import {
  globalUseCallback,
  memoize,
  type Merge,
  type SuggestedPaths,
} from '@nartex/stdlib'
import {
  // eslint-disable-next-line no-restricted-imports
  useFormContext as useRhfFormContext,
  get as rhfGet,
  type FieldError,
  type FormState,
  type SetValueConfig,
  type FieldValues,
  type UseFormReturn,
  type WatchObserver,
  type ErrorOption,
} from 'react-hook-form'

import type { FormGetter, GetPathValue } from '../types'

import { scopeName, useFormScope, unScopeName } from './EmbeddedForm'

export type OptimizedUseFormReturnType<TValues extends FieldValues> = {
  getValues: FormGetter<TValues>
  setValue: <Name extends SuggestedPaths<TValues>>(
    name: Name,
    value: GetPathValue<TValues, Name>,
    options?: SetValueConfig,
  ) => void

  getFieldState: <Name extends SuggestedPaths<TValues>>(
    name: Name,
    formState?: FormState<TValues>,
  ) => {
    invalid: boolean
    isDirty: boolean
    isTouched: boolean
    error?: FieldError
  }

  resetField: <Name extends SuggestedPaths<TValues>>(
    name: Name,
    options?: {
      defaultValue?: GetPathValue<TValues, Name>
      keepDirty?: boolean
      keepTouched?: boolean
      keepError?: boolean
    },
  ) => void

  setError: (
    name: SuggestedPaths<TValues>,
    error: ErrorOption,
    options?: {
      shouldFocus: boolean
    },
  ) => void

  clearErrors: (
    name?:
      | SuggestedPaths<TValues>
      | SuggestedPaths<TValues>[]
      | readonly SuggestedPaths<TValues>[],
  ) => void
}

export type NxFormContext<TValues extends FieldValues> = Merge<
  Omit<UseFormReturn<TValues>, 'control'>,
  OptimizedUseFormReturnType<TValues> & {
    rootFormContext: UseFormReturn<Record<string, unknown>>
    scope: string | undefined
  }
>

export function makeUseFormContext<TValues extends FieldValues>() {
  type TReturn = NxFormContext<TValues>

  return function useFormContext(): TReturn {
    const scope = useFormScope() ?? undefined
    const root = useRhfFormContext<Record<string, unknown>>()

    return {
      rootFormContext: root,
      scope,

      clearErrors: globalUseCallback(
        (name) => {
          return root.clearErrors(scopeName(scope, name))
        },
        [root.clearErrors, scope],
      ),
      get formState(): TReturn['formState'] {
        throw Error('scoped method "formState" is not implemented yet')
      },
      getFieldState: globalUseCallback(
        (name, formState) => {
          if (formState) return root.getFieldState(name, formState as any)
          return root.getFieldState(scopeName(scope, name))
        },
        [root.getFieldState, scope],
      ),
      getValues: globalUseCallback(
        (name?: string | readonly string[]) => {
          const fieldNames = scopeName(scope, name)
          const values = root.getValues()

          if (!fieldNames) {
            return values
          }

          if (Array.isArray(fieldNames)) {
            return fieldNames.map((n) => get(values, n))
          }

          return get(values, fieldNames as string)
        },
        [root.getValues, scope],
      ),
      get handleSubmit(): TReturn['handleSubmit'] {
        throw Error('scoped method "handleSubmit" is not implemented yet')
      },
      register: globalUseCallback(
        (name, options) => {
          return root.register(scopeName(scope, name), options as any) as any
        },
        [root.register, scope],
      ) as TReturn['register'],
      unregister: globalUseCallback(
        (name, options) => {
          return root.unregister(scopeName(scope, name), options)
        },
        [root.unregister, scope],
      ),
      get reset(): TReturn['reset'] {
        throw Error('scoped method "reset" is not implemented yet')
      },
      resetField: globalUseCallback(
        (name, options) => {
          return root.resetField(scopeName(scope, name), options)
        },
        [root.resetField, scope],
      ),
      setError: globalUseCallback(
        (name, options) => {
          return root.setError(scopeName(scope, name), options)
        },
        [root.setError, scope],
      ),
      setFocus: globalUseCallback(
        (name, options) => {
          return root.setFocus(scopeName(scope, name), options)
        },
        [root.setFocus, scope],
      ),
      setValue: globalUseCallback(
        (name, value, options) => {
          return root.setValue(scopeName(scope, name), value, options)
        },
        [root.setValue, scope],
      ) as TReturn['setValue'],
      trigger: globalUseCallback(
        (name, options) => {
          return root.trigger(scopeName(scope, name), options)
        },
        [root.trigger, scope],
      ),
      watch: globalUseCallback(
        (name?: any, options?: any) => {
          if (!scope) return root.watch(name, options)

          if (typeof name === 'function') {
            const callback = name as WatchObserver<Record<string, unknown>>
            const watcher = globalUseCallback(
              (values, info) => {
                if (!info.name) {
                  return callback(get(values, scope), info)
                }

                if (info.name === scope) {
                  return callback(get(values, scope), {
                    ...info,
                    name: unScopeName(scope, info.name),
                  })
                }

                if (info.name.startsWith(`${scope}.`)) {
                  return callback(get(values, scope), {
                    ...info,
                    name: unScopeName(scope, info.name),
                  })
                }
              },
              [callback, undefined],
            )

            return root.watch(watcher, options)
          }

          return root.watch(scopeName(scope, name), options)
        },
        [root.watch, scope],
      ) as TReturn['watch'],
    }
  }
}

export const useFormContext = makeUseFormContext<Record<string, unknown>>()

const get = memoize(rhfGet)
