import {
  type SearchFilter,
  type CrudFilter,
  type CrudFilters,
  isNestedFilter,
} from '@nartex/data-provider'
import { isEmpty, isTruthy } from '@nartex/stdlib'
import { groupBy } from 'remeda'

import type { SearchParams } from '../index'

import { tsEsc } from './utils/tsEsc'

export function encodeFilters(
  filters: CrudFilters<unknown>,
): Pick<SearchParams, 'filter_by' | 'q'> {
  const { qFilters, otherFilters } = groupBy(
    filters,
    (filter): 'qFilters' | 'otherFilters' => {
      if ('q' in filter) return 'qFilters'
      else return 'otherFilters'
    },
  )

  const q =
    qFilters?.map((filter) => (filter as SearchFilter).q).join(' ') || '*'
  const filter_by = (otherFilters as CrudFilter<unknown>[])
    ?.filter((filter) => !isEmpty((filter as any)?.value))
    .map(encodeFilter)
    .filter(Boolean)
    .join(' && ')

  return {
    q,
    filter_by: filter_by || undefined,
  }
}

const geopointRadiusRegex = /^\(-?\d+(.\d+)?, -?\d+(.\d+)?, (\d+(.\d+)?)km\)$/ // eg: (-180.0, 90.0, 5km)
const geopointPolygonRegex = /^\(-?\d+(.\d+)?(, -?\d+(.\d+)?)*\)$/ // eg: (-180.0, 90.0, 180.0, -90.0)

function encodeFilter(
  filter: Exclude<CrudFilter<unknown>, SearchFilter>,
): string {
  if (isNestedFilter(filter)) {
    const collectionFilter = encodeFilters(
      filter.value.filter(isTruthy),
    ).filter_by

    if (collectionFilter) {
      return `$${filter.collection}(${collectionFilter})`
    }
    return ''
  }

  const { operator } = filter

  if (operator === 'eq') {
    const { field, value } = filter

    if (typeof value === 'string' && geopointRadiusRegex.test(value)) {
      return `${field}:${value}`
    }

    if (typeof value === 'string' && geopointPolygonRegex.test(value)) {
      return `${field}:${value}`
    }

    if (
      value &&
      typeof value === 'object' &&
      value.constructor.name === 'Object'
    ) {
      const nestedFilters = Object.entries(value)
        .map(([key, innerValue]) => {
          if (innerValue == null) return undefined
          return encodeFilter({
            operator: 'eq',
            field: `${field}.${key}`,
            value: innerValue,
          })
        })
        .filter(isTruthy)

      if (!nestedFilters.length) return ''
      if (nestedFilters.length === 1) return nestedFilters[0]
      return `(${nestedFilters.join(' && ')})`
    }

    return `${field}:=${tsEsc(value, field)}`
  }

  if (operator === 'ne') {
    const { field, value } = filter
    return `${field}:!=${tsEsc(value, field)}`
  }

  if (operator === 'between') {
    const { field, value } = filter
    if (typeof value === 'string' && value.startsWith('[')) {
      return `${field}:${value}`
    }
    if (Array.isArray(value) && value.length === 2) {
      return `${field}:[${tsEsc(value[0], field)}..${tsEsc(value[1], field)}]`
    }

    throw Error(
      `This filter format (${JSON.stringify(filter)}) is not supported`,
    )
  }

  if (operator === 'lt') {
    const { field, value } = filter
    return `${field}:<${tsEsc(value, field)}`
  }

  if (operator === 'lte') {
    const { field, value } = filter
    return `${field}:<=${tsEsc(value, field)}`
  }

  if (operator === 'gt') {
    const { field, value } = filter
    return `${field}:>${tsEsc(value, field)}`
  }

  if (operator === 'gte') {
    const { field, value } = filter
    return `${field}:>=${tsEsc(value, field)}`
  }

  if (operator === 'or') {
    const { value } = filter
    return encodeOrAndFilters(value, '||')
  }

  if (operator === 'and') {
    const { value } = filter
    return encodeOrAndFilters(value, '&&')
  }

  throw Error(
    `The ${JSON.stringify(
      operator,
    )} is not yet supported. Don't hesitate to implement it`,
  )
}

function encodeOrAndFilters(
  filters: Exclude<CrudFilter<unknown>, SearchFilter>[],
  separator: '&&' | '||',
) {
  const cleanedFilters = filters.filter((val) => !isEmpty(val.value))

  if (isEmpty(cleanedFilters)) return ''

  if (cleanedFilters.length === 1) return encodeFilter(cleanedFilters[0])

  return `(${cleanedFilters.map(encodeFilter).join(` ${separator} `)})`
}
