import { Paragraph, makeInputs, unit } from '@aubade/core/ui-kit'
import {
  ThumbnailTemplateLogo,
  ThumbnailTemplateText,
} from '@aubade/design/graphics'
import type { ToolsForm } from '@aubade/types'
import { Box, HStack, Icon, VStack } from '@chakra-ui/react'

import { useFormContext, useWatch } from 'react-hook-form'

const Input = makeInputs<ToolsForm>()

type Props = {
  newToolId?: string
}

export function TemplateSection(props: Props) {
  const { setValue } = useFormContext<ToolsForm>()
  const template = useWatch<{ template: 'logo' | 'text' }>({ name: 'template' })
  return (
    <VStack width="full" alignItems={'flex-start'} gap={unit('40')}>
      <VStack gap={unit('20')} alignItems={'flex-start'}>
        <Paragraph text="tools.fields.template" size="2xs" />
        <HStack width="full" gap={unit('20')}>
          <VStack gap={unit('20')} onClick={() => setValue('template', 'logo')}>
            <Box position="relative" width={unit('145')} height={unit('106')}>
              <BorderBox color={getColor(template === 'logo')} />
              <Icon
                as={ThumbnailTemplateLogo}
                borderRadius={unit('20')}
                width={unit('145')}
                height={unit('106')}
              />
            </Box>
            <Paragraph
              size="sm"
              text="tools.titles.logo"
              color={getTextColor(template === 'logo')}
              fontWeight="bold"
            />
          </VStack>
          <VStack
            gap={unit('20')}
            padding="1px"
            onClick={() => {
              setValue('template', 'text')
              setValue('document', undefined)
            }}
          >
            <Box position="relative" width={unit('145')} height={unit('106')}>
              <BorderBox color={getColor(template === 'text')} />
              <Icon as={ThumbnailTemplateText} width={'full'} height={'full'} />
            </Box>
            <Paragraph
              size="sm"
              text="tools.titles.text"
              color={getTextColor(template === 'text')}
              fontWeight="bold"
            />
          </VStack>
        </HStack>
      </VStack>
      <TemplateDetail {...props} />
    </VStack>
  )
}

function BorderBox(props: { color: string }) {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width={unit('145')}
      height={unit('106')}
      borderRadius={unit('20')}
      border="1px solid"
      borderColor={props.color}
      zIndex={1000}
    />
  )
}

function TemplateDetail(props: Props) {
  const { newToolId } = props
  const template = useWatch<{ template: 'logo' | 'text' }>({ name: 'template' })

  if (template === 'text') {
    return (
      <VStack width="full" gap={unit('40')}>
        <Input.Text name="lineOne" label="tools.fields.text1" maxLength={17} />
        <Input.Text name="lineTwo" label="tools.fields.text2" maxLength={17} />
      </VStack>
    )
  }
  if (template === 'logo') {
    return (
      <Input.DropZone
        accept={{ 'image/*': [] }}
        maxFiles={1}
        name="document"
        variant="logo"
        label="Importer le logo de l'outil"
        objectId={newToolId}
      />
    )
  }
}

function getColor(val: boolean) {
  if (val) return 'blue.500'
  return 'transparent'
}
function getTextColor(val: boolean) {
  if (val) return 'blue.500'
  return 'black.500'
}
