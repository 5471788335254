import { arrayify } from '@nartex/stdlib'
import type { FieldValues } from 'react-hook-form'

import type { FormGetter } from '../types'

import { makeUseFormSelector } from './useFormSelector'

export function makeUseWatch<TFieldValues extends FieldValues>() {
  const useFormSelector = makeUseFormSelector<TFieldValues>()

  return function useWatch(name: string | readonly string[] | undefined) {
    return useFormSelector(
      (form) => {
        return form.getValues(name as any)
      },
      (_, info) => {
        if (!name) return true
        if (!info.name) return true

        return arrayify(name).some(
          (watchedName: string) =>
            info.name === watchedName ||
            info.name?.startsWith(watchedName + '.') ||
            watchedName.startsWith(info.name + '.'),
        )
      },
    )
  } as FormGetter<TFieldValues>
}

export const useWatch = makeUseWatch<Record<string, unknown>>()
