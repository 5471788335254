import { createContextFunction } from '@nartex/react-libs'
import * as refine from '@pankod/refine-core'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { isTruthy } from 'remeda'

import {
  createQueryBuilder,
  type QueryBuilder,
} from '../queryBuilder/QueryBuilder'
import type {
  CustomQueryParams,
  GetListQueryParams,
  GetManyQueryParams,
  GetOneQueryParams,
} from '../queryBuilder/types'
import type {
  BaseRecord,
  QueryMetaData,
  GetListResponse,
  GetOneResponse,
  CustomResponse,
  DataProviders,
} from '../types'

import { useNxDataProviders } from './NxDataProvider'
import { useQueryMetaData } from './QueryMetaDataProvider'

// fix broken refine CommonJS exports : https://stackoverflow.com/a/71398340
function fixCjsImport<Module>(m: Module): Module {
  if ('default' in (m as any)) {
    return (m as any).default as unknown as Module
  }
  return m
}
const { useDataProvider } = fixCjsImport(refine)

export * from '../queryBuilder/types'
export * from '.'

const [QueryBuilderProvider, useQueryBuilderContext] = createContextFunction<
  QueryBuilder | undefined
>('QueryBuilder', function () {
  return undefined
})

export { QueryBuilderProvider, createQueryBuilder }

export function useQueryBuilder() {
  const queryBuilderContext = useQueryBuilderContext()

  const metaData = useQueryMetaData() as QueryMetaData | undefined
  const nxDataProviders = useNxDataProviders()
  const getRefineDataProvider = useDataProvider()
  const queryClient = useQueryClient()

  return useMemo((): QueryBuilder => {
    if (queryBuilderContext) {
      return {
        getList(params) {
          return queryBuilderContext.getList({
            ...params,
            metaData: { ...metaData, ...params.metaData },
          })
        },
        getOne(params) {
          return queryBuilderContext.getOne({
            ...params,
            metaData: { ...metaData, ...params.metaData },
          })
        },
        getInfiniteList(params) {
          return queryBuilderContext.getInfiniteList({
            ...params,
            metaData: { ...metaData, ...params.metaData },
          })
        },
        getMany(params) {
          return queryBuilderContext.getMany({
            ...params,
            metaData: { ...metaData, ...params.metaData },
          })
        },
        custom(params) {
          return queryBuilderContext.custom({
            ...params,
            metaData: { ...metaData, ...params.metaData },
          })
        },
      }
    }

    return createQueryBuilder(
      new Proxy(
        {},
        {
          get(_target, prop) {
            if (typeof prop !== 'string') {
              return undefined
            }
            // @ts-expect-error nxDataProviders can't be known
            return nxDataProviders?.[prop] ?? getRefineDataProvider(prop)
          },
        },
      ) as DataProviders,
      queryClient,
      metaData,
    )
  }, [
    getRefineDataProvider,
    metaData,
    nxDataProviders,
    queryBuilderContext,
    queryClient,
  ])
}

export function useList<T extends BaseRecord>(
  params: GetListQueryParams<T>,
  queryOptions?: UseQueryOptions<
    GetListResponse<T>,
    unknown,
    GetListResponse<T>
  >,
) {
  const queryBuilder = useQueryBuilder()

  const queryResult = useQuery({
    ...queryBuilder.getList(params),
    ...queryOptions,
  } as UseQueryOptions<GetListResponse<T>, unknown, GetListResponse<T>>)

  return [queryResult.data?.data, queryResult] as const
}

export function useAll<T extends BaseRecord>(
  params: GetListQueryParams<T>,
  queryOptions?: UseQueryOptions<
    GetListResponse<T>,
    unknown,
    GetListResponse<T>
  >,
) {
  const queryBuilder = useQueryBuilder()

  const pageSize = params.pagination?.pageSize ?? 250
  const [, firstQueryResult] = useList(
    {
      ...params,
      pagination: { pageSize, current: 1 },
    },
    queryOptions,
  )

  const total = firstQueryResult.data?.total ?? 0

  const queries = useQueries({
    queries: Array.from(
      { length: Math.ceil(total / pageSize) - 1 },
      (_, page) => {
        return queryBuilder.getList<T>({
          ...params,
          pagination: { pageSize, current: page + 2 },
        })
      },
    ).map((query) => {
      return {
        ...query,
        ...queryOptions,
      } as UseQueryOptions<GetListResponse<T>, unknown, GetListResponse<T>>
    }),
  })

  const allQueries = [firstQueryResult, ...queries]

  return [
    allQueries
      .map((query) => query.data?.data)
      .filter(isTruthy)
      .flat(),
    allQueries,
  ] as const
}

export function useOne<T extends BaseRecord>(
  params: GetOneQueryParams<T>,
  queryOptions?: UseQueryOptions<GetOneResponse<T>, unknown, GetOneResponse<T>>,
) {
  const queryBuilder = useQueryBuilder()

  const queryResult = useQuery({
    ...queryBuilder.getOne(params),
    ...queryOptions,
  } as UseQueryOptions<GetOneResponse<T>, unknown, GetOneResponse<T>>)

  return [queryResult.data?.data, queryResult] as const
}

export function useMany<T extends BaseRecord>(
  params: GetManyQueryParams<T>,
  queryOptions?: UseQueryOptions<GetOneResponse<T>, unknown, GetOneResponse<T>>,
) {
  const queryBuilder = useQueryBuilder()

  const queryResults = useQueries({
    queries: queryBuilder.getMany<T, unknown>(params).queries.map((query) => {
      return { ...query, ...queryOptions } as UseQueryOptions<
        GetOneResponse<T>,
        unknown,
        GetOneResponse<T>
      >
    }),
  })

  const items = useMemo(() => {
    return queryResults.map((query) => query.data?.data).filter(isTruthy)
  }, [queryResults])

  return [items, queryResults] as const
}

export function useCustom<
  T extends BaseRecord,
  TPayload = never,
  TQuery = never,
>(
  params: CustomQueryParams<TPayload, TQuery>,
  queryOptions?: UseQueryOptions<CustomResponse<T>, unknown, CustomResponse<T>>,
) {
  const queryBuilder = useQueryBuilder()

  const queryResult = useQuery({
    ...queryBuilder.custom(params),
    ...queryOptions,
  } as UseQueryOptions<CustomResponse<T>, unknown, CustomResponse<T>>)

  return [queryResult.data?.data, queryResult] as const
}
