import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  Form,
  useForm,
  Button,
  Paragraph,
  unit,
  makeForm,
} from '@aubade/core/ui-kit'
import { IconSend } from '@aubade/design/graphics'
import { DrawerContent } from '@aubade/domain/components'
import type { ExtendedConversationWrite } from '@aubade/types'
import { Box, HStack, Spinner, Grid, Center } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useState, useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { v4 } from 'uuid'

import { ContactPicker } from '../../../components/ContactPicker'
import { FilterForm } from '../filters/FilterForm'
import { useConversationFilters } from '../filters/useConversationFilters'

const { useFormContext, useWatch } = makeForm<
  ExtendedConversationWrite,
  'users'
>()

export function ConversationCreate() {
  const newId = useMemo(() => {
    return v4()
  }, [])
  const [_searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { saveButtonProps, ...createMethods } =
    useForm<ExtendedConversationWrite>({
      mode: 'create',
      resource: 'conversations',
      hookFormOptions: {
        defaultValues: {
          newId,
        },
      },
      redirect() {
        return false
      },
      mutationOptions: {
        async onSuccess(response) {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('summary/conversations')
            },
          })
          setSearchParams({ id: response.data.id! })
        },
      },
      blockNavigation: false,
    })

  return (
    <DrawerContent>
      <Box width={'full'}>
        <Form {...createMethods}>
          <FormContent saveButtonProps={saveButtonProps} />
        </Form>
      </Box>
    </DrawerContent>
  )
}

type FormContentProps = {
  saveButtonProps: FormSubmitProps
}

function FormContent(props: FormContentProps) {
  const { saveButtonProps } = props
  const { onClick } = saveButtonProps
  const { crudFilters, filtersStore } = useConversationFilters('conversations')
  const { multi } = filtersStore.state

  const mainTitle = multi
    ? 'message.title.settingCreate.groupe'
    : 'message.title.settingCreate.simple'

  const { setValue } = useFormContext()
  const [isSubmit, setIsSubmit] = useState<Boolean>(false)

  const users = useWatch('users')

  const isDisabled = useMemo(() => {
    if (multi && Boolean(!users || users.length < 2)) {
      return true
    }
    if (!multi && Boolean(!users || users.length === 0)) {
      return true
    }
    return false
  }, [multi, users])

  useLayoutEffect(() => {
    const autoSubmit = async () => {
      if (!multi && users && !isSubmit) {
        // @ts-expect-error
        await onClick?.()
        setIsSubmit(true)
      }
    }
    autoSubmit().catch((error) => console.log(error))
  }, [multi, onClick, users, isSubmit, setValue])

  if (isSubmit) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <Grid
      width={'full'}
      height="full"
      templateRows={'60px 1fr 60px'}
      paddingX={unit('8')}
      gap={unit('40')}
    >
      <HStack width="full" padding={unit('10')} justifyContent={'flex-start'}>
        <Paragraph size="lg" fontWeight="bold" text={mainTitle} />
      </HStack>
      <Box width="full" height="full">
        <ContactPicker
          name="users"
          filters={crudFilters}
          max={multi ? undefined : 1}
          renderFilters={() => {
            return (
              <FilterForm
                filters={filtersStore.state as any}
                onChange={filtersStore.setState}
                reset={() => setValue('users', undefined)}
              />
            )
          }}
        />
      </Box>
      <HStack
        width={'100%'}
        justifyContent={'center'}
        position="absolute"
        bottom={unit('40')}
        left={0}
      >
        {multi && !isDisabled && (
          <Button
            {...saveButtonProps}
            onClick={async (e) => {
              setIsSubmit(true)
              await saveButtonProps?.onClick?.(e)
            }}
            leftIcon={IconSend}
            variant="primary"
            label="buttons.conversation.create"
          />
        )}
      </HStack>
    </Grid>
  )
}
