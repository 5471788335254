if (typeof window !== 'undefined') {
  globalThis.window ??= window
  require('requestidlecallback-polyfill')
}

type Callback = (...args: any[]) => void
type Cancel = () => void
type Cancellable<T extends Callback> = (...args: Parameters<T>) => Cancel

export function debounceIdleCallback<T extends Callback>(
  callback: T,
  maxWait: number,
): Cancellable<T> {
  let idleId: number | null = null

  return (...args) => {
    if (idleId) cancelIdleCallback(idleId)

    const thisId = (idleId = requestIdleCallback(
      () => {
        // eslint-disable-next-line n/no-callback-literal
        callback(...args)
      },
      { timeout: maxWait },
    ))

    return () => {
      cancelIdleCallback(thisId)
    }
  }
}

export function debounceImmediateCallback<T extends Callback>(
  callback: T,
): Cancellable<T> {
  let cancelPrevious: () => void = () => {}

  return (...args) => {
    cancelPrevious()

    cancelPrevious = setImmediate(() => {
      // eslint-disable-next-line n/no-callback-literal
      callback(...args)
    })

    return function cancel() {
      cancelPrevious()
    }
  }
}

export function setImmediate(callback: () => void) {
  let canceled = false

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  Promise.resolve().then(() => {
    if (!canceled) {
      // eslint-disable-next-line n/no-callback-literal
      callback()
    }
  })

  return function cancel() {
    canceled = true
  }
}

export function timeout(callback: () => void, delay: number) {
  const timeoutId = setTimeout(callback, delay)

  return function cancel() {
    clearTimeout(timeoutId)
  }
}
