import type { DocCreate } from '@aubade/core/adapters'
import { Paragraph, unit, makeForm, useDocumentUrl } from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import { useTranslate } from '@aubade/translation'
import type { PublicationForm } from '@aubade/types'
import type { Document } from '@aubade/types/api'
import {
  Icon,
  Box,
  Button as ChakraButton,
  SimpleGrid,
  AspectRatio,
  Image,
  VStack,
} from '@chakra-ui/react'
import { useFieldArray } from 'react-hook-form'

const { Input } = makeForm<
  PublicationForm,
  | 'notificationTitle'
  | 'notificationContent'
  | 'withNotification'
  | 'planify'
  | 'isInternal'
  | 'allCategories'
  | 'jobs'
>()

type Props = {
  canPartialEdit: boolean
  newPulicationId?: string
  publicationId?: string
}
export function PictureSection(props: Props) {
  const { canPartialEdit, publicationId, newPulicationId } = props
  const translate = useTranslate()
  const { fields, append, remove } = useFieldArray({
    name: 'documents',
  })

  return (
    <VStack gap={unit('5')} alignItems={'flex-start'}>
      <Paragraph size="2xs" text={'publications.fields.documents'} />
      <SimpleGrid columns={2} spacing={'10px'}>
        {fields &&
          fields.length > 0 &&
          (fields as (Document.Read | DocCreate)[]).map((document, index) => {
            return (
              <Box
                position="relative"
                borderRadius={unit('10')}
                overflow="hidden"
                key={document['@id']}
                width={unit('150')}
                height={unit('150')}
              >
                <Picture key={document['@id']} document={document} />
                {canPartialEdit && (
                  <ChakraButton
                    onClick={(event) => {
                      remove(index)
                      event.stopPropagation()
                    }}
                    title={translate('actions.delete')}
                    position="absolute"
                    top={0}
                    right={0}
                    color="darkGrey.500"
                    minWidth={0}
                    backgroundColor="transparent"
                  >
                    <Icon
                      as={IconFermer}
                      width={unit('15')}
                      height={unit('15')}
                      color="black"
                    />
                  </ChakraButton>
                )}
              </Box>
            )
          })}

        <Box maxWidth={unit('200')}>
          <Input.DropZone
            name="documents"
            accept={{ 'image/*': [], 'video/*': [] }}
            maxFiles={6}
            multiple={true}
            onAppend={append}
            disabled={fields.length >= 6}
            objectId={newPulicationId ?? publicationId}
            readOnly={!canPartialEdit}
            boxProps={{
              width: unit('150'),
              height: unit('150'),
              gap: unit('14'),
              flexDirection: 'column-reverse',
              borderRadius: 10,
            }}
          />
        </Box>
      </SimpleGrid>
    </VStack>
  )
}

export function Picture(props: { document: Document.Read | DocCreate }) {
  const { document } = props

  const pictureUrl = useDocumentUrl(document, {
    size: { width: 200, height: 200 },
    ratio: 'crop',
  })

  if ('file' in document && document.file.type?.includes('video')) {
    return (
      <AspectRatio ratio={1} width="100%" backgroundColor="white.500">
        <iframe
          src={pictureUrl}
          allow="fullscreen; picture-in-picture"
          title="sample"
          style={{
            width: 'full',
            objectFit: 'contain',
          }}
        />
      </AspectRatio>
    )
  }
  if (document?.playerEmbedUrl) {
    return (
      <AspectRatio ratio={1} width="100%">
        <iframe
          src={document.playerEmbedUrl}
          allow="fullscreen; picture-in-picture"
          title="sample"
          style={{
            width: 'full',
            height: 'full',
            objectFit: 'cover',
          }}
        />
      </AspectRatio>
    )
  }

  return (
    <AspectRatio ratio={1} width="100%" backgroundColor="white.500">
      <Image alt={document.displayName} src={pictureUrl} />
    </AspectRatio>
  )
}
