import type { AutoCompleteString } from '@aubade/core/libs'
import { useTranslation } from 'react-i18next'

import type translation from './locales/fr/common.locale.json'

export type StrictTranslationKey = keyof typeof translation
export type TranslationKey = AutoCompleteString<StrictTranslationKey>

export function useTranslate() {
  const { t } = useTranslation()

  function translate(
    key: TranslationKey,
    params?: Record<string, any>,
  ): string {
    return t(key, params)
  }
  return Object.assign(translate, {
    // Some type magic to do here to only provide enums
    enum(key: string) {
      return translate(`enums.${key}`)
    },
  })
}
