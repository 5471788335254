import type { SearchFilter } from '@aubade/core/adapters'
import { useOne, useList } from '@aubade/core/adapters'
import { Boundary, SearchBar, makeForm } from '@aubade/core/ui-kit'
import { ParentCheckBox } from '@aubade/core/ui-kit/Inputs/Checkbox/Checkbox'
import { useConnectedUser } from '@aubade/domain/adapters'
import type { Contact } from '@aubade/types'
import type { Typesense } from '@aubade/types/typesense'
import { Box } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'

import type { BaseSyntheticEvent } from 'react'

import { useMemo } from 'react'

import { useSearchParams } from 'react-router-dom'

import type { PublicationFilterType } from '../../Resources/Publications/filters/types'

import { NationalFilter } from './nationalFilter'

const { Input, useWatch } = makeForm<
  PublicationFilterType,
  'agencies' | 'societies' | 'isNational'
>()

type AgencyFilterProps = {
  light?: boolean
  societyId?: string
  single?: boolean
  withNational?: boolean
  context?: 'collaborators'

  onFilterChange?: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}

/**
 * Component for filters and form, for agencies, name agencies
 * @param {string} societyId - the id of a single society
 * @param {function} onFilterChange - callback onFilterStateChange from useFilter, for filters only
 */
export function AgencyFilter(props: AgencyFilterProps) {
  const {
    onFilterChange,
    societyId,
    light = false,
    single = false,
    withNational = false,
    context,
  } = props
  const { id: me } = useConnectedUser()

  const agencies = useWatch('agencies')

  const [actualUser] = useOne<Contact>(
    { iri: toHydraId('users', me) },
    { enabled: Boolean(me) },
  )
  const { societyAgency, society } = actualUser!

  const [searchParams] = useSearchParams({ scope: 'agencies' })
  const listFilters = useMemo(() => {
    const search = searchParams.get(`agencies.q`)
    if (search) {
      const qFilter: SearchFilter = { q: search }
      return [qFilter]
    }
    return []
  }, [searchParams])

  const [fullAgencies] = useList<Typesense.Agencies>({
    resource: 'agencies',
    dataProviderName: 'default',
    hasPagination: true,
    pagination: { pageSize: 250 },
    filters: [
      ...listFilters,
      {
        field: 'society.id',
        operator: 'eq',
        value: societyId ?? society?.id ?? societyAgency?.id,
      },
    ],
  })
  const lightStyle = getLightStyle(light)
  if (!fullAgencies) return <></>

  return (
    <Box width="full">
      {!light && <SearchBar scope="agencies" />}
      <Box padding={2} width="full" {...lightStyle}>
        {withNational && (
          <NationalFilter onChange={onFilterChange} context={context} />
        )}
        <Boundary>
          <ParentCheckBox
            name="agencies"
            onFilterChange={onFilterChange}
            children={(onValueChange, valueSet, isDisabled) => {
              return (
                <>
                  {fullAgencies.map((agency) => {
                    return (
                      <Input.CheckBoxArray
                        label={agency.name ?? ' '}
                        key={agency.id}
                        disabled={
                          isDisabled ||
                          (single &&
                            agencies &&
                            agencies.length > 0 &&
                            !valueSet.has(agency['@id']))
                        }
                        checked={valueSet.has(agency['@id'])}
                        onChange={(val) => {
                          onValueChange(val, agency['@id'])
                        }}
                      />
                    )
                  })}
                </>
              )
            }}
          />
        </Boundary>
        <Box height="16px" />
      </Box>
    </Box>
  )
}

function getLightStyle(light: boolean) {
  if (!light)
    return {
      maxHeight: '350px',
      overflow: 'auto',
    }
  return {}
}
