import { useOne } from '@aubade/core/adapters'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isSociety, isAdmin, isAgency } from '@aubade/domain/components'
import { cleanHydraId, toHydraId } from '@nartex/api-platform'

import type {
  Contact,
  ExtendedUser,
} from '../../../../packages/types/src/index'

export function usePublicationRights() {
  return {
    useCanCreate,
    useCanUpdate,
    useCanPartialEdit,
    useCanBlock,
    useCanDelete,
  }
}

export function useNotificationRights() {
  return { ...usePublicationRights(), useCanDeleteNotif }
}

function useCanCreate() {
  return true
}

function useCanUpdate(fullAuthor?: ExtendedUser) {
  const { role, society, agency } = useConnectedUser()
  if (!fullAuthor) return false
  if (isAdmin(role)) {
    return Boolean(fullAuthor && fullAuthor.isAubadeAdmin)
  }
  if (isSociety(role)) {
    if (fullAuthor && fullAuthor.isAubadeAdmin) {
      return false
    } else {
      return Boolean(
        fullAuthor &&
          fullAuthor.isSocietyUser &&
          society &&
          cleanHydraId(fullAuthor.society as any) === society.id,
      )
    }
  }
  if (isAgency(role)) {
    if (fullAuthor && fullAuthor.isAubadeAdmin) {
      return false
    } else {
      return Boolean(
        fullAuthor &&
          fullAuthor.isAgencyUser &&
          agency &&
          cleanHydraId(fullAuthor.agency as any) === agency.id,
      )
    }
  }
}

function useCanPartialEdit(
  fullAuthor: ExtendedUser,
  publishedAt: Date,
  isDraft: boolean,
) {
  const canUpdate = useCanUpdate(fullAuthor)

  const canEdit =
    isDraft ||
    (canUpdate && new Date(publishedAt!).getTime() > new Date().getTime())

  const canPartialEdit = canEdit || canUpdate
  return canPartialEdit
}

function useCanBlock(fullAuthor: Contact | undefined) {
  const { role } = useConnectedUser()
  if (isAdmin(role)) return false
  if (isSociety(role)) {
    if (isAdmin(fullAuthor?.acRoles.AC)) return true
    return false
  }
  if (isAgency(role)) {
    if (isAdmin(fullAuthor?.acRoles.AC)) return true
    if (isSociety(fullAuthor?.acRoles.AC)) return true
    return false
  }
  return false
}

function useCanDelete(fullAuthor: Contact | undefined) {
  const { role } = useConnectedUser()

  if (isAdmin(role) && fullAuthor) return true
  if (isSociety(role) && fullAuthor?.isSocietyUser) {
    return true
  }
  if (isSociety(role) && fullAuthor?.isAgencyUser) {
    return true
  }
  if (isAgency(role) && fullAuthor?.isAgencyUser) {
    return true
  }

  return false
}

function useCanDeleteNotif(authorId?: string) {
  const iri = toHydraId('users', authorId ?? '')
  const [user] = useOne<Contact>({ iri }, { enabled: Boolean(authorId) })
  const canDelete = useCanDelete(user)

  return canDelete
}
