import { useOne } from '@aubade/core/adapters'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isAdmin, isSociety } from '@aubade/domain/components'
import type { Contact } from '@aubade/types'
import { useDelete, toHydraId } from '@nartex/data-provider/react'
import { useMemo } from 'react'
import { useAubadeQueryBuilder } from 'src/aubadeQueryBuilder/useAubadeQueryBuilder'

export function useActions(authorId: string | undefined) {
  const aubadeQueryBuilder = useAubadeQueryBuilder()

  const [mutateDelete] = useDelete()

  const { role } = useConnectedUser()

  const [fullAuthor] = useOne<Contact>(
    {
      iri: toHydraId('users', authorId ?? ''),
    },
    { enabled: Boolean(authorId) },
  )

  const canDelete = useMemo(() => {
    if (isAdmin(role)) return true
    if (isSociety(role) && !isAdmin(fullAuthor?.acRoles.AC)) {
      return true
    }
    return false
  }, [fullAuthor, role])

  async function onDelete(notificationId: string) {
    await mutateDelete(...aubadeQueryBuilder.deleteNotification(notificationId))
  }
  return { canDelete, onDelete }
}
