import { useEvent } from '@nartex/react-libs'
import type { HttpError } from '@pankod/refine-core'
import * as refine from '@pankod/refine-core'

import type { BaseRecord } from '..'
import { cleanHydraId } from '..'

import { useQueryMetaData } from './QueryMetaDataProvider'

// fix broken refine CommonJS exports : https://stackoverflow.com/a/71398340
function fixCjsImport<Module>(m: Module): Module {
  if ('default' in (m as any)) {
    return (m as any).default as unknown as Module
  }
  return m
}

const {
  useCreate: useRefineCreate,
  useCreateMany: useRefineCreateMany,
  useUpdate: useRefineUpdate,
  useUpdateMany: useRefineUpdateMany,
  useDelete: useRefineDelete,
  useDeleteMany: useRefineDeleteMany,
} = fixCjsImport(refine)

export function useCreate<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = object,
>() {
  const queryMetaData = useQueryMetaData()

  const create = useRefineCreate<TData, TError, TVariables>()
  const mutationReturn = {
    ...create,
    mutate: useEvent(function mutate(variables, options) {
      return create.mutate(
        {
          ...variables,
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
    mutateAsync: useEvent(async function mutateAsync(variables, options) {
      return create.mutateAsync(
        {
          ...variables,
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
  } as typeof create

  return [mutationReturn.mutateAsync, mutationReturn] as const
}

export function useCreateMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = object,
>() {
  const queryMetaData = useQueryMetaData()

  const createMany = useRefineCreateMany<TData, TError, TVariables>()
  const mutationReturn = {
    ...createMany,
    mutate: useEvent(function mutate(variables, options) {
      return createMany.mutate(
        {
          ...variables,
          values: variables.values.map(normalizeRecordId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
    mutateAsync: useEvent(async function mutateAsync(variables, options) {
      return createMany.mutateAsync(
        {
          ...variables,
          values: variables.values.map(normalizeRecordId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
  } as typeof createMany

  return [mutationReturn.mutateAsync, mutationReturn] as const
}

export function useUpdate<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = object,
>() {
  const queryMetaData = useQueryMetaData()

  const update = useRefineUpdate<TData, TError, TVariables>()
  const mutationReturn = {
    ...update,
    mutate: useEvent(function mutate(variables, options) {
      return update.mutate(
        {
          ...variables,
          id: normalizeId(variables.id),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
    mutateAsync: useEvent(async function mutateAsync(variables, options) {
      return update.mutateAsync(
        {
          ...variables,
          id: normalizeId(variables.id),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
  } as typeof update

  return [mutationReturn.mutateAsync, mutationReturn] as const
}

export function useUpdateMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = object,
>() {
  const queryMetaData = useQueryMetaData()

  const updateMany = useRefineUpdateMany<TData, TError, TVariables>()
  const mutationReturn = {
    ...updateMany,
    mutate: useEvent(function (variables, options) {
      return updateMany.mutate(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
    mutateAsync: useEvent(async function mutateAsync(variables, options) {
      return updateMany.mutateAsync(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
  } as typeof updateMany

  return [mutationReturn.mutateAsync, mutationReturn] as const
}

export function useDelete<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = object,
>() {
  const queryMetaData = useQueryMetaData()

  const refineDelete = useRefineDelete<TData, TError, TVariables>()
  const mutationReturn = {
    ...refineDelete,
    mutate: useEvent(function mutate(variables, options) {
      return refineDelete.mutate(
        {
          ...variables,
          id: normalizeId(variables.id),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
    mutateAsync: useEvent(async function mutateAsync(variables, options) {
      return refineDelete.mutateAsync(
        {
          ...variables,
          id: normalizeId(variables.id),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
  } as typeof refineDelete

  return [mutationReturn.mutateAsync, mutationReturn] as const
}

export function useDeleteMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = object,
>() {
  const queryMetaData = useQueryMetaData()

  const refineDeleteMany = useRefineDeleteMany<TData, TError, TVariables>()
  const mutationReturn = {
    ...refineDeleteMany,
    mutate: useEvent(function mutate(variables, options) {
      return refineDeleteMany.mutate(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
    mutateAsync: useEvent(async function mutateAsync(variables, options) {
      return refineDeleteMany.mutateAsync(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    }),
  } as typeof refineDeleteMany

  return [mutationReturn.mutateAsync, mutationReturn] as const
}

function normalizeRecordId(variables: any): any {
  const id = (variables as any).id ?? variables['@id']
  return {
    ...variables,
    id: normalizeId(id),
  }
}

function normalizeId<Id extends BaseRecord['id'] | number | undefined>(
  id?: Id,
): Id extends string ? string : Id {
  if (typeof id === 'string') return cleanHydraId(id) as any
  return id as any // only for type inference
}
