import { Paragraph, unit } from '@aubade/core/ui-kit'
import type { UserRole, CustomerRole } from '@aubade/types'
import {
  Box,
  HStack,
  VStack,
  type StackProps,
  Center,
  Tooltip,
} from '@chakra-ui/react'

import type { ElementType } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import type { Variant } from '../Avatar'
import { Avatar } from '../Avatar'

export type ProfileCardProps = {
  title: string
  maxWidth?: StackProps['maxWidth']
  subtitle?: CustomerRole | UserRole | string
  url?: string
  picture?: string
  variant?: Variant
  icon?: ElementType<any>
  multiple?: number
  isAvailable?: boolean
  ellipsis?: boolean
}

export function ProfileCard(props: ProfileCardProps) {
  const {
    title,
    subtitle,
    picture,
    url,
    variant,
    icon,
    maxWidth,
    multiple,
    isAvailable = true,
    ellipsis = false,
  } = props
  const styles =
    variant === 'big'
      ? {
          gap: '8px',
        }
      : { gap: 0 }

  const variantStyles =
    variant === 'big' ? { gap: unit('20') } : { gap: unit('10') }

  if (multiple) {
    return (
      <Tooltip label={subtitle} maxWidth={maxWidth ?? 'full'}>
        <HStack {...variantStyles} maxWidth={maxWidth ?? 'full'}>
          <Center
            minWidth={unit('60')}
            height={unit('60')}
            borderRadius={'50%'}
            backgroundColor="teal.base"
          >
            <Paragraph
              color="darkGrey.500"
              fontWeight="bold"
              text={String(multiple)}
            />
          </Center>
          <VStack
            {...styles}
            alignItems="flex-start!important"
            justifyContent="flex-start!important"
            maxHeight="inherit"
            maxWidth={maxWidth ?? 'full'}
          >
            <Paragraph text={title} fontWeight="bold" ellipsis={ellipsis} />

            <Paragraph text={subtitle} ellipsis textAlign="left" />
          </VStack>
        </HStack>
      </Tooltip>
    )
  }

  const body = (
    <HStack width="full" {...variantStyles}>
      <Avatar name={title} picture={picture} variant={variant} icon={icon} />
      <VStack
        {...styles}
        alignItems="flex-start!important"
        justifyContent="flex-start!important"
        maxHeight="inherit"
        width={'full'}
        overflow={'hidden'}
      >
        <HStack width="full">
          <Paragraph text={title} fontWeight="bold" ellipsis />
          {!isAvailable && (
            <Paragraph text={'users.fields.unavailable'} ellipsis />
          )}
        </HStack>
        <Paragraph text={subtitle} ellipsis textAlign="left" />
      </VStack>
    </HStack>
  )

  if (url)
    return (
      <Box
        as={RouterLink}
        to={url}
        width={maxWidth ?? 'full'}
        overflow={'hidden'}
      >
        {body}
      </Box>
    )
  return <Box maxWidth={maxWidth ?? 'full'}>{body}</Box>
}
