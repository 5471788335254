import type { DataProvider } from '@aubade/core/adapters'

import { encodePagination, type SearchResponse } from '@nartex/typesense'

import { config } from 'src/config'

import { recordFilters } from './collaboraters'

export function ClientsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getList(params) {
      const { filters, metaData, pagination } = params

      const encodedPagination = encodePagination(pagination)

      const { page } = encodedPagination

      const searchResponse = await dataProvider.custom!<SearchResponse<any>>({
        method: 'post',
        url: `/users/clients?page=${page}`,
        payload: { ...recordFilters(filters), ...encodedPagination },
        headers: metaData?.headers,
      })

      const result = searchResponse.data
      const { lastLogin } = config.features

      const data = result?.hits?.map((hit) => hit.document) ?? []
      const filteredDatas = data.filter((associate) => {
        if (lastLogin) {
          return associate.lastLoginAt !== null
        } else {
          return associate
        }
      })

      return {
        data: filteredDatas,
        total: result?.found ?? 0,
        raw: { typesense: result },
      }
    },
  }
}
