import { storageFactory } from 'storage-factory'

import { createContextFunction } from '../utils'

export function Storage(): Storage {
  return storageFactory(() => localStorage)
}

export function createMemoryStorage(): Storage {
  let values = new Map<string, string>()
  return {
    setItem(key, value) {
      return values.set(key, value)
    },
    clear() {
      values = new Map()
    },
    getItem(key) {
      return values.get(key) ?? null
    },
    key(index) {
      return Array.from(values.keys()).at(index) ?? null
    },
    removeItem(key) {
      values.delete(key)
    },
    get length() {
      return values.size
    },
  }
}

const [StorageProvider, useStorage] = createContextFunction<Storage>('Storage')
export { StorageProvider, useStorage }
