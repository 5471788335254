import { useEffect, useMemo, useRef } from 'react'
import type { DependencyList, EffectCallback } from 'react'
import isEqual from 'react-fast-compare'

export function useDeepCompareMemoize(dependencies: DependencyList) {
  const dependenciesRef = useRef<DependencyList>(dependencies)
  const signalRef = useRef<number>(0)

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies
    signalRef.current += 1
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => dependenciesRef.current, [signalRef.current])
}

// Inspired by https://github.com/sandiiarov/use-deep-compare
export function useDeepCompareMemo<T>(
  factory: () => T,
  dependencies: DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, useDeepCompareMemoize(dependencies))
}

export function useDeepCompareEffect(
  effect: EffectCallback,
  dependencies: DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(effect, useDeepCompareMemoize(dependencies))
}
