import { useMemo } from 'react'

import { useEvent } from './useEvent'

export function useStabilizeResult<T>(cb: () => T, deps?: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => stabilizeResult(cb), deps ?? [cb])
}

export function useStableMemo<T>(cb: () => T, deps: unknown[]): T {
  const stableCallback = useStabilizeResult(useEvent(cb))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(stableCallback, deps)
}

export function stabilizeResult<T>(cb: () => T): () => T {
  let lastResult: T | undefined
  return (): T => {
    const newResult = cb()
    lastResult = replaceEqualDeep(lastResult, newResult)
    return lastResult as T
  }
}

/**
 * original function from tanstack/query
 * https://github.com/TanStack/query/blob/main/packages/query-core/src/utils.ts#L213-L246
 *
 * needed to fork to remplace isPlainArray with a simpler Array.isArray to account for sparse arrays in forms
 *
 * This function returns `a` if `b` is deeply equal.
 * If not, it will replace any deeply equal children of `b` with those of `a`.
 * This can be used for structural sharing between JSON values for example.
 *
 */
function replaceEqualDeep<T>(a: unknown, b: T): T
function replaceEqualDeep(a: any, b: any): any {
  if (a === b) {
    return a
  }

  const array = Array.isArray(a) && Array.isArray(b)

  if (array || (isPlainObject(a) && isPlainObject(b))) {
    const aSize = array ? a.length : Object.keys(a).length
    const bItems = array ? b : Object.keys(b)
    const bSize = bItems.length
    const copy: any = array ? [] : {}

    let equalItems = 0

    for (let i = 0; i < bSize; i++) {
      const key = array ? i : bItems[i]
      copy[key] = replaceEqualDeep((a as any)[key], (b as any)[key])
      if (copy[key] === (a as any)[key]) {
        equalItems++
      }
    }

    if (aSize === bSize && equalItems === aSize) return a
    return copy
  }

  return b
}

/**
 * Shallow compare objects. Only works with objects that always have the same properties.
 */
export function shallowEqualObjects<T>(a: T, b: T): boolean {
  if ((a && !b) || (b && !a)) {
    return false
  }

  for (const key in a) {
    if (a[key] !== b[key]) {
      return false
    }
  }

  return true
}

export function isPlainArray(value: unknown) {
  return Array.isArray(value) && value.length === Object.keys(value).length
}

// Copied from: https://github.com/jonschlinkert/is-plain-object
export function isPlainObject(o: any): o is object {
  if (!hasObjectPrototype(o)) {
    return false
  }

  // If has modified constructor
  const ctor = o.constructor
  if (typeof ctor === 'undefined') {
    return true
  }

  // If has modified prototype
  const prot = ctor.prototype
  if (!hasObjectPrototype(prot)) {
    return false
  }

  // If constructor does not have an Object-specific method
  if (!Object.prototype.hasOwnProperty.call(prot, 'isPrototypeOf')) {
    return false
  }

  // Most likely a plain Object
  return true
}

function hasObjectPrototype(o: any): boolean {
  return Object.prototype.toString.call(o) === '[object Object]'
}
