import { getParagraphStyle } from '@aubade/core/ui-kit'
import type { IconProps } from '@chakra-ui/react'

import type { ChakraButtonProps, Tint, Variant } from './index'

export function iconVariantStyleMap(
  color?: string,
): Record<Variant, IconProps> {
  return {
    circle: {
      color: color ?? 'darkGrey.500',
      backgroundColor: 'lightGrey.500',
    },
    circleList: {
      color: color ?? 'darkGrey.500',
      backgroundColor: 'lightGrey.500',
    },
    circleWhite: {
      backgroundColor: 'white.500',
      color: color ?? 'darkGrey.500',
    },
    circleNegative: {
      backgroundColor: 'darkGrey.500',
      color: color ?? 'lightGrey.500',
    },
    primary: {
      color: color ?? 'white.500',
    },
    secondary: {
      color: color ?? 'blue.500',
    },
    link: {
      color: color ?? 'darkGrey.500',
    },
    text: {
      color: color ?? 'darkGrey.500',
    },
  }
}

export function getVariantStyleMap(
  tint: Tint = 'lightGrey',
): Record<Variant, ChakraButtonProps> {
  return {
    primary: {
      height: '50px',
      borderRadius: '25px',
      fontSize: 'sm',
      fontWeight: 'bold',
      lineHeight: '20px',
      paddingX: '20px',
      paddingY: '13px',
      iconSpacing: '8px',
      minWidth: 'fit-content',
      background: 'blue.500',
    },
    secondary: {
      height: '50px',
      borderRadius: '25px',
      fontSize: 'sm',
      fontWeight: 'bold',
      lineHeight: '20px',
      paddingX: 0,
      paddingY: 0,
      iconSpacing: '8px',
      minWidth: 'fit-content',
      background: 'white',
      color: 'blue.500',
    },
    circle: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: `${tint}.500`,
    },
    circleList: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: 'lightGrey.500',
    },
    circleWhite: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: 'white.500',
    },
    circleNegative: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: 'darkGrey.500',
    },
    link: {
      variant: 'link',
      color: 'darkGrey.500',
      fontSize: 'sm',
      fontWeight: 'bold',
      paddingX: 0,
      paddingY: 0,
      height: 'fit-content',
      textDecoration: 'underline',
      _hover: {
        color: 'primary.500',
      },
      _active: {
        color: 'primary.500',
      },
    },
    text: {
      variant: 'link',
      background: 'transparent',
      paddingX: 0,
      paddingY: 0,
      height: 'auto',
      ...getParagraphStyle({
        color: 'blue.500',
        fontWeight: 'bold',
        size: 'sm',
      }),
      _hover: {
        textDecoration: 'underline',
      },
      _active: {
        textDecoration: 'underline',
      },
    },
  } satisfies Record<Variant, ChakraButtonProps>
}

export function getIsDangerousStyleMap(): Record<Variant, ChakraButtonProps> {
  return {
    primary: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    secondary: {
      background: 'white',
      color: 'error.dark',
    },

    circle: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    circleList: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    circleWhite: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    circleNegative: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },

    link: {
      variant: 'link',
      color: 'error.dark',
      _active: {
        color: 'error.medium',
      },
      _hover: {
        color: 'error.medium',
      },
    },
    text: {
      ...getVariantStyleMap().text,
      color: 'error.medium',
      _active: {
        color: 'error.medium',
      },
    },
  }
}
