import type { UserFunction } from '@aubade/types/api'
import { useMakeTest } from 'src/adapters/useVestTest'
import { create, enforce } from 'vest'

export function useFormValidation() {
  const test = useMakeTest<UserFunction.Write>()
  return create(function (data: Pick<UserFunction.Write, 'name'>) {
    const { name } = data

    test('name', 'functions.name.required', function () {
      enforce(name).isNotBlank()
    })
  })
}
