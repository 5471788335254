import {
  // eslint-disable-next-line no-restricted-imports
  useFieldArray as useRhfFieldArray,
  type FieldValues,
  type UseFieldArrayProps,
  type FieldArrayPath,
} from 'react-hook-form'

import { useScopedName } from './EmbeddedForm'

export function makeUseFieldArray<TValues extends FieldValues>() {
  return function useFieldArray<
    TFieldArrayName extends FieldArrayPath<TValues>,
  >(props: UseFieldArrayProps<TValues, TFieldArrayName>) {
    return useRhfFieldArray({ ...props, name: useScopedName(props.name) })
  }
}
