import type { GetRowProps } from '@aubade/core/ui-kit'
import {
  Button,
  Surface,
  unit,
  ResourceList,
  Paragraph,
  ButtonWithConfirm,
} from '@aubade/core/ui-kit'
import { IconPlus, IconRecherche, IconDelete } from '@aubade/design/graphics'

import { useTranslate } from '@aubade/translation'
import type { Typesense } from '@aubade/types/typesense'
import { VStack, HStack } from '@chakra-ui/react'
import { useDelete } from '@nartex/data-provider/react'
import type { ColumnDef } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { useUrls } from 'src/App'

import { useAubadeQueryBuilder } from 'src/aubadeQueryBuilder/useAubadeQueryBuilder'

import { ListHeader } from '../../components/ListHeader'

import { FunctionCreateEdit } from './CreateEdit'

export function Functions() {
  const urls = useUrls()
  const columns = useGetColumns()
  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      paddingX={unit('10')}
      paddingY={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
    >
      <ListHeader
        scope="functions"
        mainAction={
          <Button
            variant="primary"
            label={'actions.newFunction'}
            leftIcon={IconPlus}
            to={urls.aubade().functionsCreate()}
          />
        }
      />
      <ResourceList<Typesense.Tools>
        scope="functions"
        resource="user_functions"
        dataProviderName="default"
        tableProps={{
          templateColumns: '1fr 100px',
          columns: columns,
          noDataLabel: 'table.noData.tools',
          getRowProps: useCallback<GetRowProps<Typesense.Tools>>(
            (row) => {
              return {
                to: urls.aubade().functionsEdit(row.id),
              }
            },
            [urls],
          ),
        }}
      />
      <FunctionCreateEdit />
    </Surface>
  )
}

function useGetColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'name',
        cell(props) {
          const { row } = props
          const { name } = row.original
          return (
            <VStack
              height="full"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Paragraph fontWeight="bold" text={name} ellipsis />
            </VStack>
          )
        },
      },

      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps
          const { id } = row.original
          return <Actions id={id} />
        },
      },
    ] satisfies ColumnDef<Typesense.Tools>[]
  }, [])
}

type ActionsProps = {
  id: string
}

function Actions(props: ActionsProps) {
  const { id } = props
  const translate = useTranslate()
  const urls = useUrls()

  const [mutateDelete] = useDelete()

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  async function onDelete(publicationId: string) {
    await mutateDelete(...aubadeQueryBuilder.deleteFunction(publicationId))
  }

  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      alignItems={'center'}
      justifyContent="flex-end"
    >
      <ButtonWithConfirm
        dialogProps={{
          title: 'functions.confirmTitle',
          children: translate('functions.confirmText'),
        }}
        confirmButtonProps={{
          isDisabled: false,
          label: 'functions.confirmButton',
          variant: 'primary',
        }}
        buttonProps={{
          isDisabled: false,
          leftIcon: IconDelete,
          variant: 'circle',
        }}
        onConfirm={() => onDelete(id)}
      />
      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().functionsEdit(id)}
      />
    </HStack>
  )
}
