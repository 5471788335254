import type { DataProvider, QueryMetaData } from '@nartex/data-provider'
import { mapValues } from 'remeda'

import { isRecord } from './fileUploadProxy'

/* eslint-disable @typescript-eslint/no-namespace, @typescript-eslint/consistent-type-definitions */
declare global {
  namespace NxDataProvider {
    export interface QueryMetaData {
      defaultValues?: Record<string, any>
    }
  }
}
/* eslint-enable @typescript-eslint/no-namespace, @typescript-eslint/consistent-type-definitions */

export function defaultValuesProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    async create(params) {
      const isCsvImport =
        params.resource?.includes('naturalPersons/import') ||
        params.resource?.includes('juridicalPersons/import')

      if (isCsvImport) {
        return dataProvider.create(params)
      }

      return dataProvider.create({
        ...params,
        variables: applyDefaultValues(
          params.variables as Record<string, any>,
          params.metaData,
        ),
      })
    },
    async update(params) {
      return dataProvider.update({
        ...params,
        variables: applyDefaultValues(
          params.variables as Record<string, any>,
          params.metaData,
        ),
      })
    },
  }
}

function applyDefaultValues(
  variables: Record<string, any>,
  metaData?: QueryMetaData,
) {
  function recurse(record: unknown): any {
    if (!isRecord(record)) return record

    const transformed = mapValues(record, (value) => {
      if (Array.isArray(value)) {
        return value.map(recurse)
      }

      if (isRecord(value)) {
        return recurse(value)
      }
      return value
    })

    return { ...metaData?.defaultValues, ...transformed }
  }

  // sometimes @type is not provided for the root values
  // it fixes the algorithm
  return recurse({ '@type': 'defaultType', ...variables })
}
