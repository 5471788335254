import { makeForm } from '@aubade/core/ui-kit'

import type { NotificationFormType } from '../types'

const { Input, useWatch, useFormContext } = makeForm<
  NotificationFormType,
  'planify' | 'publishedAt'
>()

export function PlanifySection() {
  const planify = useWatch('planify')

  const { setValue } = useFormContext()

  return (
    <>
      <Input.Checkbox
        name="planify"
        label="notifications.fields.planify"
        inputLabel="notifications.title.planify"
        onChange={(value) => {
          if (!value) {
            setValue('publishedAt', new Date())
          }
        }}
      />

      {planify && (
        <Input.DateTime
          name="publishedAt"
          label="notifications.fields.publishedAt"
          list
          min
        />
      )}
    </>
  )
}
