import { useList, useOne } from '@aubade/core/adapters'
import {
  fromS3ToResizedUrl,
  useDateFnsLocale,
  run,
  formatWeight,
} from '@aubade/core/libs'

import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  unit,
  Surface,
  Button,
  Form,
  Paragraph,
  makeInputs,
  useForm,
  useToastMessage,
  useDocumentUrl,
} from '@aubade/core/ui-kit'
import { IconDanger, IconDoubleCheck, IconSingleCheck } from '@aubade/design'
import { IconSend } from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import { useTranslate } from '@aubade/translation'
import type { ExtendedPostMessage, Contact } from '@aubade/types'
import type { Message, Document } from '@aubade/types/api'
import type { ExtendedOneConversation } from '@aubade/types/index'
import {
  VStack,
  HStack,
  Stack,
  Box,
  Image,
  Icon,
  Spinner,
} from '@chakra-ui/react'
import { toHydraId, cleanHydraId, useCreate } from '@nartex/data-provider/react'
import { listify } from '@nartex/stdlib'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { format } from 'date-fns'
import type { RefObject } from 'react'
import { useState, useEffect, useMemo, useRef } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import type { DefaultExtensionType } from 'react-file-icon'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { useWatch } from 'react-hook-form'
import { v4 } from 'uuid'

import { useAubadeQueryBuilder } from '../../../aubadeQueryBuilder/useAubadeQueryBuilder'

import { PrivateMention, UnavailableUsers } from './PrivateMention'

type ConversationHeaderProps = {
  conversation: ExtendedOneConversation
  infoState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  isInformational: boolean
}

export function ConversationBody(props: ConversationHeaderProps) {
  const { conversation, isInformational } = props
  const { users, id } = conversation

  const scrollRef = useRef<HTMLDivElement>(null)
  const [messages] = useList<Message.Read>({
    dataProviderName: 'default',
    // @ts-ignore
    resource: `conversations/${id}/messages`,
  })
  const { id: me } = useConnectedUser()
  const otherUsers = users.filter((user) => user.id !== me)

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({
      block: 'nearest',
    })
  }, [])

  return (
    <Surface
      width={'full'}
      height={`calc(90%)`}
      maxHeight={`calc(90%)`}
      direction={'column'}
    >
      <Stack
        width="full"
        paddingX={unit('40')}
        gap={'10px'}
        direction="column"
        height="full"
        maxHeight="full"
        overflow="auto"
      >
        <UnavailableUsers contacts={otherUsers} />
        {messages &&
          messages.map((message) => (
            <MessageContent key={message.id} message={message} />
          ))}
        <span ref={scrollRef}></span>
        <PrivateMention contacts={otherUsers} />
      </Stack>
      {users.length >= 2 && !isInformational && (
        <FieldMessage conversationId={conversation.id!} scroll={scrollRef} />
      )}
    </Surface>
  )
}

type MessageContentProps = {
  message: Message.Read
}

function MessageContent(props: MessageContentProps) {
  const { message } = props
  const { id: me } = useConnectedUser()

  const locale = useDateFnsLocale()

  const { author, createdAt, document, isRead } = message
  const externalAuthor = me !== cleanHydraId(author!.id!)
  const messageStyle = getMessageStyle(externalAuthor)
  const justifyContent = externalAuthor ? 'start' : 'end'

  const authorName = listify([author?.firstname, author?.lastname], ' ')

  const date = format(new Date(createdAt as string), 'dd LLLL à k:mm ', {
    locale,
  })

  const iconProps = {
    as: isRead ? IconDoubleCheck : IconSingleCheck,
    color: isRead ? 'blue.500' : 'white.500',
  }

  const hasDocument = document.length > 0

  return (
    <HStack width="full" p={2} justifyContent={justifyContent}>
      <VStack
        {...messageStyle}
        position="relative"
        width="fit-content"
        maxWidth={'400px'}
        paddingX={unit('20')}
        paddingY={unit('10')}
        borderRadius={'md'}
        alignItems={'flex-start'}
      >
        <HStack gap={'5px'}>
          <Paragraph fontWeight="bold" color="inherit" text={authorName} />
          <Paragraph
            color={externalAuthor ? 'darkGrey.500' : 'grey.500'}
            text={`- ${date}`}
          />
        </HStack>
        <VStack width="full" alignItems={'flex-start'} gap={4}>
          {hasDocument && <MessagePicture document={document[0]} />}
          <ParsedMessage message={message?.content} />
          {!externalAuthor && (
            <HStack
              gap={1}
              justifyContent={'flex-end'}
              position="absolute"
              right={2}
              bottom={2}
            >
              <Icon {...iconProps} width={unit('14')} height={unit('14')} />
            </HStack>
          )}
        </VStack>
      </VStack>
    </HStack>
  )
}

function MessagePicture(props: { document: Document.MessageRead }) {
  const { document } = props
  const { url, mimeType, displayName, size, extension } = document
  const parsedUrl = fromS3ToResizedUrl(url)
  const pictureUrl = useDocumentUrl(parsedUrl)

  const isPicture = mimeType.includes('image')

  const isVocal = mimeType.includes('audio')

  const fullSize = formatWeight(size)

  const defaultStyle = defaultStyles[extension as DefaultExtensionType]

  const queryBuilder = useAubadeQueryBuilder()

  const { mutateAsync: getFile, isLoading } = useMutation(
    queryBuilder.getMessageMedia(document, pictureUrl),
  )

  if (isLoading) return <Spinner />

  return (
    <Box
      cursor={'pointer'}
      width="full"
      overflow="hidden"
      onClick={async () => {
        await getFile()
        if (isPicture) {
          window.open(pictureUrl, '_blank')
        }
      }}
    >
      {run(() => {
        if (isPicture) {
          return (
            <Image
              src={pictureUrl}
              minWidth="100%"
              minHeight="100%"
              objectFit="cover"
            />
          )
        }
        if (isVocal) {
          return <ReactAudioPlayer controls src={pictureUrl} />
        }
        return (
          <HStack width="full">
            <Box width="60px">
              <FileIcon extension={extension} {...defaultStyle} />
            </Box>
            <VStack
              alignItems={'flex-start'}
              gap={0}
              width="full"
              overflow="hidden"
            >
              <Paragraph text={displayName} color={'inherit'} ellipsis />
              <Paragraph text={fullSize} color={'inherit'} fontWeight="bold" />
            </VStack>
          </HStack>
        )
      })}
    </Box>
  )
}

const Input = makeInputs<ExtendedPostMessage>()

type FieldMessageProps = {
  conversationId: string
  scroll: RefObject<HTMLDivElement>
}

function FieldMessage(props: FieldMessageProps) {
  const { conversationId, scroll } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const toastMessage = useToastMessage()
  const [newId] = useState(() => v4())

  const { id: me } = useConnectedUser()
  const [user] = useOne<Contact>({ iri: toHydraId('users', me) })
  const { isAvailable } = user!

  useEffect(() => {
    async function invalidate() {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const { queryKey } = query
          return queryKey.includes(`conversations/${conversationId}/messages`)
        },
      })
    }
    invalidate().catch(console.error)
  }, [conversationId, queryClient])

  const [postPicture, { isLoading }] = useCreate()

  const { saveButtonProps, ...methods } = useForm<ExtendedPostMessage>({
    mode: 'create',
    resource: 'messages',
    hookFormOptions: {
      defaultValues: {
        newId,
        conversation: conversationId,
      },
    },
    redirect() {
      return false
    },
    mutationOptions: {
      onError() {
        toastMessage('error', 'notifications.message.sendError')
      },
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: (query) => {
            const { queryKey } = query
            // return true
            return (
              queryKey.includes(`conversations/${conversationId}/messages`) ||
              queryKey.includes(`getOne`) ||
              queryKey.includes(`firebase`) ||
              queryKey.includes(`summary/conversations`)
            )
          },
        })
      },
    },
    blockNavigation: false,
  })

  const { watch } = methods

  const content = watch('content')

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  const { mutate: togglaAvailable } = useMutation(
    aubadeQueryBuilder.togglaAvailable(),
  )

  if (!isAvailable) {
    return (
      <Box width="full" padding={5}>
        <HStack
          width="full"
          gap={'10px'}
          backgroundColor="lightGrey.500"
          paddingY="10px"
          paddingX="20px"
          borderRadius={'20px'}
        >
          <Icon as={IconDanger} width="23px" height="20px" />
          <Paragraph text="message.user.unavailable" />
          <Button
            variant="text"
            color="darkGrey.500"
            label="message.user.toggleUnavailable"
            onClick={() =>
              togglaAvailable({
                isAvailable: !isAvailable,
              })
            }
          />
        </HStack>
      </Box>
    )
  }

  return (
    <Box width="full" padding={5}>
      <Form {...methods} bucket={{ type: 'Conversation' }}>
        <HStack
          width="full"
          gap={'10px'}
          height={unit('40')}
          alignItems={'center'}
        >
          <Input.TextArea
            name="content"
            placeholder={translate('message.writeMessage')}
            variant="textAreaMessageStyle"
          />
          {isLoading ? (
            <Spinner />
          ) : content && (content as string).length ? (
            <SendButton
              {...saveButtonProps}
              onClick={async (e) => {
                await saveButtonProps.onClick?.(e)
                await queryClient.invalidateQueries({
                  predicate: (query) => {
                    const { queryKey } = query
                    // return true
                    return (
                      queryKey.includes(
                        `conversations/${conversationId}/messages`,
                      ) ||
                      queryKey.includes(`getOne`) ||
                      queryKey.includes(`firebase`) ||
                      queryKey.includes(`summary/conversations`)
                    )
                  },
                })
                methods.setValue('document', undefined)
                methods.setValue('content', undefined)

                scroll?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                })
              }}
            />
          ) : (
            <Input.DropZone
              name="document"
              accept={{ '*': [] }}
              variant="icon"
              label="publications.fields.documents"
              objectId={conversationId}
              submitOnAccept={async (document: any) => {
                await postPicture(
                  {
                    resource: 'messages',
                    values: {
                      newId,
                      conversation: conversationId,
                      document: document,
                    },
                  },
                  {
                    async onSuccess() {
                      await queryClient.invalidateQueries({
                        predicate: (query) => {
                          const { queryKey } = query
                          return (
                            queryKey.includes(
                              `conversations/${conversationId}/messages`,
                            ) ||
                            queryKey.includes(`getOne`) ||
                            queryKey.includes(`firebase`) ||
                            queryKey.includes(`summary/conversations`)
                          )
                        },
                      })
                      scroll?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                      })
                      methods.setValue('document', undefined)
                      methods.setValue('content', undefined)
                    },
                  },
                )
              }}
            />
          )}
        </HStack>
      </Form>
    </Box>
  )
}

function SendButton(props: FormSubmitProps) {
  const content = useWatch<{ content?: string }>({ name: 'content' })
  const document = useWatch<{ document?: any }>({ name: 'document' })

  const isDisabled = useMemo(() => {
    if (!document && (!content || content.length === 0)) {
      return true
    }
    if (document) {
      return false
    }
    return false
  }, [content, document])

  return (
    <Button
      variant="circleNegative"
      leftIcon={IconSend}
      {...props}
      isDisabled={isDisabled}
      label=""
    />
  )
}

function getMessageStyle(isOdd: boolean) {
  if (isOdd) {
    return {
      backgroundColor: 'lightGrey.500',
      color: 'black.500',
    }
  } else {
    return {
      backgroundColor: 'darkGrey.500',
      color: 'white.500',
    }
  }
}

function ParsedMessage(props: { message?: string }) {
  const { message } = props
  if (!message) return <></>
  return (
    <Paragraph
      color={'inherit'}
      whiteSpace={'pre-line'}
      text={message}
      lineHeight={unit('20')}
    />
  )
}
