import { cleanHydraId, type DataProvider } from '@aubade/core/adapters'

import type { Agency } from '@aubade/types/api'

import { logicalToCrudApiFilter } from '../../../libs/logicalToCrudApiFilter'
import type { TemporaryStatSummary } from '../../../Resources/Statistics/types'

export function StatisticsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const { filters } = params

      const agencies = filters?.find(
        (filter) => 'field' in filter && filter.field === 'agencies',
      )

      let agencyId

      if (agencies && 'value' in agencies && agencies?.value?.[0]) {
        const { data } = await dataProvider.getOne<Agency.Read>({
          resource: 'agencies',
          id: agencies?.value?.[0],
        })
        agencyId = cleanHydraId(data.society)
      }

      const extendedFilters = agencyId
        ? [
            ...(filters ?? []),
            {
              field: 'societies',
              operator: 'eq',
              value: [agencyId],
            },
          ]
        : filters

      const crudFilters = filters
        ? logicalToCrudApiFilter(extendedFilters as any)
        : {}
      const data = (await dataProvider.custom!({
        method: 'post',
        url: `/summary/statistics`,
        payload: crudFilters,
      })) as any
      return {
        ...data,
        data: [data.data],
        total: data.data.length,
      }
    },
  }
}

export function StatisticsCountProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList() {
      const defaultfilters = [
        {
          field: 'startDate',
          operator: 'eq',
          value: new Date().getTime(),
        },
        {
          field: 'endDate',
          operator: 'eq',
          value: new Date().getTime(),
        },
        {
          field: 'displayType',
          operator: 'eq',
          value: 'day',
        },
      ]
      const crudFilters = logicalToCrudApiFilter(defaultfilters as any)
      const data = (await dataProvider.custom!({
        method: 'post',
        url: `/summary/statistics`,
        payload: crudFilters,
      })) as any
      return {
        ...data,
        data: [data.data],
        total: data.data.length,
      }
    },
  }
}

export function ExportStatisticsProxy(
  dataProvider: DataProvider,
  resource: 'customers' | 'associates',
): Partial<DataProvider> {
  return {
    async getList() {
      // return { data: fakeExport }
      return dataProvider.getList({
        // @ts-expect-error
        resource: `${resource}/export`,
      })
    },
  }
}

export function getAverage(stats: TemporaryStatSummary) {
  let sommePublications = 0
  let sommeNotifications = 0
  if (!stats.graph.length) {
    return {
      averagePublication: 0,
      averageNotification: 0,
    }
  }

  for (const item of stats.graph) {
    sommePublications += item.publications
    sommeNotifications += item.notifications
  }
  const averagePublication = sommePublications / stats.graph.length
  const averageNotification = sommeNotifications / stats.graph.length
  return {
    averagePublication: Math.round(averagePublication),
    averageNotification: Math.round(averageNotification),
  }
}

export function getTotalStats(stats: TemporaryStatSummary) {
  let sommePublications = 0
  let sommeNotifications = 0
  if (!stats.graph.length) {
    return {
      sommePublications: 0,
      sommeNotifications: 0,
    }
  }

  for (const item of stats.graph) {
    sommePublications += item.publications
    sommeNotifications += item.notifications
  }
  return {
    sommePublications,
    sommeNotifications,
  }
}
