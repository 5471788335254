// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconCoeurFilled = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.7142 2C31.4409 2 27.6177 3.88582 25 6.85881C22.3823 3.88582 18.5591 2 14.2858 2C6.39666 2 0 8.3965 0 16.2858C0 37.7142 25 48.4283 25 48.4283C25 48.4283 50 37.7142 50 16.2858C50 8.39666 43.6035 2 35.7142 2Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconCoeurFilled)
export default Memo
