import { cleanHydraId } from '@nartex/api-platform'
import { useCallback } from 'react'

export function Aubade() {}

Aubade.useUrls = function useUrls() {
  return useCallback(({ prefix = '' } = {}) => {
    return {
      prefix: `${prefix}`,
      publications: () => `${prefix}/publications`,
      publicationsCreateStandAlone: () => `${prefix}/publicationMobile`,
      publicationsCreate: () =>
        keepQueryString(`${prefix}/publications?page=create`),
      publicationsEdit: (id: string) =>
        keepQueryString(
          `${prefix}/publications?page=edit&id=${cleanHydraId(id)}`,
        ),
      feed: () => `${prefix}/fil`,
      feedEdit: (id: string) =>
        keepQueryString(`${prefix}/fil?page=edit&id=${cleanHydraId(id)}`),
      conversation: () => `${prefix}/messagerie`,
      conversationDetail: (id: string) =>
        keepQueryString(`${prefix}/messagerie?id=${cleanHydraId(id)}`),
      tools: () => `${prefix}/outils`,
      toolsCreate: () => keepQueryString(`${prefix}/outils?page=create`),
      toolsEdit: (id: string) =>
        keepQueryString(`${prefix}/outils?page=edit&id=${cleanHydraId(id)}`),
      collaboraters: () => `${prefix}/collaborateurs`,
      customers: () => `${prefix}/clients`,
      customersCreate: () => keepQueryString(`${prefix}/clients?page=create`),
      userDetail: (suffix: 'collaborateurs' | 'clients', id: string) =>
        keepQueryString(`${prefix}/${suffix}?page=edit&id=${cleanHydraId(id)}`),
      me: () => keepQueryString(`${prefix}/me`),
      notifications: () => `${prefix}/notifications`,
      notificationsCreate: () =>
        keepQueryString(`${prefix}/notifications?page=create`),
      notificationsEdit: (id: string) =>
        keepQueryString(
          `${prefix}/notifications?page=edit&id=${cleanHydraId(id)}`,
        ),
      statistics: () => `${prefix}/statistiques`,
      functions: () => `${prefix}/fonctions`,
      functionsCreate: () => keepQueryString(`${prefix}/fonctions?page=create`),
      functionsEdit: (id: string) =>
        keepQueryString(`${prefix}/fonctions?page=edit&id=${cleanHydraId(id)}`),
      campaign: () => `${prefix}/campagne`,
      campaignCreate: () => `${prefix}/campagne?page=create`,
      campaignEdit: (id: string) =>
        keepQueryString(`${prefix}/campagne?page=edit&id=${cleanHydraId(id)}`),
    }
  }, [])
}

function keepQueryString(url: string) {
  const queryString = window.location.search
  if (!queryString || queryString.length === 0) {
    return url
  }
  return `${url}&${queryString.replace('?', '')}`
}
