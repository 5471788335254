import type { SuggestedPaths } from '@nartex/stdlib'
import type { ReactNode } from 'react'
import type { FieldValues } from 'react-hook-form'

import { createContextFunction } from '../../utils/createContextFunction'

export const [FormScopeProvider, useFormScope] = createContextFunction<
  string | undefined
>('Form Scope', () => undefined)

type SubFormProps<
  TValues extends FieldValues,
  TFieldPath extends SuggestedPaths<TValues>,
> = {
  name: TFieldPath
  children?: ReactNode
}
export function makeEmbeddedForm<TValues extends FieldValues>() {
  return function SubForm<TFieldPath extends SuggestedPaths<TValues>>(
    props: SubFormProps<TValues, TFieldPath>,
  ) {
    const { name, children } = props

    const scope = useScopedName(name)
    return (
      <FormScopeProvider key={scope} value={scope}>
        {children}
      </FormScopeProvider>
    )
  }
}
export const EmbeddedForm = makeEmbeddedForm<Record<string, unknown>>()

/**
 * useScopedName
 */

// function overloads
export function useScopedName(): undefined

export function useScopedName<
  TFieldValues extends FieldValues,
  TFieldNames extends readonly SuggestedPaths<TFieldValues>[],
>(name: readonly [...TFieldNames]): typeof name

export function useScopedName<
  TFieldValues extends FieldValues,
  TFieldName extends SuggestedPaths<TFieldValues>,
>(name: TFieldName): typeof name

// implementation
export function useScopedName(
  name?: string | readonly string[] | undefined,
): typeof name {
  const formScope = useFormScope() ?? undefined
  if (!name) return formScope
  return scopeName(formScope, name as any)
}

/**
 * scopeName
 */

export function scopeName(formScope: string | undefined, name: string): string
export function scopeName(
  formScope: string | undefined,
  name: string | readonly string[],
): string | readonly string[]
export function scopeName(
  formScope: string | undefined,
  name: string | readonly string[] | undefined,
): string | readonly string[] | undefined
export function scopeName(
  formScope: string | undefined,
  name: string | readonly string[] | undefined,
): string | readonly string[] | undefined {
  if (!formScope) return name
  if (!name) return formScope
  if (Array.isArray(name)) return name.map((n) => `${formScope}.${n}`)
  return `${formScope}.${name}`
}

export function unScopeName(
  formScope: string | undefined,
  name: string | undefined,
): string | undefined {
  if (!formScope) return name

  if (name === formScope) {
    return undefined
  }

  if (name?.startsWith(`${formScope}.`)) {
    return name?.substring(formScope.length + 1)
  }

  throw Error(
    `The name ${JSON.stringify(
      name,
    )} is not scoped by the scope ${JSON.stringify(formScope)}`,
  )
}
