import { useMergeRefs, VStack } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import type { PropsWithChildren, ReactNode, KeyboardEventHandler } from 'react'
import { forwardRef, useState, useRef, useMemo, Suspense } from 'react'

import { useIsBusy, useBusyContext } from '../..'
import { useEvent, focusNext, focusPrev } from '../../../libs'
import { FetchError, SpinnerOverlay } from '../../Boundary'
import { TextInput } from '../../Inputs/TextInput'

import type { TextInputProps } from '../../Inputs/TextInput/TextInput'
import type { Option, PickerInstance } from '../types'

import { useOptionsController } from './optionsController'
import { SuggestOption } from './SuggestOption'

export { usePickerOption } from './optionsController'

export type SuggestRenderOptionsProps = { search: string }
export type SuggestProps = Pick<
  TextInputProps,
  'placeholder' | 'label' | 'helperText' | 'disabled'
> & {
  renderOptions: (optionsProps: SuggestRenderOptionsProps) => ReactNode
  hideFilters?: boolean
  renderFooter?: (optionsProps: SuggestRenderOptionsProps) => ReactNode
  renderFilters?: (options: Option[]) => ReactNode
  renderValues?: ReactNode
}

const Component = forwardRef<HTMLInputElement, SuggestProps>(function Suggest(
  props,
  ref,
) {
  const {
    renderOptions,
    renderValues,
    renderFooter: footer,
    renderFilters,
    disabled,
    ...inputProps
  } = props

  const [search, setSearch] = useState('')

  const onClose = useEvent(() => {
    setTimeout(() => placeholderInputRef.current?.focus(), 100)
  })
  const [cursor, setCursor] = useState<Option['id'] | null>(null)
  const pickerInstance = useMemo<PickerInstance>(() => {
    return {
      highlightedId: cursor,
      onClose,
    }
  }, [cursor, onClose])
  const [options, optionsContextWrapper] = useOptionsController(pickerInstance)

  const placeholderInputRef = useRef<HTMLInputElement>(null)

  const currentIndex = useMemo(() => {
    return options.findIndex((option) => option.id === cursor)
  }, [options, cursor])

  const renderedOptions = useMemo(() => {
    return renderOptions({ search })
  }, [search, renderOptions])

  const renderedFooter = useMemo(() => {
    return footer?.({ search })
  }, [search, footer])

  const onSearchChange = useEvent((newValue: string | null | undefined) => {
    setSearch(newValue ?? '')
  })

  const keyDownListener = useEvent<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'ArrowDown') {
        if (!options.length) return

        const nextIndex = (currentIndex + 1) % options.length
        setCursor(options[nextIndex].id)
        event.preventDefault()
      } else if (event.key === 'ArrowUp') {
        if (!options.length) return
        const index = currentIndex === -1 ? 0 : currentIndex
        const prevIndex = (options.length + index - 1) % options.length
        setCursor(options[prevIndex].id)
        event.preventDefault()
      } else if (event.key === 'Enter') {
        if (options.length) {
          const index = currentIndex === -1 ? 0 : currentIndex
          options[index].onClick()
        }
        event.preventDefault()
      } else if (event.key === 'Escape') {
        onClose() // refocus input
      } else if (event.key === 'Tab') {
        if (event.shiftKey) {
          focusPrev(placeholderInputRef.current)
        } else {
          focusNext(placeholderInputRef.current)
        }
        event.preventDefault()
      }
    },
  )

  const [isBusy, BusyContextWrapper] = useBusyContext()

  const textInputProps = {
    ...inputProps,
    type: 'search',
    onChange: onSearchChange,
    onKeyDown: keyDownListener,
    value: search,
    suggestions: [], // prevent navigator suggestions
  } satisfies TextInputProps

  return optionsContextWrapper(
    BusyContextWrapper(
      <VStack p={'2px'}>
        <TextInput
          {...textInputProps}
          variant="search"
          ref={useMergeRefs(placeholderInputRef, ref)}
        />

        {renderFilters?.(options)}
        <Boundary>
          <SpinnerOverlay position={'static'} isLoading={isBusy}>
            <VStack gap={4} width="full" height="full">
              {renderValues}
              {!disabled && renderedOptions}
            </VStack>
          </SpinnerOverlay>
        </Boundary>
        {renderedFooter}
      </VStack>,
    ),
  )
})

export const Suggest = Object.assign(Component, {
  Option: SuggestOption,
})

function Boundary(props: PropsWithChildren<{}>) {
  const { children } = props

  return (
    <ErrorBoundary fallback={(errorInfo) => <FetchError {...errorInfo} />}>
      <Suspense fallback={<SuspenseFallback />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

function SuspenseFallback() {
  useIsBusy(true)
  return <></>
  // return <Skeletons count={3} />
}
