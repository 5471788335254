import type { DataProvider, CrudSort } from '@aubade/core/adapters'
import { encodeFilters, encodePagination } from '@nartex/typesense'
import type { SearchResponse } from '@nartex/typesense'
import { groupBy } from 'remeda'

export function FunctionsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getList(params) {
      const { filters, hasPagination, pagination, sort, metaData } = params
      const { search, tsFilters } = groupBy(filters ?? [], (filter) => {
        if ('q' in filter) return 'search'
        if ('field' in filter) {
          return 'tsFilters'
        }
      })

      const sortBy = encodeSortBy(sort).sort_by
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        url: `user_functions/ts/search`,
        metaData,
        payload: {
          searches: [
            {
              collection: 'userFunction',
              query_by: 'name',
              ...encodePagination(hasPagination ? pagination : {}),
              // @ts-expect-error
              ...encodeFilters([...(search ?? []), ...(tsFilters ?? [])]),
              sort_by: sortBy,
              per_page: 200,
            },
          ],
        },
      })
      const { found } = searchResponse.data.results[0]
      const result =
        searchResponse.data.results[0].hits?.map((hit) => hit.document) ?? []

      return {
        data: result,
        total: found,
        raw: { typesense: result },
      }
    },
  }
}

function encodeSortBy<T>(sort: CrudSort<T>[] | undefined) {
  return {
    sort_by:
      sort === undefined
        ? undefined
        : sort
            .map((item) => {
              const { field, order } = item
              return `${field}:${order}`
            })
            .join(','),
  }
}
