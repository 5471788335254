import type { Transformer } from '@aubade/core/ui-kit'

import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'

import type { RadioButtonsProps } from './RadioButtons'
import { RadioButtons } from './RadioButtons'

export { RadioButtons as BaseRadioButtons } from './RadioButtons'

export type RHFRadioButtonsProps<T, Out = string> = RHFInputProps<
  T,
  Out,
  string | undefined
> &
  Pick<RadioButtonsProps, 'options' | 'inverse' | 'inline' | 'borderColor'>

export const RHFRadioButtons = function <T, Out = string>(
  props: RHFRadioButtonsProps<T, Out>,
) {
  const { options, inverse, borderColor } = props
  const innerProps = useRHFInput<T, Out, string | undefined>(props)

  return (
    <RadioButtons
      {...innerProps}
      options={options}
      inverse={inverse}
      borderColor={borderColor}
    />
  )
}

export const booleanTransformer: Transformer<
  boolean | null | undefined,
  string | undefined
> = {
  read(value) {
    if (value === true) return 'true'
    if (value === false) return 'false'
    if (!value) return undefined
  },
  write(value) {
    if (value === 'true') return true
    if (value === 'false') return false
    if (!value) return undefined
  },
}
