export function formatWeight(size: number) {
  if (size < 1000000) {
    return (
      (size / 1000).toLocaleString('fr-FR', {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }) + ' Ko'
    )
  }
  if (size < 1000000000) {
    return (
      (size / 1000000).toLocaleString('fr-FR', {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }) + ' Mo'
    )
  }
  return (
    (size / 1000000000).toLocaleString('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }) + ' Go'
  )
}
