import type { LogicalFilter } from '@aubade/core/adapters'
import { useFiltersSearchParams } from '@aubade/core/ui-kit'

import type { Typesense } from '@aubade/types/typesense'

import { cleanHydraId } from '@nartex/data-provider'

import type { ScopesType } from '../../../components/ScopeProvider'
import type { PublicationFilterType } from '../../Publications/filters/types'
import type { UserFilterType } from '../types'

export function useUserFilters(
  scope: ScopesType['collaboraters'] | ScopesType['customers'],
) {
  return useFiltersSearchParams<
    UserFilterType,
    Typesense.Associates | Typesense.Clients
  >({
    scope: scope,
    toCrudFilters(filtersState) {
      const { roles, agencies, societies, jobs } = filtersState
      const filteredRoles = roles?.filter((role) => role !== undefined)
      const filteredAgencies = agencies?.filter(
        (agency) => agency !== undefined,
      )
      const filteredSocieties = societies?.filter(
        (society) => society !== undefined,
      )

      const filteredJobs = jobs?.filter((job) => job !== undefined)

      return [
        filteredJobs && {
          field: 'jobs',
          operator: 'eq',
          value: filteredJobs,
        },
        filteredRoles && roleFilterToCrud(filteredRoles),
        filteredSocieties && societiesFilterToCrud(filteredSocieties),
        filteredAgencies && agenciesFilterToCrud(filteredAgencies),
      ]
    },
  })
}

function societiesFilterToCrud(
  values: string[],
): LogicalFilter<PublicationFilterType> {
  if (!values)
    return {
      field: 'societies',
      operator: 'eq',
      value: undefined,
    }

  const cleanValues = values.map(cleanHydraId)
  return {
    field: 'societies',
    operator: 'eq',
    value: cleanValues,
  }
}

function agenciesFilterToCrud(
  values: string[],
): LogicalFilter<PublicationFilterType> {
  if (!values)
    return {
      field: 'agencies',
      operator: 'eq',
      value: undefined,
    }

  const cleanValues = values.map(cleanHydraId)
  return {
    field: 'agencies',
    operator: 'eq',
    value: cleanValues,
  }
}

function roleFilterToCrud(roles: string[]): LogicalFilter<UserFilterType> {
  return {
    field: 'roles',
    operator: 'eq',
    value: roles,
  }
}
