import type { BaseRecord } from '@aubade/core/adapters'
import type { ColumnDef } from '@tanstack/react-table'
import { useReactTable, getCoreRowModel } from '@tanstack/react-table'

import type { GetRowProps } from '../Table'

import { BaseTable } from '.'

export type TableProps<T extends BaseRecord> = {
  columns: ColumnDef<T>[]
  variant?: 'draft'
  getRowProps?: GetRowProps<T>
  data: T[]
  noDataLabel?: string
  templateColumns: any
  hasPagination?: boolean
}

export function Table<T extends BaseRecord>(props: TableProps<T>) {
  const { columns, getRowProps, noDataLabel, data, templateColumns, variant } =
    props

  const table = useReactTable<T>({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <BaseTable
      table={table}
      getRowProps={getRowProps}
      noDataLabel={noDataLabel}
      templateColumns={templateColumns}
      variant={variant}
    />
  )
}

export type TableRowSelection = {
  selection: string[]
  resources: Record<string, string[]>
}
