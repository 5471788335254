import type { BaseRecord } from '@aubade/core/adapters'
import type { DataProvider } from '@nartex/data-provider'

export function ConversationsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getOne(params) {
      const { metaData } = params

      const conversation = await dataProvider.custom<BaseRecord>({
        ...params,
        ...metaData,
        hasPagination: false,
        method: 'get',
        url: `summary${params.id}`,
      })

      const { id: conversationId } = conversation.data
      const { data: messages } = await dataProvider.getList({
        ...params,
        ...metaData,
        hasPagination: false,
        resource: `conversations/${conversationId}/messages`,
      })
      return {
        ...conversation,
        data: {
          ...conversation.data,
          messages,
        },
      }
    },
  }
}
