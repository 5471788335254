import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/600.css'

import {
  inputTheme,
  switchTheme,
  modalTheme,
  drawerTheme,
  selectTheme,
} from './componentStyles'
import { themeColors } from './themeColors'

export const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'primary',
    components: ['Button'],
  }),

  {
    components: {
      Switch: switchTheme,
      Select: selectTheme,
      Input: inputTheme,
      NumberInputField: inputTheme,
      Modal: modalTheme,
      Drawer: drawerTheme,
    },
    colors: themeColors,
    shadows: {
      md: '4px 12px 24px 0px rgba(41, 114, 255, 0.28)',
      xl: '-15px 25px 50px 0px rgba(23, 57, 122, 0.08)',
      '2xl': '-15px 25px 50px 0px rgba(148, 163, 184, 0.11)',
    },
    radii: {
      xs: '4px',
      sm: '8px',
      md: '10px',
      lg: '20px',
    },
    sizes: {
      md: '450px',
      lg: '594px',
      xl: '800px',
      xxl: '1024px',
    },
    styles: {
      global: {
        body: {
          bg: 'lightGrey.500',
        },
        '.ais-Hits-list': {
          listStyle: 'none',
        },
        '.chakra-select__wrapper': {
          paddingX: '0px!important', // override default Select wrapper padding
        },
        'input[type="color"]::-moz-color-swatch': {
          border: 'none',
        },
        'input[type="color"]::-webkit-color-swatch': {
          border: 'none',
        },
        '.epr-emoji-category': {
          width: 'full',
        },
        '.epr-skin-tones': {
          display: 'none!important',
        },
        'input[type="color"]::-webkit-color-swatch-wrapper': {
          padding: 0,
          borderRadius: 0,
        },
        'input[type="date"]::-webkit-inner-spin-button': {
          display: 'none',
          background: 'transparent',
          color: 'red',
          '-webkit-appearance': 'none',
        },
        'input[type="date"]::-webkit-calendar-picker-indicator': {
          background: 'transparent',
          bottom: 0,
          color: 'transparent',
          cursor: 'pointer',
          height: 'auto',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          width: 'auto',
        },
      },
    },
    fonts: {
      heading: `'Montserrat', sans-serif`,
      body: `'Montserrat', sans-serif`,
      text: `'Montserrat', sans-serif`,
    },
    fontSizes: {
      '3xs': '0.4375rem', // 7px
      '2xs': '0.5625rem', // 9px
      xs: '0.625rem', // 10px
      sm: '0.75rem', // 12px
      md: '1rem', // 16px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
      '2xl': '1.75rem', // 28px
      '3xl': '2rem', // 32px
    },

    fontWeights: {
      bold: 600,
      normal: 400,
    },
    breakpoints: {
      sm: '576px',
      md: '800px',
      lg: '960px',
      xl: '1200px',
      // 2xl must be specified https://chakra-ui.com/docs/styled-system/responsive-styles#customizing-breakpoints
      '2xl': '1536px',
    },
  },
)
