import { useList, useOne } from '@aubade/core/adapters'
import type { SearchFilter } from '@aubade/core/adapters'
import { useQueryParamState } from '@aubade/core/libs'
import type { Filters } from '@aubade/core/ui-kit'
import {
  unit,
  DropdownMenu,
  Form,
  Paragraph,
  Accordion,
  makeForm,
} from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isAdmin } from '@aubade/domain/components'
import type { Contact } from '@aubade/types'
import { type Typesense } from '@aubade/types/typesense'
import { Box, HStack, Stack, VStack } from '@chakra-ui/react'

import { toHydraId } from '@nartex/api-platform'
import type { BaseSyntheticEvent } from 'react'
import { useId, useMemo } from 'react'
import type { DefaultValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { AgencyFilter, SocietyAgencyFilter } from 'src/components/Filters'

import { CategoryFilter } from 'src/components/Filters/CategoryFilter'
import { StringParam } from 'use-query-params'

import { NationalFilter } from '../../../components/Filters/nationalFilter'
import { ActiveFilters } from '../Components/ActiveFliters'

import type { PublicationFilterType } from './types'

const { Input } = makeForm<PublicationFilterType, 'agencies'>()

type Props<T> = {
  onChange: (filters: Filters) => void
  filterState: DefaultValues<T>
  resource: 'notifications' | 'publications'
}

export function FilterBar<T extends Filters>(props: Props<T>) {
  const { onChange, filterState, resource } = props
  const methods = useForm<PublicationFilterType>({
    // @ts-ignore
    defaultValues: filterState,
  })

  const formId = useId()
  const { handleSubmit } = methods
  const onFilterChange = handleSubmit(() => {
    onChange(methods.getValues())
  })

  return (
    <Form {...methods} onSubmit={onFilterChange} id={formId}>
      <VStack width="full">
        <Stack
          width="full"
          gap={5}
          justifyContent={'space-between'}
          direction={'row'}
          alignItems={'flex-start'}
          marginBottom={'-10px'}
        >
          <HStack gap={unit('20')} alignItems="flex-start">
            <Paragraph size="sm" text={`filters.${resource}.title`} />
            <DropdownMenu title="filters.publications.target">
              <SocietiesFilters onChange={() => onFilterChange()} />
            </DropdownMenu>
            <CategoryFilter
              title="filters.publications.category"
              name="jobs"
              onChange={() => onFilterChange()}
            />
          </HStack>

          <Box maxWidth="300px">
            <Input.Switch
              label={`filters.${resource}.filterMe`}
              name="me"
              onChange={() => onFilterChange()}
            />
          </Box>
        </Stack>
        <ActiveFilters onChange={onFilterChange} scope={resource} />
      </VStack>
    </Form>
  )
}

export function SocietiesFilters(props: {
  onChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
  context?: 'collaborators'
}) {
  const { onChange, context } = props
  const { id: me } = useConnectedUser()
  const paramName = 'societies.q'
  const [queryValue] = useQueryParamState(paramName, StringParam)

  const listFilters = useMemo(() => {
    const search = queryValue
    if (search) {
      const qFilter: SearchFilter = { q: search }

      return [qFilter]
    }
  }, [queryValue])

  const [currentUser] = useOne<Contact>(
    {
      iri: toHydraId('users', me ?? ''),
    },
    { enabled: Boolean(me) },
  )

  const [societies] = useList<Typesense.Societies>({
    resource: 'societies',
    dataProviderName: 'default',
    filters: listFilters,
    hasPagination: true,
    pagination: { pageSize: 250 },
  })

  if (!societies || !currentUser) return <></>

  if (currentUser?.agency) {
    return (
      <VStack
        gap={unit('5')}
        maxHeight={unit('500')}
        width="full"
        borderRadius={unit('10')}
        padding={unit('20')}
      >
        <Accordion>
          <NationalFilter onChange={onChange} />
        </Accordion>
      </VStack>
    )
  }

  const filterType = isAdmin(currentUser!.acRoles.AC)
    ? 'societyAgency'
    : 'agency'
  return (
    <VStack
      gap={unit('5')}
      maxHeight={unit('500')}
      width="full"
      borderRadius={unit('10')}
      padding={unit('20')}
    >
      <Accordion>
        {filterType === 'societyAgency' ? (
          <SocietyAgencyFilter
            onFilterChange={onChange}
            context={context}
            withNational
          />
        ) : (
          <AgencyFilter
            onFilterChange={onChange}
            withNational
            context={context}
          />
        )}
      </Accordion>
    </VStack>
  )
}
