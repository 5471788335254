import { useQueryParamsState, drawerQueryParams } from '@aubade/core/libs'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  FormDrawer,
  Form,
  Paragraph,
  useForm,
  Button,
  unit,
  makeForm,
  ButtonWithConfirm,
} from '@aubade/core/ui-kit'
import { DrawerContent } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { UserFunction } from '@aubade/types/api'
import { VStack } from '@chakra-ui/react'
import { vestResolver } from '@hookform/resolvers/vest'
import { useDelete } from '@nartex/data-provider/react'
import { useUrls } from 'src/App'
import { useAubadeQueryBuilder } from 'src/aubadeQueryBuilder/useAubadeQueryBuilder'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { useFormValidation } from './FormUtils/formValidator'

const { Input, useWatch } = makeForm<UserFunction.Write, 'name'>()

export function FunctionCreateEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page, id } = modalState
  const matchCreate = page === 'create'
  const matchUpdate = page === 'edit' && id
  const match = Boolean(matchCreate || matchUpdate)

  return (
    <FormDrawer isOpenProps={Boolean(match)}>
      <DrawerContent>
        {matchCreate && <FormCreate />}
        {matchUpdate && <FormEdit id={id!} />}
      </DrawerContent>
    </FormDrawer>
  )
}

function FormCreate() {
  const urls = useUrls()
  const suite = useFormValidation()

  const newId = useMemoOne(() => {
    return uuid()
  }, [])
  const { saveButtonProps, ...methods } = useForm<UserFunction.Write>({
    mode: 'create',
    resource: 'user_functions',
    redirect() {
      return urls.aubade().functions()
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues: {
        newId,
      },
      resolver: vestResolver(suite),
    },
  })

  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="lg"
        color="black.base"
        text="functions.title.create"
      />
      <Form {...methods} bucket={{ type: 'Tool' }}>
        <FormContent {...saveButtonProps} label="buttons.functions.create" />
      </Form>
    </>
  )
}

type FormProps = {
  id: string
}

function FormEdit(props: FormProps) {
  const { id } = props
  const urls = useUrls()
  const suite = useFormValidation()
  const { saveButtonProps, ...methods } = useForm<UserFunction.Write>({
    mode: 'update',
    resource: 'user_functions',
    id: id,
    redirect() {
      return urls.aubade().functions()
    },
    blockNavigation: false,
    hookFormOptions: {
      resolver: vestResolver(suite),
    },
  })

  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="md"
        color="black.base"
        text="functions.title.edit"
      />
      <Form {...methods} bucket={{ type: 'Tool' }}>
        <FormContent
          {...saveButtonProps}
          id={id}
          label="buttons.functions.update"
        />
      </Form>
    </>
  )
}

type FormContentProps = FormSubmitProps & {
  id?: string
}

function FormContent(props: FormContentProps) {
  const { id, ...saveButtonProps } = props

  const [mutateDelete] = useDelete()

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  async function onDelete(publicationId: string) {
    await mutateDelete(...aubadeQueryBuilder.deleteFunction(publicationId))
  }

  const translate = useTranslate()

  const values = useWatch()
  const suite = useFormValidation()

  const isDisabled = !suite(values).isValid()

  return (
    <>
      <Input.Text
        name="name"
        label="functions.fields.name"
        required
        maxLength={50}
      />

      <VStack width="full" gap={unit('20')} alignItems={'flex-start'}>
        <Button
          variant="primary"
          {...saveButtonProps}
          isDisabled={isDisabled}
          onClick={async (evt) => {
            await saveButtonProps?.onClick?.(evt)
          }}
        />
        {id && (
          <ButtonWithConfirm
            dialogProps={{
              title: 'functions.confirmTitle',
              children: translate('functions.confirmText'),
            }}
            confirmButtonProps={{
              isDisabled: false,
              label: 'functions.confirmButton',
              variant: 'primary',
            }}
            buttonProps={{
              label: 'buttons.deleteFunction',
              isDisabled: false,
              variant: 'text',
              isDangerous: true,
            }}
            onConfirm={() => onDelete(id)}
          />
        )}
      </VStack>
    </>
  )
}
