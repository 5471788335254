import type { HydraId } from './types'

export function cleanHydraId(id: string) {
  if (isHydraId(id)) {
    return last(id.split('/')) as string
  }
  return id
}

export function getResourceType(hydraId: HydraId) {
  return `${hydraId.split('/').slice(1, -1).join('/')}`
}

export function toHydraId<T extends string>(
  resource: T,
  id: string,
): HydraId<T> {
  if (typeof id !== 'string') return id
  return `/${resource}/${cleanHydraId(id)}`
}

export function isHydraId(
  id?: string,
  options?: { strict?: boolean },
): id is HydraId {
  if (!id) return false
  if (typeof id !== 'string') return false

  if (options?.strict) {
    return Boolean(
      id.match(
        // search for /<resource>/<uuid>
        /^\/.+\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
      ),
    )
  }

  return Boolean(
    id.match(
      // search for /<resource>/<string>[/<string>]
      /^\/.+\/.+(\/.+)*$/,
    ),
  )
}

function last<T>(array: T[]): T | undefined {
  return array[array.length - 1]
}
