import { useConnectedUser } from '@aubade/domain/adapters'

export function useIsBlocked(blockedBy?: string[]) {
  const { society, role } = useConnectedUser()

  if (role !== 'ROLE_APP_CRAFTER_COMPANY') return false
  if (!blockedBy || blockedBy.length === 0) return false
  if (society && society?.id && blockedBy.includes(society.id)) return true
  return false
}
