import { Box } from '@chakra-ui/react'
import type { ICommand } from '@uiw/react-md-editor'
import Picker, { Categories } from 'emoji-picker-react'
import { useState } from 'react'

import type { PreviewType } from '../../../../../../../node_modules/@uiw/react-md-editor/lib/Context'

export function useTogglePreview() {
  const [previewMode, setPreviewMode] = useState<PreviewType>('edit')

  const toggleView = {
    name: 'toggleView',
    keyCommand: 'toggle',
    buttonProps: { 'aria-label': 'toggle' },
    render: () => {
      const view = previewMode === 'preview' ? 'edit' : 'preview'
      const label = previewMode === 'preview' ? 'Edition' : 'Prévisualisation'
      return (
        <button
          onClick={(event) => {
            event.preventDefault()
            setPreviewMode(view)
          }}
        >
          {label}
        </button>
      )
    },
  } as ICommand
  return [toggleView, previewMode] as const
}

export function EmojiPicker(previewMode: PreviewType) {
  const [showPicker, setShowPicker] = useState(false)

  const emojiPicker = {
    name: 'emijiPicker',
    keyCommand: 'emoji',
    buttonProps: { 'aria-label': 'emoji' },
    render: (command, _disabled, executeCommand) => {
      return (
        <Box bg="white" width="full">
          <button
            disabled={previewMode === 'preview'}
            // style={{ position: 'relative' }}
            onClick={(event) => {
              event.preventDefault()
              setShowPicker(!showPicker)
            }}
          >
            😊
          </button>
          <Box
            position="absolute"
            top={'28px'}
            left={0}
            zIndex={1000}
            width={'full'}
          >
            <Picker
              open={showPicker}
              searchPlaceHolder="Rechercher ..."
              lazyLoadEmojis
              categories={[
                {
                  category: Categories.SUGGESTED,
                  name: 'suggestions',
                },
                {
                  category: Categories.SMILEYS_PEOPLE,
                  name: 'Smileys et personnes',
                },
                {
                  category: Categories.ANIMALS_NATURE,
                  name: 'Nature et animaux',
                },
                {
                  category: Categories.ACTIVITIES,
                  name: 'Activités',
                },
                {
                  category: Categories.FLAGS,
                  name: 'Drapeaux',
                },
                {
                  category: Categories.SYMBOLS,
                  name: 'Symboles',
                },
                {
                  category: Categories.FOOD_DRINK,
                  name: 'Aliments et boissons',
                },
              ]}
              width={'100%'}
              onEmojiClick={(emoji) => {
                executeCommand({ ...command, value: emoji.emoji })
                setShowPicker(false)
              }}
            />
          </Box>
        </Box>
      )
    },
    execute: (state, api) => {
      if (!state.command.value) return
      api.replaceSelection(state.command.value)
    },
  } as ICommand
  return emojiPicker
}
