import { memoizeLast } from '@nartex/stdlib'
import { useDebugValue, useMemo, useSyncExternalStore } from 'react'

import { useStabilizeResult } from '../hooks'
import { useEvent } from '../hooks/useEvent'

import { ExternalStore } from './ExternalStore'
import type { IExternalStore, Lens, UpdateFunc } from './types'

export class ProfunctorState<T> {
  #store: IExternalStore<T>
  getSnapShot: IExternalStore<T>['getSnapShot']
  setState: IExternalStore<T>['setState']

  static createRoot<S>(initial: S | (() => S)) {
    const externalStore =
      typeof initial === 'function'
        ? new ExternalStore((initial as () => S)())
        : new ExternalStore(initial)

    return new ProfunctorState<S>(externalStore)
  }

  constructor(store: IExternalStore<T>) {
    this.#store = store
    this.getSnapShot = this.#store.getSnapShot
    this.setState = this.#store.setState
  }

  debug = () => {
    this.#store.subscribe(() => console.debug(this.#store.getSnapShot()))
  }

  useState = () => {
    useDebugValue('ProfunctorState.useState')

    const setState = useEvent(this.setState)

    const state = useSyncExternalStore<T>(
      this.#store.subscribe,
      useStabilizeResult(this.getSnapShot),
    )

    return [state, setState] as const
  }

  useSelect = <S>(get: Lens<T, S>['get'], deps: any[]): S => {
    useDebugValue('ProfunctorState.useSelect')

    const store = useMemo(
      () =>
        this.promap({
          get,
          set: () => {
            throw Error('not implemented')
          },
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      deps,
    )

    return store.useState()[0]
  }

  promap = <S>(lens: Lens<T, S>): ProfunctorState<S> => {
    const get = memoizeLast(lens.get)
    const { set } = lens

    return new ProfunctorState({
      subscribe: this.#store.subscribe,
      getSnapShot: () => get(this.#store.getSnapShot()),
      setState: (state) =>
        this.#store.setState((parentState: T): T => {
          const prevState = get(parentState)

          if (typeof state === 'function') {
            state = (state as UpdateFunc<S>)(prevState)
          }

          if (prevState === state) return parentState
          else return set(parentState, state)
        }),
    })
  }
}
