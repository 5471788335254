import type { Merge } from '@aubade/core/libs'

import type {
  Publication,
  PostMessageEventPostMessageRequest,
  Conversation,
  GetSummaryConversationSummaryConversation,
  CraftCompany,
  BlackList,
  Notification,
} from '@aubade/types/api'

import type { Agency, Society, User } from './api'
import type { Message, Api, UUID } from './api/index'
import type { Typesense } from './typesense'
import type { CustomerRole, UserRole } from './Users'

export * from './utilityTypes'
export * from './Users'
export * from './Publications'
export * from './Tools'
export * from './Campaign'

export enum EXCEPTIONS {
  LOGGED_USER_IS_NOT_ADMIN = 'handledException.loggedUser.notAdmin',
  LOGGED_USER_IS_ADMIN = 'handledException.loggedUser.admin',
  ERROR_ACCESS_RESTRICTED = 'handledException.loggedUser.accessRestricted',
  LOGGED_USER_HAS_NO_AGENCY = 'handledException.loggedUser.noAgency',
  AGENCY_UNAUTHORIZED_FOR_USER = 'handledException.loggedUser.forbiddenAgency',
  NOT_FOUND = 'handledException.notFound',
  CITY_DISTRICT_NOT_FOUND = 'handledException.cityDistrictNotFound',
  SKIP = 'handledException.skip',
}

export type ExtendedPublicationsWrite = Omit<
  Publication.Write,
  'targetSocieties'
> & {
  linkType: string
  agencies?: string[]
  societies?: string[]
  targetSocieties: string[]
  isNational: boolean
  isAgencies: boolean
  jobs?: string[]
  allCategories?: boolean
  planify?: boolean
}

export type ExtendedNotificationWrite = Merge<
  Notification.Write,
  {
    societyId: string | null
    societies?: Api.IriReference[]
    agencies?: Api.IriReference[]
    jobs?: string[]
    isAgencies?: boolean
    jobsIds?: string[]
    allCategories?: boolean
  }
>

export type ExtendedPublicationsRead = Omit<
  Publication.Read,
  'targetSocieties'
> & {
  targetAgencies: string[]
  targetSocieties: string[]
  linkType: string
  isNational: boolean
  societyId: string | null
  toolId: string
  jobs?: string[]
}

export type ExtendedTypesenseUser = Merge<
  User.Read,
  { society: Society.Read; agency: Agency.Read }
>

export type ExtendedPostMessage = Merge<
  PostMessageEventPostMessageRequest.Write,
  { newId: string }
>

export type ExtendedUserConversation = Merge<
  User.Read,
  {
    id: UUID
    craftCompany?: CraftCompany.Read
    craftCompanyId?: string
    craftCompanyName?: string
    societyName?: string
    agencyName?: string
    agencyId?: string
    acRoles: {
      AC: UserRole | CustomerRole
    }
    society?: {
      id: UUID
      name: string
    }
    agency?: {
      id: UUID
      name: string
    }
  }
>

export type ExtendedConversationList = {
  users: ExtendedConversation[]
  messages: ExtendedConversation[]
}

export type ExtendedConversation = Merge<
  GetSummaryConversationSummaryConversation.Read,
  {
    users: ExtendedUserConversation[]
    isInformational?: boolean
  }
>

export type ExtendedConversationWrite = Merge<
  Conversation.Write,
  { newId: string; id?: string }
>

export type ExtendedAgency = Merge<Agency.Read, { society: Society.Read }>

export type ExtendedOneConversation = Merge<
  Conversation.Read,
  {
    messages?: Message.Read[]
    users: ExtendedUserConversation[]
    blackList?: BlackList.SummaryConversationRead
    isInformational?: boolean
  }
>

export type TemporaryTypesensenotification = Omit<
  Typesense.Publications,
  | 'url'
  | 'urlLabel'
  | 'documents'
  | 'documents.url'
  | 'documents.mimeType'
  | 'documents.pictureLink'
  | 'documents.playerEmbedUrl'
  | 'cover'
  | 'tool.id'
  | 'blocked_by'
>

export type ExtendedTypesensesocieties = Merge<
  Typesense.Societies,
  { 'society.id': string }
>

export type ExtendedTypesenseList = Merge<
  Typesense.Publications,
  {
    documents?: TypesenseDocuments[]
  }
>

type TypesenseDocuments = {
  mimeType?: string
  pictureLink?: string
  playerEmbedUrl?: string
  url?: string
}

export enum PublicationLinkEnum {
  'enum./publicationlink/WITHOUT_LINK' = 'enum./publicationlink/WITHOUT_LINK',
  'enum./publicationlink/TOOL_LINK' = 'enum./publicationlink/TOOL_LINK',
  'enum./publicationlink/EXTERNAL_LINK' = 'enum./publicationlink/EXTERNAL_LINK',
}
