import type { Merge } from '@nartex/stdlib'
import { useMemo } from 'react'

// Usefull to override RHF values without trigering its lazy behavior by spreading object
export function useProxyOverride<
  Target extends Record<string, any>,
  Override extends Record<string, any>,
>(proxified: Target, override: Override): Merge<Target, Override> {
  return useMemo(() => {
    return new Proxy(proxified, {
      get(target, requestedKey) {
        if (requestedKey in override) return (override as any)[requestedKey]
        return (target as any)[requestedKey]
      },
    }) as Merge<Target, Override>
  }, [proxified, override])
}
