import { useOne } from '@aubade/core/adapters'
import {
  unit,
  Surface,
  Paragraph,
  Button,
  ButtonWithConfirm,
} from '@aubade/core/ui-kit'
import {
  IconOutilsCouleurs,
  IconDelete,
  IconForbidden,
  IconInfo,
} from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import { ProfileCard } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { CraftCompany } from '@aubade/types/api'
import type {
  ExtendedOneConversation,
  ExtendedUserConversation,
} from '@aubade/types/index'
import { VStack, HStack } from '@chakra-ui/react'
import { cleanHydraId } from '@nartex/data-provider/react'
import { useMemo } from 'react'

import { useActions } from '../Actions/index'

type ConversationHeaderProps = {
  conversation: ExtendedOneConversation
  infoState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  isInformational: boolean
}

export function ConversationHeader(props: ConversationHeaderProps) {
  const { conversation, infoState, isInformational } = props
  const { users, name } = conversation

  return (
    <Surface
      width={'full'}
      padding={unit('10')}
      justifyContent={'center'}
      position={'relative'}
    >
      <ConversationUsers
        users={users}
        conversationName={name}
        isInformational={isInformational}
      />
      <Actions conversation={conversation} infoState={infoState} />
    </Surface>
  )
}

function ConversationUsers(props: {
  users: ExtendedUserConversation[]
  conversationName?: string
  isInformational: boolean
}) {
  const { users, conversationName, isInformational } = props
  const { id: me } = useConnectedUser()
  const translate = useTranslate()

  if (isInformational) {
    const otherUsers = users.filter((usr) => usr.id !== me)

    return (
      <ProfileCard
        title={conversationName ?? ' '}
        variant="big"
        maxWidth="350px"
        subtitle={translate('messages.subtitle.important')}
        picture={otherUsers?.[0].avatar}
      />
    )
  }
  if (users.length === 1) {
    return (
      <VStack
        width="full"
        height={unit('60')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Paragraph
          fontWeight="bold"
          text="messages.label.singleUserconversation"
        />
      </VStack>
    )
  }

  if (users.length === 2) {
    const otherUsers = users.find((usr) => usr.id !== me)
    const userName = [otherUsers?.firstname, otherUsers?.lastname]
      .filter(Boolean)
      .join(' ')

    const userFunction =
      otherUsers?.userFunction?.name ?? otherUsers?.acRoles?.AC ?? ''
    return (
      <ProfileCard
        title={userName}
        variant="big"
        maxWidth="350px"
        subtitle={translate(`${userFunction}`)}
        picture={otherUsers?.avatar}
        icon={otherUsers?.craftCompany ? IconOutilsCouleurs : undefined}
      />
    )
  }
  return (
    <ProfileCard
      multiple={users.length}
      title={conversationName ?? translate('messages.list.group')}
      maxWidth="350px"
      variant="big"
      ellipsis
      subtitle={users
        .map((user) =>
          [user.firstname, user.lastname].filter(Boolean).join(' '),
        )
        .join(', ')}
    />
  )
}

type ActionsProps = {
  conversation: ExtendedOneConversation
  infoState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}
function Actions(props: ActionsProps) {
  const { conversation, infoState } = props
  const { id: me, agency } = useConnectedUser()
  const { id, users, blackList, isInformational } = conversation
  const { deleteConversation, canBlock, isBlocked, toggleBlackList } =
    useActions(id!, users, blackList)
  const translate = useTranslate()

  const otherUsers = useMemo(
    () => users.filter((user) => cleanHydraId(user.id) !== cleanHydraId(me)),
    [me, users],
  )
  const otherUserAgencyId = useGetOtherUserAgency(otherUsers)

  const canBlockUser =
    canBlock &&
    otherUserAgencyId &&
    agency &&
    agency.id &&
    cleanHydraId(otherUserAgencyId) === cleanHydraId(agency.id)

  const [showInfo, setShowInfo] = infoState

  const toggleInfo = () => {
    setShowInfo(!showInfo)
  }

  return (
    <HStack position="absolute" right={unit('40')} top={0} height="full">
      {canBlockUser && (
        <Button
          variant="circleList"
          leftIcon={IconForbidden}
          iconColor={isBlocked ? 'error.dark' : undefined}
          onClick={async () => {
            await toggleBlackList()
          }}
        />
      )}
      {!isInformational && (
        <Button leftIcon={IconInfo} variant="circleList" onClick={toggleInfo} />
      )}
      <ButtonWithConfirm
        dialogProps={{
          title: 'conversation.confirmTitle',
          children: translate('conversation.confirmText'),
        }}
        confirmButtonProps={{
          isDisabled: false,
          label: 'conversation.confirmButton',
          variant: 'primary',
        }}
        buttonProps={{
          isDisabled: false,
          variant: 'circleList',
          leftIcon: IconDelete,
        }}
        onConfirm={() => deleteConversation()}
      />
    </HStack>
  )
}

function useGetOtherUserAgency(
  otherUsers: ExtendedUserConversation[],
): string | undefined {
  const [craftCompany] = useOne<CraftCompany.Read>(
    {
      resource: 'craft_companies',
      id: cleanHydraId(otherUsers[0]?.craftCompany?.id!),
    },
    {
      enabled: Boolean(otherUsers[0]?.craftCompany?.id),
    },
  )
  if (craftCompany && craftCompany.agency) {
    return craftCompany.agency
  }
  return undefined
}
