import { once } from '@nartex/stdlib'

import type { ConditionalFilter, SearchFilter, LogicalFilter } from './types'

/**
 * Creates a CRUD filter builder for the targeted type.
 *
 * @example
 * ```ts
 * import { makeCrudFilterBuilder } from '@nartex/data-provider'
 * type Record = { foo: 'bar'; baz: 'lol' }
 *
 * // init with the Record type for intellisense
 * const crud = makeCrudFilterBuilder<Record>()
 *
 * const filters = [
 *  crud.q,
 *  crud.or([
 *   crud.and([
 *      crud.filter('foo', 'eq', 'bar'),
 *      crud.filter('bad', 'gte', null),
 *    ]),
 *  ]),
 * ]
 * ```
 *
 * @template T - The type of the data to filter.
 * @returns An object with methods to build different types of filters.
 */
export const makeCrudFilterBuilder = once(function makeCrudFilterBuilder<T>() {
  return {
    and(value: ConditionalFilter<T>['value']): ConditionalFilter<T> {
      return {
        operator: 'and',
        value,
      }
    },

    or(value: ConditionalFilter<T>['value']): ConditionalFilter<T> {
      return {
        operator: 'or',
        value,
      }
    },

    q(search: string): SearchFilter {
      return { q: search }
    },

    filter<Field extends LogicalFilter<T>['field']>(
      field: Field,
      operator: LogicalFilter<T>['operator'],
      value: Field extends keyof T ? T[Field] : any,
    ): LogicalFilter<T> {
      return {
        field,
        operator,
        value,
      }
    },
  }
})

export const crud = makeCrudFilterBuilder<object>()
