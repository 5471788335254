import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  useForm,
  Form,
  Button,
  Paragraph,
  makeForm,
  unit,
} from '@aubade/core/ui-kit'
import { HStack, Spinner, VStack } from '@chakra-ui/react'
import { useCreate } from '@nartex/data-provider/react'
import { useCallback, useState } from 'react'
import { useUrls } from 'src/App'

import { DrawerContent } from '../../../../domain/src/components'

import type {
  CrafterEventCrafterRegisterRequest,
  CrafterEventCrafterUser,
  CrafterEventCrafterSearchRequest,
} from '../../../../types/src/api/index'

import type { CustomerCreate, Admin, SearchResponseError } from './types'

const { Input, useFormContext, useWatch } = makeForm<
  RegisterPayload,
  'crafterCode' | 'firstname' | 'lastname' | 'email' | 'username'
>()

export function FormCreate() {
  const urls = useUrls()

  // @ts-expect-error
  const { saveButtonProps, ...methods } = useForm<CustomerCreate>({
    mode: 'create',
    resource: 'craft_companies/admin/register',
    hookFormOptions: {
      mode: 'all',
    },
    redirect() {
      return urls.aubade().customers()
    },
    blockNavigation: false,
  })

  return (
    <DrawerContent>
      <Form {...methods}>
        <VStack width="full" gap={unit('40')} alignItems={'flex-start'}>
          <VStack alignItems={'flex-start'} gap={unit('10')}>
            <Paragraph
              size="lg"
              fontWeight="bold"
              text="customers.titles.create"
            />
            <Paragraph size="sm" text="customers.subtitle.create" />
          </VStack>
          <FormContent {...saveButtonProps} />
        </VStack>
      </Form>
    </DrawerContent>
  )
}

function FormContent(props: FormSubmitProps) {
  const { ...saveButtonProps } = props
  const { setValue } = useFormContext()
  const [response, setResponse] = useState<SearchResponse>()
  const [error, setError] = useState<string>()
  const [mutationCreate, { isLoading }] = useCreate<SearchResponse>()

  const checkClient = useCallback(
    async (value: string) => {
      try {
        await mutationCreate(
          {
            resource: 'craft_companies/admin/search',
            values: {
              code: value,
            },
          },
          {
            async onSuccess(resp) {
              const { firstname, lastname, email, crafterCode, username } =
                resp.data
              setResponse(resp.data)
              firstname && setValue('firstname', firstname)
              lastname && setValue('lastname', lastname)
              username && setValue('username', username)
              crafterCode && setValue('crafterCode', crafterCode)
              email && setValue('email', email)
              setError(undefined)
            },
            async onError(responseError: any) {
              const errorCode = (responseError as SearchResponseError).response
                .data.code
              setResponse(undefined)
              if (errorCode) {
                setError(errorCode)
              } else {
                setError(undefined)
              }
            },
          },
        )
      } catch (err: any) {
        console.log('error', err)
      }
    },
    [mutationCreate, setValue],
  )

  const rules = {
    validate: (value: string) => {
      if (!value || value.length === 0) {
        return 'errors.customers.missingFields'
      }
    },
  }

  const [firstname, lastname, email] = useWatch([
    'firstname',
    'lastname',
    'email',
  ])

  return (
    <>
      <VStack width="full" gap={unit('10')} alignItems={'flex-start'}>
        <Input.Text
          label="customers.fields.customercode"
          name="code"
          onChange={async (value) => {
            if (value) {
              await checkClient(value)
            }
          }}
        />

        {isLoading ? (
          <Spinner w="20px" h="20px" />
        ) : (
          <>
            {error && <Paragraph text={`error.${error}`} color={'red.500'} />}
            {response?.crafterCode && (
              <Paragraph
                text={'success.customers.create'}
                color={'primary.500'}
              />
            )}
          </>
        )}
      </VStack>
      {!error && response && (
        <>
          <HStack width="full" gap={unit('20')}>
            <Input.Text
              label="customers.fields.firstname"
              name="firstname"
              rules={rules}
              disabled={Boolean(response.firstname)}
            />
            <Input.Text
              label="customers.fields.lastname"
              name="lastname"
              rules={rules}
              disabled={Boolean(response.lastname)}
            />
          </HStack>
          <Input.Text
            label="customers.fields.email"
            name="email"
            rules={rules}
            disabled={Boolean(response.email)}
          />

          <HStack width="full" paddingY={unit('60')}>
            <Button
              {...saveButtonProps}
              variant="primary"
              label="buttons.createcustomer"
              isDisabled={!isAdminComplete({ firstname, lastname, email })}
            />
          </HStack>
        </>
      )}
    </>
  )
}

function isAdminComplete(admin?: Partial<Admin>) {
  if (!admin) return false
  const { firstname, lastname, email } = admin
  if (firstname && lastname && email) {
    return true
  }
  return false
}

type SearchResponse = CrafterEventCrafterUser.Read

type RegisterPayload = CrafterEventCrafterRegisterRequest.Write &
  Pick<CrafterEventCrafterSearchRequest.Write, 'code'>
