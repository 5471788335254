import type {
  BaseRecord,
  GetListParams,
  GetListResponse,
  DataProvider,
  CrudSort,
  CrudFilters,
  QueryMetaData,
  LogicalFilter,
} from '@nartex/data-provider'
import {
  type AutoCompleteString,
  type Merge,
  run,
  isTruthy,
  isEmpty,
} from '@nartex/stdlib'
import type { Pagination } from '@pankod/refine-core'
import type { AxiosInstance } from 'axios'
import { groupBy } from 'remeda'

import type { SearchParams, SearchResponse } from '..'

import { encodeFilters } from './encodeFilter'
import { createMultiSearch } from './multiSearch'

export { encodeFilters }

// Declaration merging the QueryMetaData definition to add a new `searchParams` option
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NxDataProvider {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    export interface QueryMetaData {
      searchParams?: Partial<MultiSearchParams>
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    export interface ResponseRawData<T extends BaseRecord> {
      typesense?: SearchResponse<T>
    }
  }
}

export type MultiSearchParams<Collections extends string = string> =
  SearchParams & {
    collection: AutoCompleteString<Collections>
  }

export type TypesenseDataProviderConfig = {
  httpClient: Pick<AxiosInstance, 'request'>
  baseURL: string
  transformQuery: (
    getParams: <T extends BaseRecord>() => AugmentedParams<T>,
  ) => GetListParams<BaseRecord & Record<string, any>>
  toCollectionName: (resource: string) => string
}
export class TypesenseDataProvider implements DataProvider {
  #config: TypesenseDataProviderConfig
  #multiSearch: ReturnType<typeof createMultiSearch>
  constructor(config: TypesenseDataProviderConfig) {
    this.#config = config
    this.#multiSearch = createMultiSearch(config.httpClient, config.baseURL)
  }

  async getList<T extends BaseRecord>(
    getListParams: GetListParams<T>,
  ): Promise<GetListResponse<T>> {
    const { transformQuery, toCollectionName } = this.#config
    const { resource, filters, sort, hasPagination, pagination, metaData } =
      transformQuery(() =>
        // @ts-expect-error WTF typescript consider that CrudFilter is not assignable to CrudFilter
        augmentParams(getListParams),
      ) as GetListParams<BaseRecord>

    const resourceHasBeenTransformed = resource !== getListParams.resource
    const collection = resourceHasBeenTransformed
      ? resource!
      : toCollectionName(resource!)

    const computedPagination = run(() => {
      if (hasPagination === false) {
        return {}
      }
      return (
        pagination ?? {
          // Typesense default values needed for api typesense proxy
          current: 1,
          pageSize: 10,
        }
      )
    })

    const searchResponse = await this.search<T>(
      {
        collection,
        query_by: '', // required !
        ...encodeFilters(filters ?? []),
        ...encodePagination(computedPagination),
        ...encodeSortBy(sort),
        ...metaData?.searchParams,
      },
      metaData,
    )

    const data: T[] = searchResponse.hits?.map((hit) => hit.document) ?? []

    return {
      data,
      total: searchResponse.found,
      raw: { typesense: searchResponse },
    }
  }

  async getOne(): Promise<any> {
    throw Error(`Method TypesenseDataProvider.getOne() is not implemented`)
  }

  async create(): Promise<any> {
    throw Error(`Method TypesenseDataProvider.create() is not implemented`)
  }

  async deleteOne(): Promise<any> {
    throw Error(`Method TypesenseDataProvider.deleteOne() is not implemented`)
  }

  async update(): Promise<any> {
    throw Error(`Method TypesenseDataProvider.update() is not implemented`)
  }

  async custom(): Promise<any> {
    throw Error(`Method TypesenseDataProvider.custom() is not implemented`)
  }

  getApiUrl(): string {
    throw Error(`Method TypesenseDataProvider.getApiUrl() is not implemented`)
  }

  private async search<T extends BaseRecord>(
    searchParams: MultiSearchParams,
    options?: Pick<QueryMetaData, 'headers' | 'signal'>,
  ): Promise<SearchResponse<T>> {
    const { collection } = searchParams
    const { signal } = options ?? {}
    const promise = this.#multiSearch(searchParams, options)

    signal?.addEventListener('abort', () => promise.abort())

    const result = await promise

    if ('error' in result) {
      const error = Error(
        `Typesense ${result.code} : Got ${JSON.stringify(
          result.error,
        )} while requesting the following collection: ${JSON.stringify(
          collection,
        )}`,
        {
          cause: Error(result.error),
        },
      )

      // Check for specific typesense error if the collection is empty
      // https://github.com/typesense/typesense/issues/1051
      const isBrokenQueryBy = result.error.match(
        /Could not find a field named `(.*)` in the schema\./,
      )
      if (searchParams.query_by && isBrokenQueryBy) {
        const wrongField = isBrokenQueryBy[1]

        logOnce(
          `${error.message}, querying without \`${wrongField}\` as fallback...`,
        )

        return this.search<T>(
          {
            ...searchParams,
            query_by: searchParams.query_by
              .split(',')
              .filter((q) => q.trim() !== wrongField)
              .join(','),
          },
          options,
        )
      }

      // Check for specific typesense error if the collection is empty
      // https://github.com/typesense/typesense/issues/1948
      const isBrokenFilterBy = result.error.match(
        /Could not find a filter field named `(.*)` in the schema\./,
      )
      if (isBrokenFilterBy) {
        logOnce(`${error.message}, returning an empty result as fallback...`)

        return {
          found: 0,
          out_of: 0,
          page: searchParams.page ?? 1,
          request_params: searchParams,
          search_time_ms: 0,
        }
      }

      throw error
    }

    return result
  }
}

const logged = new Set<string>()
function logOnce(message: string) {
  if (!logged.has(message)) {
    logged.add(message)
    console.error(message)
  }
}

export function encodePagination(
  pagination?: Pagination,
): Pick<SearchParams, 'page' | 'per_page'> {
  return { page: pagination?.current, per_page: pagination?.pageSize }
}

export function encodeSortBy(
  sort?: CrudSort<unknown>[],
): Pick<SearchParams, 'sort_by'> {
  return {
    sort_by: sort
      ?.map((item) => {
        const { field, order } = item
        return `${field}:${order}`
      })
      .join(','),
  }
}

export type AugmentedParams<T extends BaseRecord> = Merge<
  GetListParams<T>,
  {
    withSearchParams: (
      searchParams: Partial<MultiSearchParams>,
    ) => AugmentedParams<T>
    withFilters: (filters: CrudFilters<T>) => AugmentedParams<T>
    withFiltersAsNxCustomParams: (
      filtersNames: LogicalFilter<T>['field'][],
    ) => AugmentedParams<T>
    removeFilter: (
      filterToRemove: LogicalFilter<T>['field'],
    ) => AugmentedParams<T>
  }
>

export function augmentParams<T extends BaseRecord = BaseRecord>(
  params: GetListParams<T>,
): AugmentedParams<T> {
  return {
    ...params,
    withSearchParams(searchParams) {
      return augmentParams({
        ...params,
        metaData: {
          ...params.metaData,
          searchParams: {
            ...params.metaData?.searchParams,
            ...searchParams,
          },
        },
      })
    },

    withFilters(filters) {
      return augmentParams({
        ...params,
        filters: [...filters, ...(params.filters ?? [])],
      })
    },

    withFiltersAsNxCustomParams(filtersNames) {
      const { custom, typesense } = groupBy(params.filters ?? [], (filter) => {
        if ('field' in filter) {
          if (filter.field && filtersNames.includes(filter.field as any)) {
            return 'custom'
          }
        }
        return 'typesense'
      })

      const nxCustomParams =
        custom &&
        Object.fromEntries(
          custom
            ?.map((filter) => {
              if ('field' in filter && filter.field) {
                if (filter.value === null) return [filter.field, filter.value]
                if (!isEmpty(filter.value)) {
                  return [filter.field, filter.value]
                }
              }
              return undefined
            })
            .filter(isTruthy),
        )
      return augmentParams({
        ...params,
        filters: typesense,
        metaData: {
          ...params.metaData,
          searchParams: {
            ...params.metaData?.searchParams,
            nx_custom_params: {
              ...nxCustomParams,
              ...params.metaData?.searchParams?.nx_custom_params,
            },
          },
        },
      })
    },

    removeFilter(filterToRemove) {
      return augmentParams({
        ...params,
        filters: params.filters?.filter((filter) => {
          if ('field' in filter) {
            return filter.field !== filterToRemove
          }
          return true
        }),
      })
    },
  }
}
