import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { Conversation } from './Components'
import { ConversationList } from './List/index'

export function List() {
  return (
    <ResourcePageLayout>
      <ConversationList />
      <Conversation />
    </ResourcePageLayout>
  )
}
