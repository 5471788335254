import type {
  ConditionalFilter,
  CrudFilter,
  AnyFilter,
  SearchFilter,
  NestedFilter,
  LogicalFilter,
} from './types'

export function isLogicalFilter<T extends Record<string, any> = any>(
  filter: CrudFilter<T>,
): filter is LogicalFilter<T> {
  if ('field' in filter && 'operator' in filter) {
    return true
  }
  return false
}

export function isConditionalFilter<T extends Record<string, any> = any>(
  filter: CrudFilter<T>,
): filter is ConditionalFilter<T> {
  if ('operator' in filter && ['or', 'and'].includes(filter.operator)) {
    return true
  }
  return false
}

export function isNestedFilter<T extends Record<string, any> = any>(
  filter: CrudFilter<T>,
): filter is NestedFilter<T> {
  if ('collection' in filter) {
    return true
  }
  return false
}

export function isSearchFilter<T extends Record<string, any> = any>(
  filter: AnyFilter<T>,
): filter is SearchFilter {
  return 'q' in filter
}
