import type { BaseRecord } from '@aubade/core/adapters'
import { useCallback } from 'react'

import { Table } from '../Table'
import type { TableProps } from '../Table'

import type { ResourceListContainerProps } from './ResourceListContainer'
import { ResourceListContainer } from './ResourceListContainer'

type ResourceListProps<T extends BaseRecord> = Omit<
  ResourceListContainerProps<T>,
  'children'
> & {
  tableProps: Omit<TableProps<T>, 'data'>
}

export function ResourceList<T extends BaseRecord>(
  props: ResourceListProps<T>,
) {
  const { tableProps, ...rest } = props
  const { hasPagination = false } = tableProps

  return (
    <ResourceListContainer<T>
      {...rest}
      // eslint-disable-next-line react/no-children-prop
      children={useCallback(
        (data, pagination) => {
          return (
            <>
              <Table {...tableProps} data={data} variant={props?.variant} />
              {hasPagination && pagination}
            </>
          )
        },
        [hasPagination, tableProps, props?.variant],
      )}
    />
  )
}
