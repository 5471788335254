import { Button } from '@aubade/core/ui-kit'
import { IconPlus } from '@aubade/design/graphics'
import { useUrls } from 'src/App'

export function CreateCampaign() {
  const urls = useUrls()

  return (
    <Button
      variant="primary"
      label={'actions.newCampaign'}
      leftIcon={IconPlus}
      to={urls.aubade().campaignCreate()}
    />
  )
}
