import { makeInputs } from '@aubade/core/ui-kit'
import { makeForm as makeNxForm } from '@nartex/react-libs'
import { once } from '@nartex/stdlib'
import type { FieldValues } from 'react-hook-form'

function makeFormImpl<T extends FieldValues, Props extends keyof T = never>() {
  return {
    /** Components */
    Input: makeInputs<T>(), // but keep on providing the complete type for inputs autocompletion
    ...makeNxForm<T, Props>(),
  }
}

export const makeForm = once(makeFormImpl) as typeof makeFormImpl
