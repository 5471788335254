import type {
  BaseRecord,
  GetListParams,
  ResourceDataProvider,
} from '@aubade/core/adapters'

import type { AutoCompleteString } from '@aubade/core/libs'
import type { Typesense } from '@aubade/types/typesense'
import type { DataProvider } from '@nartex/data-provider'
import {
  encodeFilters,
  encodePagination,
  encodeSortBy,
  type MultiSearchParams,
} from '@nartex/typesense'
import { groupBy } from 'remeda'

import {
  AgenciesProxy,
  ClientsProxy,
  ContactsProxy,
  ConversationsProxy,
  FeedProxy,
  FunctionsProxy,
  MessagesProxy,
  NotificationsProxy,
  PublicationsProxy,
  SummaryProxy,
  ToolsProxy,
} from './proxies'
import { CampaignProxy } from './proxies/campaign'
import { CollaboratersProxy } from './proxies/collaboraters'
import { SocietiesProxy } from './proxies/societies'
import {
  ExportStatisticsProxy,
  StatisticsCountProxy,
  StatisticsProxy,
} from './proxies/statistics'
import { UsersProxy } from './proxies/users'

export function antiCorruptionLayerProxy(dataProvider: DataProvider) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const { resource } = params
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }

        return (dataProvider[methodName] as any)(params)
      }
    },
  })
}

function Proxies(
  dataProvider: DataProvider,
): Record<string, Partial<ResourceDataProvider<any>>> {
  return {
    publications: PublicationsProxy(dataProvider),
    notifications: NotificationsProxy(dataProvider),
    feed: FeedProxy(dataProvider),
    contacts: ContactsProxy(dataProvider),
    agencies: AgenciesProxy(dataProvider),
    'messages/': MessagesProxy(dataProvider),
    // @ts-expect-error
    tools: ToolsProxy(dataProvider),
    users: UsersProxy(dataProvider),
    conversations: ConversationsProxy(dataProvider),
    'summary/conversations': SummaryProxy(dataProvider),
    clients: ClientsProxy(dataProvider),
    associates: CollaboratersProxy(dataProvider),
    statistics: StatisticsProxy(dataProvider),
    countStatistics: StatisticsCountProxy(dataProvider),
    'export/customers': ExportStatisticsProxy(dataProvider, 'customers'),
    'export/associates': ExportStatisticsProxy(dataProvider, 'associates'),
    societies: SocietiesProxy(dataProvider),
    user_functions: FunctionsProxy(dataProvider),
    campaign: CampaignProxy(dataProvider),
  }
}

export type MulmtiSearchPayload = {
  searches: [MultiSearchParams]
}

export function encodePayloadApiToTypesense<T extends BaseRecord>(
  params: GetListParams<T>,
  collection: AutoCompleteString<Typesense.Collection>,
  queryBy: string,
): MulmtiSearchPayload {
  const { filters, sort, pagination } = params
  const { search, tsFilters } = groupBy(filters ?? [], (filter) => {
    if ('q' in filter) return 'search'
    if ('field' in filter) {
      return 'tsFilters'
    }
  })
  const sortBy = encodeSortBy(sort).sort_by
  return {
    searches: [
      {
        collection: collection,
        query_by: queryBy,
        ...encodePagination(pagination ?? {}),
        // @ts-expect-error
        ...encodeFilters([...(search ?? []), ...(tsFilters ?? [])]),
        sort_by: sortBy,
      },
    ],
  }
}
