// eslint-disable-next-line no-restricted-imports
import type { Merge, SuggestedPaths } from '@nartex/stdlib'
import { useFormState as useRhfFormState } from 'react-hook-form'
import type {
  FieldValues,
  UseFormStateProps,
  UseFormStateReturn,
} from 'react-hook-form'

import { useScopedName } from './EmbeddedForm'

export function useFormState<TValues extends FieldValues>(
  props: Merge<
    UseFormStateProps<TValues>,
    {
      name?:
        | SuggestedPaths<TValues>
        | SuggestedPaths<TValues>[]
        | readonly SuggestedPaths<TValues>[]
    }
  > = {},
) {
  const name = useScopedName(props.name as any) as typeof props.name

  return (useRhfFormState as any)({
    ...props,
    name,
  }) as UseFormStateReturn<TValues>
}
