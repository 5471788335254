import { makeForm } from '@aubade/core/ui-kit'

import { useConnectedUser } from '@aubade/domain/adapters'

import type { PublicationFilterType } from '../../Resources/Publications/filters/types'

const { Input, useFormContext } = makeForm<
  PublicationFilterType,
  'agencies' | 'societies' | 'isNational'
>()

type Props = {
  onChange?: () => void
  context?: 'collaborators'
}

export function NationalFilter(props: Props) {
  const { onChange, context } = props
  const { setValue } = useFormContext()
  const setFalsy = (value: boolean | null | undefined) => {
    setValue(`agencies`, undefined)
    setValue(`societies`, undefined)
    if (value === false) {
      setValue(`isNational`, undefined)
    }
  }

  return (
    <>
      {!context && (
        <Input.Checkbox
          name={`isNational`}
          label={'filters.national'}
          onChange={(value) => {
            setFalsy(value)
            onChange?.()
          }}
        />
      )}
      <MySocietyFilter {...props} />
      <MyAgencyFilter {...props} />
    </>
  )
}

export function MySocietyFilter(props: Props) {
  const { onChange } = props
  const { society } = useConnectedUser()
  const { setValue } = useFormContext()

  if (!society) return <></>
  return (
    <Input.Checkbox
      name={`societies`}
      label={'filters.mySociety'}
      onChange={(value) => {
        if (value) {
          setValue(`agencies`, undefined)
          setValue(`isNational`, undefined)
        }
        setValue(`societies`, value ? [society['@id']] : undefined)
        onChange?.()
      }}
    />
  )
}

export function MyAgencyFilter(props: Props) {
  const { onChange } = props
  const { agency } = useConnectedUser()
  const { setValue } = useFormContext()

  if (!agency) return <></>
  return (
    <Input.Checkbox
      name={`agencies`}
      label={'filters.myAgency'}
      onChange={(value) => {
        if (value) {
          setValue(`societies`, undefined)
          setValue(`isNational`, undefined)
        }
        setValue(`agencies`, value ? [agency['@id']] : undefined)
        onChange?.()
      }}
    />
  )
}
