import type { ReactElement, ReactNode } from 'react'

const reactElement = <></>

export function isReactNode(item: unknown): item is ReactNode {
  if (typeof item !== 'object') return true
  return isReactElement(item)
}

export function isReactElement(item: unknown): item is ReactElement {
  if (!item) return false
  if (typeof item !== 'object') return false
  return (item as any).$$typeof === (reactElement as any).$$typeof
}
