import { createCombinedStateHook } from '../utils/createCombinedStateHook'

export const {
  useCombinedState: useIsInDirtyContext,
  useCombinedStateRoot: useIsDirtyRoot,
  useSetChildState: useIsDirty,
} = createCombinedStateHook(isTreeDirty)

function isTreeDirty(dirtyElements: Record<string, boolean>) {
  return Object.values(dirtyElements).some((isDirty) => isDirty === true)
}
