import type { IExternalStore, Listener } from './types'

export class ExternalStore<T> implements IExternalStore<T> {
  constructor(initialState: T) {
    this.#state = initialState
  }

  #listeners: Listener[] = []
  subscribe = (onStoreChange: Listener) => {
    this.#listeners.push(onStoreChange)
    return () => {
      this.#listeners = this.#listeners.filter((item) => item !== onStoreChange)
    }
  }

  #state: T
  setState = (state: T | ((prevState: T) => T)) => {
    const prevState = this.#state
    if (typeof state === 'function') {
      this.#state = (state as any)(prevState)
    } else {
      this.#state = state
    }

    if (prevState !== this.#state) {
      this.#notifyChange()
    }
  }

  getSnapShot = () => {
    return this.#state
  }

  #notifyChange = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.resolve().then(() => {
      this.#listeners.forEach((listener) => listener())
    })
  }
}
