import { useOne } from '@aubade/core/adapters'
import { fromS3ToResizedUrl } from '@aubade/core/libs'
import {
  Paragraph,
  unit,
  useDocumentUrl,
  UnixDateFormat,
  Chip,
  Button,
  ButtonWithConfirm,
} from '@aubade/core/ui-kit'

import {
  IconDot,
  IconRecherche,
  IconPen,
  IconDelete,
  IconEyeShow,
  IconEyeHide,
} from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import { Avatar } from '@aubade/domain/components/Avatar'
import { useTranslate } from '@aubade/translation'
import type { Contact } from '@aubade/types'
import { VStack, Box, HStack } from '@chakra-ui/react'
import { useDelete, toHydraId, cleanHydraId } from '@nartex/data-provider/react'
import { useMutation } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { isBefore } from 'date-fns'
import { useMemo } from 'react'
import { first } from 'remeda'
import { useUrls } from 'src/App'
import { useAubadeQueryBuilder } from 'src/aubadeQueryBuilder/useAubadeQueryBuilder'

import type { ExtendedTypesenseList } from '../../../../../../packages/types/src/index'

import { usePublicationRights } from '../../../adapters/rights'
import { useIsBlocked } from '../useIsBlocked'

const vimeoParams =
  '&byline=0&portrait=0&speed=0&pip=0&quality=undefined&texttrack=undefined&controls=false&title=0'

export function useGetColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'cover',
        cell(props) {
          const { row } = props
          const { cover, documents } = row.original
          const lastDocument = first(documents ?? [])
          const pictureUrl = lastDocument?.pictureLink ?? cover

          if (lastDocument?.playerEmbedUrl) {
            return (
              <VStack
                width="60px"
                height="60px"
                borderRadius={'30px'}
                overflow="hidden"
                alignItems={'center'}
                justifyContent={'center'}
                position="relative"
                onClick={(e) => e.preventDefault()}
              >
                <iframe
                  src={`${lastDocument.playerEmbedUrl}${vimeoParams}`}
                  allow="fullscreen; picture-in-picture"
                  title="sample"
                  style={{
                    width: '120px',
                    aspectRatio: '3 / 2',
                  }}
                />
              </VStack>
            )
          }

          return (
            <Box width="fit-content">
              <ListItemPicture cover={pictureUrl} />
            </Box>
          )
        },
      },
      {
        header: '',
        accessorKey: 'title',
        cell(props) {
          const { row } = props
          const { title, publishedAt, blocked_by } = row.original
          return (
            <PublicationExcerpt
              title={title}
              publishedAt={publishedAt}
              blocked_by={blocked_by}
            />
          )
        },
      },
      {
        header: '',
        accessorKey: 'targetAgency',
        cell(props) {
          const { row } = props
          const { targetAgencyNames, targetSocietyNames, targets } =
            row.original
          return (
            <VStack
              gap={unit('5')}
              alignItems="flex-start"
              justifyContent={'center'}
              height="full"
            >
              {targets?.includes('national') ? (
                <Paragraph text={'publications.fields.nationalTarget'} />
              ) : (
                <>
                  <Paragraph text={targetSocietyNames?.join(', ')} ellipsis />
                  <Paragraph text={targetAgencyNames?.join(', ')} ellipsis />
                </>
              )}
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'targetSociety',
        cell(props) {
          const { row } = props

          return <Actions publication={row.original} />
        },
      },
    ] satisfies ColumnDef<ExtendedTypesenseList>[]
  }, [])
}

function ListItemPicture(props: { cover?: string }) {
  const { cover } = props
  const parsedUrl = fromS3ToResizedUrl(cover)
  const pictureUrl = useDocumentUrl(parsedUrl, {
    size: { width: 100, height: 100 },
    ratio: 'crop',
  })

  return (
    <Avatar
      name={' '}
      picture={cover?.includes('vimeo') ? cover : pictureUrl}
      variant={'big'}
    />
  )
}

type PublicationExcerptProps = {
  title: ExtendedTypesenseList['title']
  publishedAt: ExtendedTypesenseList['publishedAt']
  blocked_by?: string[]
}

function PublicationExcerpt(props: PublicationExcerptProps) {
  const { title, publishedAt, blocked_by } = props
  const isPublished = isBefore(new Date(publishedAt! * 1000), new Date())

  return (
    <VStack
      alignItems="flex-start"
      gap={unit('5')}
      overflow="hidden"
      height="full"
      justifyContent="center"
    >
      <Paragraph text={title} fontWeight="bold" size="sm" ellipsis />
      <HStack gap={unit('7')}>
        <PublishedChip isPublished={isPublished} blocked_by={blocked_by} />
        <Paragraph size="sm">
          <UnixDateFormat value={publishedAt} />
        </Paragraph>
      </HStack>
    </VStack>
  )
}

function PublishedChip(props: { isPublished: Boolean; blocked_by?: string[] }) {
  const { isPublished, blocked_by } = props
  const isBlocked = useIsBlocked(blocked_by)

  const status = getPublicationStatus(isPublished, isBlocked)

  return (
    <Chip
      size="xsmall"
      label={status.label}
      iconLeftProps={{ as: IconDot, color: status.mainColor }}
      textProps={{ color: status.mainColor }}
    />
  )
}

function getPublicationStatus(isPublished: Boolean, isBlocked: boolean) {
  if (isBlocked) {
    return {
      mainColor: 'red.500',
      label: 'publication.status.blocked',
    }
  }
  const mainColor = isPublished ? 'darkGrey.500' : 'blue.500'

  const label = isPublished
    ? 'publication.status.published'
    : 'publication.status.scheduled'
  return {
    mainColor,
    label,
  }
}

type PropsActions = {
  publication: ExtendedTypesenseList
}

function Actions(props: PropsActions) {
  const { publication } = props

  const { isDraft, id, blocked_by, 'author.id': authorId } = publication
  const isBlocked = useIsBlocked(blocked_by)

  const urls = useUrls()
  const [mutateDelete] = useDelete()

  const { id: me } = useConnectedUser()

  const [currentUser] = useOne<Contact>({
    iri: toHydraId('users', me),
  })

  const [fullAuthor] = useOne<Contact>({
    iri: toHydraId('users', authorId!),
  })

  const canBlock = usePublicationRights().useCanBlock(fullAuthor)

  const canDelete = usePublicationRights().useCanDelete(fullAuthor)

  const aubadeQueryBuilder = useAubadeQueryBuilder()
  const { mutate: mutateHide } = useMutation(
    aubadeQueryBuilder.toggleHidePublication(
      isBlocked,
      cleanHydraId(currentUser?.society?.['@id'] ?? ''),
      id,
    ),
  )

  function toggleHide() {
    mutateHide()
  }

  async function onDelete(publicationId: string) {
    await mutateDelete(...aubadeQueryBuilder.deletePublication(publicationId))
  }

  const translate = useTranslate()

  if (isDraft) {
    return (
      <HStack
        gap="10px"
        width="full"
        height="full"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="circleList"
          leftIcon={IconPen}
          to={urls.aubade().publicationsEdit(id)}
        />
        <ButtonWithConfirm
          dialogProps={{
            title: 'publications.confirmTitle',
            children: translate('publications.confirmText'),
          }}
          confirmButtonProps={{
            isDisabled: false,
            label: 'publications.confirmButton',
            variant: 'primary',
          }}
          buttonProps={{
            isDisabled: false,
            leftIcon: IconDelete,
            variant: 'circle',
          }}
          onConfirm={() => onDelete(id)}
        />
      </HStack>
    )
  }
  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      justifyContent="flex-end"
      alignItems="center"
    >
      {canBlock && (
        <Button
          variant="circleList"
          iconColor={isBlocked ? 'error.dark' : undefined}
          leftIcon={isBlocked ? IconEyeShow : IconEyeHide}
          onClick={() => {
            toggleHide()
          }}
        />
      )}
      {canDelete && (
        <ButtonWithConfirm
          dialogProps={{
            title: 'publications.confirmTitle',
            children: translate('publications.confirmText'),
          }}
          confirmButtonProps={{
            isDisabled: false,
            label: 'publications.confirmButton',
            variant: 'primary',
          }}
          buttonProps={{
            isDisabled: false,
            leftIcon: IconDelete,
            variant: 'circle',
          }}
          onConfirm={() => onDelete(id)}
        />
      )}

      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().publicationsEdit(id)}
      />
    </HStack>
  )
}

export function useGetDraftColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'title',
        cell(cellProps) {
          const { row } = cellProps
          const { title } = row.original
          return (
            <HStack width={'full'} height="full" overflow={'hidden'}>
              <Paragraph text={title} fontWeight="bold" size="sm" ellipsis />
            </HStack>
          )
        },
      },

      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps

          return (
            <HStack width={'full'} height="full" justifyContent={'flex-end'}>
              <Actions publication={row.original} />
            </HStack>
          )
        },
      },
    ] satisfies ColumnDef<ExtendedTypesenseList>[]
  }, [])
}
