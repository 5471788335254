import { useQueryParamsState, drawerQueryParams } from '@aubade/core/libs'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  Form,
  FormDrawer,
  Paragraph,
  useForm,
  Button,
  makeInputs,
  unit,
} from '@aubade/core/ui-kit'

import { DrawerContent } from '@aubade/domain/components'
import type { ToolsForm } from '@aubade/types'
import { ToolCategoriesEnum } from '@aubade/types/api'
import { Box, VStack } from '@chakra-ui/react'
import { vestResolver } from '@hookform/resolvers/vest'

import { useFormContext, useWatch } from 'react-hook-form'
import { useUrls } from 'src/App'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { TemplateSection } from './components/TemplateSection'
import { useFormValidation } from './formUtils/formValidators'

export function ToolsEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page, id } = modalState
  const matchCreate = page === 'create'
  const matchUpdate = page === 'edit' && id
  const match = Boolean(matchCreate || matchUpdate)

  return (
    <FormDrawer isOpenProps={Boolean(match)}>
      <DrawerContent>
        {matchCreate && <FormCreate />}
        {matchUpdate && <FormEdit id={id!} />}
      </DrawerContent>
    </FormDrawer>
  )
}

function FormCreate() {
  const urls = useUrls()
  const suite = useFormValidation()
  const newId = useMemoOne(() => {
    return uuid()
  }, [])
  const { saveButtonProps, ...methods } = useForm<ToolsForm>({
    mode: 'create',
    resource: 'tools',
    redirect() {
      return urls.aubade().tools()
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues: {
        newId,
        backgroundColor: '#009EE0',
        template: 'text',
        isPublished: true,
      },
      resolver: vestResolver(suite),
    },
  })

  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="lg"
        color="black.base"
        text="tools.title.create"
      />
      <Form {...methods} bucket={{ type: 'Tool' }}>
        <FormContent
          newToolId={newId}
          {...saveButtonProps}
          label="buttons.tools.create"
        />
      </Form>
    </>
  )
}

function FormEdit(props: { id: string }) {
  const { id } = props
  const urls = useUrls()
  const suite = useFormValidation()
  const { saveButtonProps, ...methods } = useForm<ToolsForm>({
    mode: 'update',
    resource: 'tools',
    id: id,
    redirect() {
      return urls.aubade().tools()
    },
    blockNavigation: false,
    hookFormOptions: {
      resolver: vestResolver(suite),
    },
  })

  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="md"
        color="black.base"
        text="tools.title.create"
      />
      <Form {...methods} bucket={{ type: 'Tool' }}>
        <FormContent
          {...saveButtonProps}
          newToolId={id}
          label="buttons.tools.update"
        />
      </Form>
    </>
  )
}

type FormContentProps = FormSubmitProps & {
  newToolId?: string
}

const Input = makeInputs<ToolsForm>()

function FormContent(props: FormContentProps) {
  const { newToolId, ...saveButtonProps } = props
  const values = useWatch()
  const { getValues, setValue } = useFormContext()
  const suite = useFormValidation()

  const isDisabled = !suite(values as ToolsForm).isValid()
  function removeValues() {
    const template = getValues('template')
    if (template === 'text') setValue('document', undefined)
    if (template === 'logo') {
      setValue('lineOne', undefined)
      setValue('lineTwo', undefined)
    }
  }
  return (
    <VStack width="full" gap={unit('40')} paddingBottom={unit('40')}>
      <TemplateSection newToolId={newToolId} />
      <Box width="full">
        <Input.Color
          label="tools.fields.background"
          name="backgroundColor"
          placeholder="tools.fields.backgroundPlaceholder"
        />
      </Box>
      <Input.Select
        name="toolCategory"
        label="tools.fields.category"
        optionsSelect={ToolCategoriesEnum}
      />
      <Input.Text
        name="url"
        type="url"
        label="tools.fields.link"
        placeholder="https://www.monsite.fr"
      />
      <VStack width="full" gap={unit('20')} alignItems={'flex-start'}>
        <Paragraph text={'tools.subtext.link'} />
        <Button
          variant="primary"
          {...saveButtonProps}
          isDisabled={isDisabled}
          onClick={async (evt) => {
            removeValues()
            await saveButtonProps?.onClick?.(evt)
          }}
        />
      </VStack>
    </VStack>
  )
}
