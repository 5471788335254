import {
  type DataProvider as IDataProvider,
  type BaseRecord,
  type CreateParams,
  type CreateManyParams,
  type GetOneParams,
  type GetListParams,
  type DeleteOneParams,
  type UpdateParams,
  type CustomParams,
  type UpdateManyParams,
  type DeleteManyParams,
  toHydraId,
  type ResourceName,
  cleanHydraId,
} from '@nartex/data-provider'
import {
  isAxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios'

import { axiosRequestBuilder } from './requestBuilder'
import {
  ApiPlatformProviderError,
  type HydraCollection,
  type HydraError,
} from './types'

/** @deprecated Use `@nartex/data-provider` instead */
export * from './types'

/** @deprecated Use `@nartex/data-provider` instead */
export {
  type HydraId,
  isHydraId,
  cleanHydraId,
  toHydraId,
} from '@nartex/data-provider'
export { buildFilters } from './buildFilters'
export * from './requestBuilder'

// Declaration merging the QueryMetaData definition to add a new `searchParams` option
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NxDataProvider {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
    export interface ResponseRawData<T extends BaseRecord> {
      apiPlatform?: HydraCollection
    }
  }
}

type DataProviderConfig = {
  httpClient: Pick<AxiosInstance, 'request'>
  apiUrl: string
  options?: {
    /**
     * if enabled, allows multiple getOnes to be regrouped in a single request
     *
     * it can be enabled globaly, or resource by resource (for progressive enhancement)
     */
    serverIsSupportingGetMany?: boolean | ((resource: ResourceName) => any)
  }
}

export function DataProvider(config: DataProviderConfig): IDataProvider {
  const { httpClient, apiUrl } = config
  const { serverIsSupportingGetMany = false } = config.options ?? {}
  const getServerIsSupportingGetMany = functify(serverIsSupportingGetMany)

  function request<T = unknown>(requestConfig: AxiosRequestConfig) {
    return httpClient.request<T>({
      baseURL: apiUrl,
      ...requestConfig,
    })
  }

  const provider: IDataProvider = {
    async create<T extends BaseRecord, TReturn extends BaseRecord = T>(
      options: CreateParams<T>,
    ) {
      const response = await catchApiError(
        'create',
        options.resource,
        request<TReturn>(axiosRequestBuilder.create(options)),
      )

      const responseData = response.data
      if (responseData && typeof responseData === 'object') {
        const { newId, id, '@id': atId } = responseData
        const invalidIri =
          typeof atId !== 'string' || atId.startsWith?.('/.well-known/genid')

        if (invalidIri) {
          if (typeof id !== 'string' && typeof newId !== 'string') {
            throw new Error(
              'Invalid response data. `@id` is invalid. ' +
                JSON.stringify(atId),
            )
          }

          return {
            data: {
              ...responseData,
              id: id ?? newId,
              '@id': toHydraId(options.resource, (id ?? newId) as string),
            } as TReturn,
            raw: {},
          }
        }
      }

      return { data: responseData, raw: {} }
    },

    async createMany<T extends BaseRecord, TReturn extends BaseRecord = T>(
      options: CreateManyParams<T>,
    ) {
      const { variables } = options
      const responses = await Promise.all(
        variables.map((values) =>
          provider.create<T, TReturn>({ ...options, variables: values as any }),
        ),
      )

      return { data: responses.map((x) => x.data), raw: {} }
    },

    async getList<T extends BaseRecord>(options: GetListParams<T>) {
      const { data } = await catchApiError(
        'getList',
        options.resource,
        request<HydraCollection>(axiosRequestBuilder.getList(options)),
      )

      return { ...processListResponse<T>(data), raw: { apiPlatform: data } }
    },

    async getOne<T extends BaseRecord>(options: GetOneParams<T>) {
      const { data } = await request(axiosRequestBuilder.getOne(options))
      return { data: data as unknown as T, raw: {} }
    },

    async getMany(options) {
      const { ids } = options

      // @ts-expect-error that's hard to type, but it works
      const isEnabled = getServerIsSupportingGetMany(options.resource)
      if (!isEnabled) {
        const responses = await Promise.all(
          ids.map((id) => provider.getOne({ ...options, id })),
        )
        const data = responses.map((x) => x.data as any).filter(Boolean)
        return { data, total: data.length }
      }

      const result = await provider.getList({
        ...options,
        filters: [
          {
            operator: 'eq',
            field: 'ids',
            value: ids,
          },
        ],
      })

      const indexedResult: Record<string, any> = {}
      result.data.forEach((item) => {
        indexedResult[cleanHydraId(item['@id'])] = item
      })

      return {
        data: ids.map((id) => indexedResult[cleanHydraId(id)]),
        total: ids.length,
        raw: result.raw,
      }
    },

    async update<T extends BaseRecord, TReturn extends BaseRecord = T>(
      options: UpdateParams<T>,
    ) {
      const { variables } = options
      const response = await request(
        axiosRequestBuilder.update({
          ...options,
          variables: { ...(variables as any), q: undefined },
        }),
      )
      return { data: response.data as unknown as TReturn, raw: {} }
    },

    async updateMany<T extends BaseRecord, TReturn extends BaseRecord = T>(
      options: UpdateManyParams<T>,
    ) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) =>
          this.update<T, TReturn>({
            ...options,
            id,
            variables: options.variables,
          }),
        ),
      )
      return { data: responses.map((res) => res.data), raw: {} }
    },

    async deleteOne<T extends BaseRecord>(options: DeleteOneParams<T>) {
      const { data } = await catchApiError(
        'deleteOne',
        options.resource,
        request<T>(axiosRequestBuilder.delete(options)),
      )
      return {
        data,
        raw: {},
      }
    },

    async deleteMany<T extends BaseRecord>(options: DeleteManyParams<T>) {
      const { ids, variables } = options

      const responses = await Promise.all(
        ids.map((id) =>
          provider.deleteOne<T>({
            ...options,
            variables: variables as any,
            id,
          }),
        ),
      )

      return {
        data: responses
          .map((deleteResult) => deleteResult?.data)
          .filter(Boolean),
        raw: {},
      }
    },

    async custom<TReturn, TPayload = never, TQuery = never>(
      options: CustomParams<TPayload, TQuery>,
    ) {
      const { data } = await catchApiError(
        'custom',
        `${options.method.toLocaleUpperCase()} ${options.url}`,
        request<TReturn>(axiosRequestBuilder.custom(options)),
      )
      return { data }
    },

    getApiUrl() {
      return apiUrl
    },
  }

  return provider
}

export function processListResponse<T extends BaseRecord>(
  response: HydraCollection,
) {
  return {
    data: response['hydra:member'] as unknown as T[],
    total: response['hydra:totalItems'],
  }
}

type AnyFunction = (...args: any[]) => any
function functify<Args extends any[], R extends Exclude<unknown, AnyFunction>>(
  arg: R | ((...arg: Args) => R),
): (...args: Args) => R {
  if (typeof arg === 'function') {
    return arg as (...args: Args) => R
  }
  return () => arg
}

function catchApiError<T>(
  methodName: string,
  resourceName: string,
  promise: Promise<T>,
): Promise<T> {
  return promise.catch((error) => {
    if (!isAxiosError(error)) throw error
    const isHydraError = error.response?.data['@type'] === 'hydra:Error'
    if (!isHydraError) throw error

    const hydraError: HydraError = error.response!.data
    throw Object.assign(
      new ApiPlatformProviderError(
        `DataProvider.${methodName}(${resourceName}) failed with code ${error.response!.status}: ${hydraError['hydra:title']} ${hydraError['hydra:description']}`,
        { cause: error },
      ),
    )
  })
}
