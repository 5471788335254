import { HStack } from '@chakra-ui/react'

import {
  JobActiveFilters,
  AuthorActiveFilters,
  SocietiesActiveFilters,
  AgenciesActiveFilter,
  IsNationalFilters,
} from '../../../components/ActiveFilters/index'

type Props = {
  onChange: () => void
  variant?: 'negative'
  scope?: 'notifications' | 'publications'
}
export function ActiveFilters(props: Props) {
  const { scope } = props
  return (
    <HStack width="full" wrap="wrap" gap={2}>
      <AgenciesActiveFilter {...props} />
      <SocietiesActiveFilters {...props} />
      <AuthorActiveFilters {...props} scope={scope} />
      <JobActiveFilters {...props} />
      <IsNationalFilters {...props} />
    </HStack>
  )
}
