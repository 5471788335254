import { cleanHydraId } from '@nartex/data-provider'
import { useQueryMetaData } from '@nartex/data-provider/react'
import type {
  BaseKey,
  BaseRecord,
  GetOneResponse,
  HttpError,
  LiveModeProps,
  MetaDataQuery,
  SuccessErrorNotification,
  CrudFilters,
  CrudSorting,
  Pagination,
  GetListResponse,
} from '@pankod/refine-core'
import * as refine from '@pankod/refine-core'
import type { UseQueryOptions } from '@tanstack/react-query'

// fix broken refine CommonJS exports : https://stackoverflow.com/a/71398340
function fixCjsImport<Module>(m: Module): Module {
  if ('default' in (m as any)) {
    return (m as any).default as unknown as Module
  }
  return m
}

const {
  useList: useRefineList,
  useOne: useRefineOne,
  useMany: useRefineMany,
} = fixCjsImport(refine)

type UseListConfig = {
  pagination?: Pagination
  hasPagination?: boolean
  sort?: CrudSorting
  filters?: CrudFilters
}
type UseListProps<TData, TError> = {
  resource: string
  config?: UseListConfig
  queryOptions?: UseQueryOptions<GetListResponse<TData>, TError>
  metaData?: MetaDataQuery
  dataProviderName?: string
} & SuccessErrorNotification &
  LiveModeProps

/** @deprecated Use exports `@nartex/data-provider/readt` instead */
export function useList<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(params: UseListProps<TData, TError>) {
  const queryMetaData = useQueryMetaData()

  return useRefineList<TData, TError>({
    ...params,
    metaData: {
      ...queryMetaData,
      ...params.metaData,
    },
  })
}

type UseOneProps<TData, TError> = {
  resource: string
  id: BaseKey
  queryOptions?: UseQueryOptions<GetOneResponse<TData>, TError>
  metaData?: MetaDataQuery
  dataProviderName?: string
} & SuccessErrorNotification &
  LiveModeProps

/** @deprecated Use exports `@nartex/data-provider/readt` instead */
export function useOne<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(params: UseOneProps<TData, TError>) {
  const queryMetaData = useQueryMetaData()

  return useRefineOne<TData, TError>({
    ...params,
    id: normalizeId(params.id),
    metaData: {
      ...queryMetaData,
      ...params.metaData,
    },
  })
}

/** @deprecated Use exports `@nartex/data-provider/readt` instead */
export function useMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(params: any) {
  const queryMetaData = useQueryMetaData()

  // Nice to have: useQueries instead. But will require to build the request ourselves
  return useRefineMany<TData, TError>({
    ...params,
    ids: params.ids.map(normalizeId),
    metaData: {
      ...queryMetaData,
      ...params.metaData,
    },
  })
}

function normalizeId(id?: BaseRecord['id']) {
  if (typeof id === 'string') return cleanHydraId(id)
  return id as Required<BaseRecord>['id'] // only for type inference
}
