/* eslint-disable */
// @ts-nocheck
/*
 * Beware, this code is AUTO-GENERATED
 * Any manual changes on this file would be eaten by the VØ|D
 */

type AutoCompleteString<T> = (T extends string ? `${T}` : never) | (string & {})

type Integer = number

type Year = Integer
type Month = Integer
type Day = Integer
type Hour = Integer
type Minute = Integer
type Second = Integer
type HoursOffset = Integer
type SecondsOffset = Integer
export type DateTimeString =
  `${Year}-${Month}-${Day}T${Hour}:${Minute}:${Second}+${HoursOffset}:${SecondsOffset}`

export type UUID = string
export type Email = string

// For declaration merging
declare global {
  namespace NxDataProvider {
    export interface ResourceNames {
      default: Api.ResourcePluralName
    }
  }
}

export namespace Api {
  export type ResourcePath =
    | '/agencies'
    | '/black_lists'
    | '/conversation/informational'
    | '/conversation/informational/list'
    | '/conversations'
    | '/conversations/leave/{id}/users/{uId}'
    | '/conversations/{id}/messages'
    | '/conversations/{id}/rename'
    | '/conversations/{id}/users/add'
    | '/conversations/{id}/users/{uId}'
    | '/craft_companies'
    | '/craft_companies/admin/register'
    | '/craft_companies/admin/search'
    | '/craft_companies/users'
    | '/craft_companies/users/add'
    | '/craft_companies/{id}/agencies'
    | '/craft_companies/{id}/favorites'
    | '/craft_companies/{id}/full'
    | '/documents'
    | '/enums/event_keys'
    | '/enums/tool_categories'
    | '/enums/tool_names'
    | '/enums/user_jobs'
    | '/folders'
    | '/images'
    | '/images/avatar'
    | '/images/send_guide'
    | '/jobs'
    | '/jobs/list'
    | '/like'
    | '/login'
    | '/messages'
    | '/notifications'
    | '/notifications/create'
    | '/notifications/{id}/update'
    | '/posts/published'
    | '/public/craft_companies/register'
    | '/public/societies'
    | '/publications'
    | '/publications/create'
    | '/publications/{id}/update'
    | '/realisations'
    | '/statistics'
    | '/summary/conversations'
    | '/summary/gallery'
    | '/tools'
    | '/tools/create'
    | '/tools/{id}/update'
    | '/user_functions'
    | '/users'
    | '/users/invite'
    | '/users/me/avatar'
    | '/users/me/colleagues'
    | '/users/me/has_seen_onboarding'
    | '/users/me/info'
    | '/users/me/update-password'
    | '/users/reset-credentials'
    | '/users/{id}/crafter_user'
    | '/users/{id}/setAvailability'
    | '/users/{id}/setRegistrationTokens/{platform}'
    | '/{type}/export'
    | '/roles'
    | '/users/{uuid}/role'
    | '/impersonate'
    | '/s3/presigned_s3_put/{objectType}/{objectId}/{filename}'
    | '/s3/presigned_s3_get/{objectType}/{objectId}/{filename}'
    | '/s3/presigned_url_get/batch'
    | '/typesense/spec.json'
    | '/audit/events'
    | '/audit/events/{objectName}/object/{objectId}'

  export type ResourcePluralName =
    | 'agencies'
    | 'conversations'
    | 'blocked_bies'
    | 'conversations'
    | 'craft_companies'
    | 'documents'
    | 'jobs'
    | 'messages'
    | 'notifications'
    | 'publications'
    | 'realisations'
    | 'societies'
    | 'statistics'
    | 'tools'
    | 'users'
    | 'user_functions'

  export type IriReference = `/${ResourcePluralName}/${UUID}`

  export type ResourceName =
    | 'AddUsersOnConversationEventAddUsersOnConversationRequest'
    | 'AddUsersOnConversationEventConversation'
    | 'Agency'
    | 'AvailabilityMessagingEventAvailabilityMessagingRequest'
    | 'AvailabilityMessagingEventUser'
    | 'BlackList'
    | 'BlackListEventBlackList'
    | 'BlackListEventPostBlackListRequest'
    | 'BlockedBy'
    | 'Conversation'
    | 'CraftCompany'
    | 'CraftCompanyEventCraftCompany'
    | 'CraftCompanyEventCraftCompanyAgencies'
    | 'CraftCompanyEventCraftCompanyFavoritesRequest'
    | 'CraftCompanyEventPublicCrafterRegisterRequest'
    | 'CraftCompanyEvent'
    | 'CrafterEventCrafterRegisterRequest'
    | 'CrafterEventCrafterSearchRequest'
    | 'CrafterEventCrafterUser'
    | 'CrafterEventCrafterUserAddRequest'
    | 'CrafterEventUser'
    | 'Document'
    | 'DocumentPart'
    | 'ExportUsersEventExportUser'
    | 'Folder'
    | 'FolderEventFolder'
    | 'FolderEventPostFolderRequest'
    | 'FolderEventPutFolderRequest'
    | 'GetSummaryConversationSummaryConversation'
    | 'GetSummaryGallerySummaryGallery'
    | 'ImageEventDocument'
    | 'ImageEventPostAvatarRequest'
    | 'ImageEventPostImageRequest'
    | 'ImageEventPostImageSendGuideRequest'
    | 'ImageEventPutImageRequest'
    | 'ImageEvent'
    | 'InformationalConversationEventInformationalConversationListOutput'
    | 'InformationalConversationEventInformationalConversationOutput'
    | 'InformationalConversationEventPostInformationalConversationRequest'
    | 'Job'
    | 'JobEventJob'
    | 'Like'
    | 'Message'
    | 'Notification'
    | 'PostConversationConversation'
    | 'PostConversationPostConversationRequest'
    | 'PostMessageEventMessage'
    | 'PostMessageEventPostMessageRequest'
    | 'Publication'
    | 'PublicationEvent'
    | 'PublicationLikeEventLike'
    | 'PublicationLikeEventPublicationLikeRequest'
    | 'PublicationSociety'
    | 'Realisation'
    | 'RealisationEventPostRealisationRequest'
    | 'RealisationEventPutRealisationRequest'
    | 'RealisationEventRealisation'
    | 'RegistrationTokenEventRegistrationTokenRequest'
    | 'RegistrationTokenEventUser'
    | 'RenameConversationEventConversation'
    | 'RenameConversationEventRenameConversationRequest'
    | 'Society'
    | 'SocietyEvent'
    | 'Statistic'
    | 'StatisticEventStatisticRequest'
    | 'StatisticEvent'
    | 'Tool'
    | 'UpsertNotificationEventNotification'
    | 'UpsertNotificationEventUpsertNotificationRequest'
    | 'UpsertPublicationEventPublicationRepresentation'
    | 'UpsertPublicationEventUpsertPublicationRequest'
    | 'UpsertToolEventTool'
    | 'UpsertToolEventUpsertToolRequest'
    | 'User'
    | 'UserEventAvatarUserRequest'
    | 'UserEventCrafterUserInfoRequest'
    | 'UserEventCrafterUserUpdateRequest'
    | 'UserEventEditUserRequest'
    | 'UserEventHasSeenOnboardingRequest'
    | 'UserEventInviteUserRequest'
    | 'UserEventResetCredentialsRequest'
    | 'UserEventUpdatePasswordRequest'
    | 'UserEventUser'
    | 'UserEvent'
    | 'UserFunction'

  export type Resource =
    | AddUsersOnConversationEventAddUsersOnConversationRequest.Read
    | AddUsersOnConversationEventAddUsersOnConversationRequest.Write
    | AddUsersOnConversationEventAddUsersOnConversationRequest.ConversationWrite
    | AddUsersOnConversationEventConversation.Read
    | AddUsersOnConversationEventConversation.Write
    | AddUsersOnConversationEventConversation.ConversationRead
    | Agency.Read
    | Agency.Write
    | AvailabilityMessagingEventAvailabilityMessagingRequest.Read
    | AvailabilityMessagingEventAvailabilityMessagingRequest.Write
    | AvailabilityMessagingEventAvailabilityMessagingRequest.UserWrite
    | AvailabilityMessagingEventUser.Read
    | AvailabilityMessagingEventUser.Write
    | AvailabilityMessagingEventUser.UserRead
    | BlackList.Read
    | BlackList.Write
    | BlackList.BlacklistRead
    | BlackList.SummaryConversationRead
    | BlackListEventBlackList.Read
    | BlackListEventBlackList.Write
    | BlackListEventBlackList.BlacklistRead
    | BlackListEventPostBlackListRequest.Read
    | BlackListEventPostBlackListRequest.Write
    | BlackListEventPostBlackListRequest.BlacklistWrite
    | BlockedBy.Read
    | BlockedBy.Write
    | BlockedBy.BlockedBy
    | BlockedBy.PublicationRead
    | Conversation.Read
    | Conversation.Write
    | CraftCompany.Read
    | CraftCompany.Write
    | CraftCompany.ReadFullRead
    | CraftCompanyEventCraftCompany.Read
    | CraftCompanyEventCraftCompany.Write
    | CraftCompanyEventCraftCompany.CraftCompanyRead
    | CraftCompanyEventCraftCompanyAgencies.Read
    | CraftCompanyEventCraftCompanyAgencies.Write
    | CraftCompanyEventCraftCompanyAgencies.CraftCompanyEventCraftCompanyAgencies
    | CraftCompanyEventCraftCompanyFavoritesRequest.Read
    | CraftCompanyEventCraftCompanyFavoritesRequest.Write
    | CraftCompanyEventCraftCompanyFavoritesRequest.FavoritesWrite
    | CraftCompanyEventPublicCrafterRegisterRequest.Read
    | CraftCompanyEventPublicCrafterRegisterRequest.Write
    | CraftCompanyEventPublicCrafterRegisterRequest.CraftCompanyEventPublicCrafterRegisterRequest
    | CraftCompanyEvent.Read
    | CraftCompanyEvent.Write
    | CraftCompanyEvent.CraftCompanyEvent
    | CrafterEventCrafterRegisterRequest.Read
    | CrafterEventCrafterRegisterRequest.Write
    | CrafterEventCrafterRegisterRequest.CrafterEventCrafterRegisterRequest
    | CrafterEventCrafterSearchRequest.Read
    | CrafterEventCrafterSearchRequest.Write
    | CrafterEventCrafterSearchRequest.CrafterEventCrafterSearchRequest
    | CrafterEventCrafterUser.Read
    | CrafterEventCrafterUser.Write
    | CrafterEventCrafterUser.CrafterEventCrafterUser
    | CrafterEventCrafterUserAddRequest.Read
    | CrafterEventCrafterUserAddRequest.Write
    | CrafterEventCrafterUserAddRequest.CrafterEventCrafterUserAddRequest
    | CrafterEventUser.Read
    | CrafterEventUser.Write
    | CrafterEventUser.CrafterEventUser
    | Document.Read
    | Document.Write
    | Document.FeedRead
    | Document.MessageRead
    | Document.ToolRead
    | DocumentPart.Read
    | DocumentPart.Write
    | DocumentPart.MessageWrite
    | DocumentPart.PublicationWrite
    | DocumentPart.SummaryGalleryRead
    | DocumentPart.ToolWrite
    | DocumentPart.UserWrite
    | ExportUsersEventExportUser.Read
    | ExportUsersEventExportUser.Write
    | ExportUsersEventExportUser.ExportUserRead
    | Folder.Read
    | Folder.Write
    | FolderEventFolder.Read
    | FolderEventFolder.Write
    | FolderEventFolder.FolderRead
    | FolderEventPostFolderRequest.Read
    | FolderEventPostFolderRequest.Write
    | FolderEventPostFolderRequest.FolderWrite
    | FolderEventPutFolderRequest.Read
    | FolderEventPutFolderRequest.Write
    | FolderEventPutFolderRequest.FolderWrite
    | GetSummaryConversationSummaryConversation.Read
    | GetSummaryConversationSummaryConversation.Write
    | GetSummaryConversationSummaryConversation.SummaryConversationRead
    | GetSummaryGallerySummaryGallery.Read
    | GetSummaryGallerySummaryGallery.Write
    | GetSummaryGallerySummaryGallery.SummaryGalleryRead
    | ImageEventDocument.Read
    | ImageEventDocument.Write
    | ImageEventDocument.ImageRead
    | ImageEventPostAvatarRequest.Read
    | ImageEventPostAvatarRequest.Write
    | ImageEventPostAvatarRequest.ImageWrite
    | ImageEventPostImageRequest.Read
    | ImageEventPostImageRequest.Write
    | ImageEventPostImageRequest.ImageWrite
    | ImageEventPostImageSendGuideRequest.Read
    | ImageEventPostImageSendGuideRequest.Write
    | ImageEventPostImageSendGuideRequest.ImageEventPostImageSendGuideRequest
    | ImageEventPutImageRequest.Read
    | ImageEventPutImageRequest.Write
    | ImageEventPutImageRequest.ImageWrite
    | ImageEvent.Read
    | ImageEvent.Write
    | ImageEvent.ImageEvent
    | InformationalConversationEventInformationalConversationListOutput.Read
    | InformationalConversationEventInformationalConversationListOutput.Write
    | InformationalConversationEventInformationalConversationListOutput.InformationalRead
    | InformationalConversationEventInformationalConversationOutput.Read
    | InformationalConversationEventInformationalConversationOutput.Write
    | InformationalConversationEventInformationalConversationOutput.InformationalRead
    | InformationalConversationEventPostInformationalConversationRequest.Read
    | InformationalConversationEventPostInformationalConversationRequest.Write
    | InformationalConversationEventPostInformationalConversationRequest.InformationalConversationEventPostInformationalConversationRequest
    | Job.Read
    | Job.Write
    | Job.CraftCompanyRead
    | Job.CraftCompanyReadCraftCompanyFullRead
    | JobEventJob.Read
    | JobEventJob.Write
    | JobEventJob.JobRead
    | Like.Read
    | Like.Write
    | Like.Like
    | Like.FeedRead
    | Like.PublicationRead
    | Message.Read
    | Message.Write
    | Message.SummaryConversationRead
    | Notification.Read
    | Notification.Write
    | PostConversationConversation.Read
    | PostConversationConversation.Write
    | PostConversationConversation.ConversationRead
    | PostConversationPostConversationRequest.Read
    | PostConversationPostConversationRequest.Write
    | PostConversationPostConversationRequest.ConversationWrite
    | PostMessageEventMessage.Read
    | PostMessageEventMessage.Write
    | PostMessageEventMessage.MessageRead
    | PostMessageEventPostMessageRequest.Read
    | PostMessageEventPostMessageRequest.Write
    | PostMessageEventPostMessageRequest.MessageWrite
    | Publication.Read
    | Publication.Write
    | PublicationEvent.Read
    | PublicationEvent.Write
    | PublicationEvent.FeedRead
    | PublicationLikeEventLike.Read
    | PublicationLikeEventLike.Write
    | PublicationLikeEventLike.PublicationLikeEventLike
    | PublicationLikeEventPublicationLikeRequest.Read
    | PublicationLikeEventPublicationLikeRequest.Write
    | PublicationLikeEventPublicationLikeRequest.LikeWrite
    | PublicationSociety.Read
    | PublicationSociety.Write
    | PublicationSociety.PublicationSociety
    | Realisation.Read
    | Realisation.Write
    | RealisationEventPostRealisationRequest.Read
    | RealisationEventPostRealisationRequest.Write
    | RealisationEventPostRealisationRequest.RealisationWrite
    | RealisationEventPutRealisationRequest.Read
    | RealisationEventPutRealisationRequest.Write
    | RealisationEventPutRealisationRequest.RealisationWrite
    | RealisationEventRealisation.Read
    | RealisationEventRealisation.Write
    | RealisationEventRealisation.RealisationRead
    | RegistrationTokenEventRegistrationTokenRequest.Read
    | RegistrationTokenEventRegistrationTokenRequest.Write
    | RegistrationTokenEventRegistrationTokenRequest.UserWrite
    | RegistrationTokenEventUser.Read
    | RegistrationTokenEventUser.Write
    | RegistrationTokenEventUser.UserRead
    | RenameConversationEventConversation.Read
    | RenameConversationEventConversation.Write
    | RenameConversationEventConversation.ConversationRead
    | RenameConversationEventRenameConversationRequest.Read
    | RenameConversationEventRenameConversationRequest.Write
    | RenameConversationEventRenameConversationRequest.ConversationWrite
    | Society.Read
    | Society.Write
    | SocietyEvent.Read
    | SocietyEvent.Write
    | SocietyEvent.SocietyEvent
    | Statistic.Read
    | Statistic.Write
    | StatisticEventStatisticRequest.Read
    | StatisticEventStatisticRequest.Write
    | StatisticEventStatisticRequest.StatisticWrite
    | StatisticEvent.Read
    | StatisticEvent.Write
    | StatisticEvent.StatisticEvent
    | Tool.Read
    | Tool.Write
    | UpsertNotificationEventNotification.Read
    | UpsertNotificationEventNotification.Write
    | UpsertNotificationEventNotification.NotificationRead
    | UpsertNotificationEventUpsertNotificationRequest.Read
    | UpsertNotificationEventUpsertNotificationRequest.Write
    | UpsertNotificationEventUpsertNotificationRequest.NotificationWrite
    | UpsertPublicationEventPublicationRepresentation.Read
    | UpsertPublicationEventPublicationRepresentation.Write
    | UpsertPublicationEventPublicationRepresentation.UpsertPublicationEventPublicationRepresentation
    | UpsertPublicationEventUpsertPublicationRequest.Read
    | UpsertPublicationEventUpsertPublicationRequest.Write
    | UpsertPublicationEventUpsertPublicationRequest.PublicationWrite
    | UpsertToolEventTool.Read
    | UpsertToolEventTool.Write
    | UpsertToolEventTool.UpsertToolEventTool
    | UpsertToolEventUpsertToolRequest.Read
    | UpsertToolEventUpsertToolRequest.Write
    | UpsertToolEventUpsertToolRequest.ToolWrite
    | User.Read
    | User.Write
    | User.CraftCompanyReadCraftCompanyFullRead
    | User.MessageRead
    | UserEventAvatarUserRequest.Read
    | UserEventAvatarUserRequest.Write
    | UserEventAvatarUserRequest.UserWrite
    | UserEventCrafterUserInfoRequest.Read
    | UserEventCrafterUserInfoRequest.Write
    | UserEventCrafterUserInfoRequest.UserWrite
    | UserEventCrafterUserUpdateRequest.Read
    | UserEventCrafterUserUpdateRequest.Write
    | UserEventCrafterUserUpdateRequest.UserEventCrafterUserUpdateRequest
    | UserEventEditUserRequest.Read
    | UserEventEditUserRequest.Write
    | UserEventEditUserRequest.UserWrite
    | UserEventHasSeenOnboardingRequest.Read
    | UserEventHasSeenOnboardingRequest.Write
    | UserEventHasSeenOnboardingRequest.UserEventHasSeenOnboardingRequest
    | UserEventInviteUserRequest.Read
    | UserEventInviteUserRequest.Write
    | UserEventInviteUserRequest.UserEventInviteUserRequest
    | UserEventResetCredentialsRequest.Read
    | UserEventResetCredentialsRequest.Write
    | UserEventResetCredentialsRequest.UserEventResetCredentialsRequest
    | UserEventUpdatePasswordRequest.Read
    | UserEventUpdatePasswordRequest.Write
    | UserEventUpdatePasswordRequest.UserEventUpdatePasswordRequest
    | UserEventUser.Read
    | UserEventUser.Write
    | UserEventUser.UserEventUser
    | UserEventUser.UserRead
    | UserEvent.Read
    | UserEvent.Write
    | UserEvent.UserEvent
    | UserFunction.Read
    | UserFunction.Write
    | UserFunction.CraftCompanyReadCraftCompanyFullRead
    | UserFunction.MessageRead
    | UserFunction.UserRead

  export const singularToPluralMap = {
    Agency: 'agencies',
    BlackList: 'conversations',
    BlockedBy: 'blocked_bies',
    Conversation: 'conversations',
    CraftCompany: 'craft_companies',
    Document: 'documents',
    Job: 'jobs',
    Message: 'messages',
    Notification: 'notifications',
    Publication: 'publications',
    Realisation: 'realisations',
    Society: 'societies',
    Statistic: 'statistics',
    Tool: 'tools',
    User: 'users',
    UserFunction: 'user_functions',
  } as const
}

export enum ToolCategoriesEnum {
  'OUTILS_GENERIQUES' = '/enums/tool_categories/OUTILS_GENERIQUES',
  'SDB_CARRELAGE' = '/enums/tool_categories/SDB_CARRELAGE',
  'WARM_CLIM_ENERGY' = '/enums/tool_categories/WARM_CLIM_ENERGY',
}

export enum UserJobsEnum {
  'JOB_COMMERCIAL' = '/enums/user_jobs/JOB_COMMERCIAL',
  'JOB_RESPONSABLE' = '/enums/user_jobs/JOB_RESPONSABLE',
  'JOB_MEMBER' = '/enums/user_jobs/JOB_MEMBER',
  'JOB_ATC' = '/enums/user_jobs/JOB_ATC',
  'JOB_SALESOFFICE' = '/enums/user_jobs/JOB_SALESOFFICE',
  'JOB_SALESSHOWROOM' = '/enums/user_jobs/JOB_SALESSHOWROOM',
  'JOB_MAILER' = '/enums/user_jobs/JOB_MAILER',
  'JOB_TRAVELING' = '/enums/user_jobs/JOB_TRAVELING',
  'JOB_AGENCYMANAGER' = '/enums/user_jobs/JOB_AGENCYMANAGER',
  'JOB_ADVISESHOWROOM' = '/enums/user_jobs/JOB_ADVISESHOWROOM',
  'JOB_SAVMANAGER' = '/enums/user_jobs/JOB_SAVMANAGER',
  'JOB_SELF' = '/enums/user_jobs/JOB_SELF',
}

export enum undefined {}

export namespace AddUsersOnConversationEventAddUsersOnConversationRequest {
  export type Read = {
    readonly '@type': 'AddUsersOnConversationEventAddUsersOnConversationRequest'
    readonly '@id': `/${string}/${UUID}`
    users: string[]
  }

  export type Write = Read

  export type ConversationWrite = Read
}

export namespace AddUsersOnConversationEventConversation {
  export type Read = {
    readonly '@type': 'AddUsersOnConversationEventConversation'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type Write = {
    readonly '@type': 'AddUsersOnConversationEventConversation'
    readonly '@id': `/${string}/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type ConversationRead = Read
}

export namespace Agency {
  export type Read = {
    readonly '@type': 'Agency'
    readonly '@id': `/agencies/${UUID}`
    readonly id?: UUID
    society: Api.IriReference
    code?: string
    name: string
    phone?: string
    email?: string
    users: Api.IriReference[]
  }

  export type Write = {
    readonly '@type': 'Agency'
    readonly '@id': `/agencies/${UUID}`
    society: Api.IriReference
    code?: string
    name: string
    phone?: string
    email?: string
    users: Api.IriReference[]
  }
}

export namespace AvailabilityMessagingEventAvailabilityMessagingRequest {
  export type Read = {
    readonly '@type': 'AvailabilityMessagingEventAvailabilityMessagingRequest'
    readonly '@id': `/${string}/${UUID}`
    isAvailable: boolean
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace AvailabilityMessagingEventUser {
  export type Read = {
    readonly '@type': 'AvailabilityMessagingEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    userFunction: UserFunction.UserRead | any
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    readonly avatarUrl?: string
  }

  export type Write = {
    readonly '@type': 'AvailabilityMessagingEventUser'
    readonly '@id': `/${string}/${UUID}`
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    userFunction: UserFunction.UserRead | any
  }

  export type UserRead = Read
}

export namespace BlackList {
  export type Read = {
    readonly '@type': 'BlackList'
    readonly '@id': `/conversations/${UUID}`
    readonly id?: UUID
    agency: Api.IriReference
    craftCompany: Api.IriReference
    reason?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type Write = {
    readonly '@type': 'BlackList'
    readonly '@id': `/conversations/${UUID}`
    agency: Api.IriReference
    craftCompany: Api.IriReference
    reason?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type BlacklistRead = Read

  export type SummaryConversationRead = {
    readonly '@type': 'BlackList'
    readonly '@id': `/conversations/${UUID}`
    readonly id?: UUID
    reason?: string
  }
}

export namespace BlackListEventBlackList {
  export type Read = {
    readonly '@type': 'BlackListEventBlackList'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    agency: Api.IriReference
    craftCompany: Api.IriReference
    reason?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type Write = {
    readonly '@type': 'BlackListEventBlackList'
    readonly '@id': `/${string}/${UUID}`
    agency: Api.IriReference
    craftCompany: Api.IriReference
    reason?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type BlacklistRead = Read
}

export namespace BlackListEventPostBlackListRequest {
  export type Read = {
    readonly '@type': 'BlackListEventPostBlackListRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    craftCompany: string
    reason?: string
  }

  export type Write = Read

  export type BlacklistWrite = Read
}

export namespace BlockedBy {
  export type Read = {
    readonly '@type': 'BlockedBy'
    readonly '@id': `/blocked_bies/${UUID}`
    readonly id?: UUID
    publication: Api.IriReference
    society: Api.IriReference
    newId?: string
  }

  export type Write = {
    readonly '@type': 'BlockedBy'
    readonly '@id': `/blocked_bies/${UUID}`
    publication: Api.IriReference
    society: Api.IriReference
    newId?: string
  }

  export type BlockedBy = Read

  export type PublicationRead = {
    readonly '@type': 'BlockedBy'
    readonly '@id': `/blocked_bies/${UUID}`
    readonly id?: UUID
    society: Api.IriReference
  }
}

export namespace Conversation {
  export type Read = {
    readonly '@type': 'Conversation'
    readonly '@id': `/conversations/${UUID}`
    readonly id?: UUID
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type Write = {
    readonly '@type': 'Conversation'
    readonly '@id': `/conversations/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }
}

export namespace CraftCompany {
  export type Read = {
    readonly '@type': 'CraftCompany'
    readonly '@id': `/craft_companies/${UUID}`
    readonly id?: UUID
    code?: string
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
    jobs: Job.CraftCompanyRead[]
    isProAubade: boolean
    hasEaCard: boolean
    readonly jobsIds: string[]
  }

  export type Write = {
    readonly '@type': 'CraftCompany'
    readonly '@id': `/craft_companies/${UUID}`
    code?: string
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
    jobs: Job.CraftCompanyRead[]
    isProAubade: boolean
    hasEaCard: boolean
  }

  export type ReadFullRead = {
    readonly '@type': 'CraftCompany'
    readonly '@id': `/craft_companies/${UUID}`
    readonly id?: UUID
    code?: string
    name: string
    users: User.CraftCompanyReadCraftCompanyFullRead[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
    jobs: Job.CraftCompanyReadCraftCompanyFullRead[]
    isProAubade: boolean
    hasEaCard: boolean
    readonly jobsIds: string[]
  }
}

export namespace CraftCompanyEventCraftCompany {
  export type Read = {
    readonly '@type': 'CraftCompanyEventCraftCompany'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    code?: string
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
    jobs: Job.CraftCompanyRead[]
    isProAubade: boolean
    hasEaCard: boolean
    readonly jobsIds: string[]
  }

  export type Write = {
    readonly '@type': 'CraftCompanyEventCraftCompany'
    readonly '@id': `/${string}/${UUID}`
    code?: string
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
    jobs: Job.CraftCompanyRead[]
    isProAubade: boolean
    hasEaCard: boolean
  }

  export type CraftCompanyRead = Read
}

export namespace CraftCompanyEventCraftCompanyAgencies {
  export type Read = {
    readonly '@type': 'CraftCompanyEventCraftCompanyAgencies'
    readonly '@id': `/${string}/${UUID}`
    agencies: string[]
  }

  export type Write = Read

  export type CraftCompanyEventCraftCompanyAgencies = Read
}

export namespace CraftCompanyEventCraftCompanyFavoritesRequest {
  export type Read = {
    readonly '@type': 'CraftCompanyEventCraftCompanyFavoritesRequest'
    readonly '@id': `/${string}/${UUID}`
    agencies: string[]
  }

  export type Write = Read

  export type FavoritesWrite = Read
}

export namespace CraftCompanyEventPublicCrafterRegisterRequest {
  export type Read = {
    readonly '@type': 'CraftCompanyEventPublicCrafterRegisterRequest'
    readonly '@id': `/${string}/${UUID}`
    societyId: UUID
    crafterCompanyName: string
    crafterCompanyCode?: string
    crafterCompanySiret: string
    firstname: string
    lastname: string
    email: string
  }

  export type Write = Read

  export type CraftCompanyEventPublicCrafterRegisterRequest = Read
}

export namespace CraftCompanyEvent {
  export type Read = {
    readonly '@type': 'CraftCompanyEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type CraftCompanyEvent = Read
}

export namespace CrafterEventCrafterRegisterRequest {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterRegisterRequest'
    readonly '@id': `/${string}/${UUID}`
    crafterCode: string
    firstname: string
    lastname: string
    username: string
    email: string
  }

  export type Write = Read

  export type CrafterEventCrafterRegisterRequest = Read
}

export namespace CrafterEventCrafterSearchRequest {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterSearchRequest'
    readonly '@id': `/${string}/${UUID}`
    code: string
  }

  export type Write = Read

  export type CrafterEventCrafterSearchRequest = Read
}

export namespace CrafterEventCrafterUser {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterUser'
    readonly '@id': `/${string}/${UUID}`
    crafterCode: string
    crafterName: string
    username: string
    isAdmin: boolean
    firstname?: string
    lastname?: string
    email?: string
    hasAuthentification: boolean
    accesses: string[]
    roles: string[]
    agency: Api.IriReference
    readonly admin: boolean
    readonly aCAccess: boolean
  }

  export type Write = {
    readonly '@type': 'CrafterEventCrafterUser'
    readonly '@id': `/${string}/${UUID}`
    crafterCode: string
    crafterName: string
    username: string
    isAdmin: boolean
    firstname?: string
    lastname?: string
    email?: string
    hasAuthentification: boolean
    accesses: string[]
    roles: string[]
    agency: Api.IriReference
  }

  export type CrafterEventCrafterUser = Read
}

export namespace CrafterEventCrafterUserAddRequest {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterUserAddRequest'
    readonly '@id': `/${string}/${UUID}`
    username?: string
    password?: string
    lastName: string
    firstName: string
    email: string
    accesses: string[]
    roles: string[]
  }

  export type Write = Read

  export type CrafterEventCrafterUserAddRequest = Read
}

export namespace CrafterEventUser {
  export type Read = {
    readonly '@type': 'CrafterEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    currentAgency?: Api.IriReference
    likes: Like.Read[]
    userFunction?: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    ebRole?: string
    acAccessEnabled: boolean
    acRole: string
    readonly agencyId?: string
    readonly agencyName?: string
    readonly societyId?: string
    readonly societyName?: string
    readonly craftCompanyId?: string
    readonly craftCompanyName?: string
    readonly avatarUrl?: string
    readonly agenciesBlackListed: string[]
    readonly craftCompanyJobsIds: string[]
    readonly craftCompanyJobsLabels: string[]
    readonly userFunctionName?: string
    readonly userFunctionId?: string
  }

  export type Write = {
    readonly '@type': 'CrafterEventUser'
    readonly '@id': `/${string}/${UUID}`
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    currentAgency?: Api.IriReference
    likes: Like.Write[]
    userFunction?: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    ebAccessEnabled: boolean
    ebRole?: string
    acAccessEnabled: boolean
    acRole: string
  }

  export type CrafterEventUser = {
    readonly '@type': 'CrafterEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    currentAgency?: Api.IriReference
    likes: Like.Like[]
    userFunction?: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    ebRole?: string
    acAccessEnabled: boolean
    acRole: string
    readonly agencyId?: string
    readonly agencyName?: string
    readonly societyId?: string
    readonly societyName?: string
    readonly craftCompanyId?: string
    readonly craftCompanyName?: string
    readonly avatarUrl?: string
    readonly agenciesBlackListed: string[]
    readonly craftCompanyJobsIds: string[]
    readonly craftCompanyJobsLabels: string[]
    readonly userFunctionName?: string
    readonly userFunctionId?: string
  }
}

export namespace Document {
  export type Read = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    readonly id?: UUID
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    category?: string
    publication?: Api.IriReference
    pictureLink?: string
    playerEmbedUrl?: string
    folder?: Api.IriReference
    owner?: Api.IriReference
    title?: string
    description?: string
    realisationBefore?: Api.IriReference
    realisationAfter?: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    category?: string
    publication?: Api.IriReference
    pictureLink?: string
    playerEmbedUrl?: string
    folder?: Api.IriReference
    owner?: Api.IriReference
    title?: string
    description?: string
  }

  export type FeedRead = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    mimeType: string
    readonly url: string
  }

  export type MessageRead = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    readonly id?: UUID
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    readonly url: string
  }

  export type ToolRead = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    readonly id?: UUID
    mimeType: string
    readonly url: string
  }
}

export namespace DocumentPart {
  export type Read = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    id?: UUID
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
    objectId?: string
    objectType?: string
    pictureLink?: string
    playerEmbedUrl?: string
  }

  export type Write = Read

  export type MessageWrite = Read

  export type PublicationWrite = Read

  export type SummaryGalleryRead = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    url: string
    mimeType: string
  }

  export type ToolWrite = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    id?: UUID
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
    objectId?: string
    objectType?: string
  }

  export type UserWrite = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    id?: UUID
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
  }
}

export namespace ExportUsersEventExportUser {
  export type Read = {
    readonly '@type': 'ExportUsersEventExportUser'
    readonly '@id': `/${string}/${UUID}`
    id: string
    firstname?: string
    lastname?: string
    email?: string
    roles?: string[]
    society?: string
    agency?: string
    craftCompany?: string
  }

  export type Write = Read

  export type ExportUserRead = Read
}

export namespace Folder {
  export type Read = {
    readonly '@type': 'Folder'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }

  export type Write = {
    readonly '@type': 'Folder'
    readonly '@id': `/${string}/${UUID}`
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }
}

export namespace FolderEventFolder {
  export type Read = {
    readonly '@type': 'FolderEventFolder'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }

  export type Write = {
    readonly '@type': 'FolderEventFolder'
    readonly '@id': `/${string}/${UUID}`
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }

  export type FolderRead = Read
}

export namespace FolderEventPostFolderRequest {
  export type Read = {
    readonly '@type': 'FolderEventPostFolderRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    parent?: string
    title: string
  }

  export type Write = Read

  export type FolderWrite = Read
}

export namespace FolderEventPutFolderRequest {
  export type Read = {
    readonly '@type': 'FolderEventPutFolderRequest'
    readonly '@id': `/${string}/${UUID}`
    title: string
  }

  export type Write = Read

  export type FolderWrite = Read
}

export namespace GetSummaryConversationSummaryConversation {
  export type Read = {
    readonly '@type': 'GetSummaryConversationSummaryConversation'
    readonly '@id': `/${string}/${UUID}`
    id: UUID
    name?: string
    isInternal: boolean
    /** CodegenError: Cannot generate property type : {} */
    users: any
    nbUnreadMessage: Integer
    blackList: BlackList.SummaryConversationRead | any
    lastMessage: Message.SummaryConversationRead | any
    sortValue?: Integer
    isInformational: boolean
  }

  export type Write = Read

  export type SummaryConversationRead = Read
}

export namespace GetSummaryGallerySummaryGallery {
  export type Read = {
    readonly '@type': 'GetSummaryGallerySummaryGallery'
    readonly '@id': `/${string}/${UUID}`
    id: UUID
    title?: string
    description?: string
    parent?: string
    nbElements?: Integer
    url?: string
    before: DocumentPart.SummaryGalleryRead | any
    after: DocumentPart.SummaryGalleryRead | any
    isUpdatable: boolean
    isDeletable: boolean
    createdAt: string
    updatedAt?: string
    deletedAt?: string
    owner?: string
  }

  export type Write = Read

  export type SummaryGalleryRead = Read
}

export namespace ImageEventDocument {
  export type Read = {
    readonly '@type': 'ImageEventDocument'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    category?: string
    publication?: Api.IriReference
    folder?: Api.IriReference
    owner?: Api.IriReference
    title?: string
    description?: string
  }

  export type Write = {
    readonly '@type': 'ImageEventDocument'
    readonly '@id': `/${string}/${UUID}`
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    category?: string
    publication?: Api.IriReference
    folder?: Api.IriReference
    owner?: Api.IriReference
    title?: string
    description?: string
  }

  export type ImageRead = Read
}

export namespace ImageEventPostAvatarRequest {
  export type Read = {
    readonly '@type': 'ImageEventPostAvatarRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    id?: UUID
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
  }

  export type Write = Read

  export type ImageWrite = Read
}

export namespace ImageEventPostImageRequest {
  export type Read = {
    readonly '@type': 'ImageEventPostImageRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    id?: UUID
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
    title?: string
    description?: string
    folder?: string
  }

  export type Write = Read

  export type ImageWrite = Read
}

export namespace ImageEventPostImageSendGuideRequest {
  export type Read = {
    readonly '@type': 'ImageEventPostImageSendGuideRequest'
    readonly '@id': `/${string}/${UUID}`
    images: string[]
    realisations: string[]
  }

  export type Write = Read

  export type ImageEventPostImageSendGuideRequest = Read
}

export namespace ImageEventPutImageRequest {
  export type Read = {
    readonly '@type': 'ImageEventPutImageRequest'
    readonly '@id': `/${string}/${UUID}`
    title?: string
    description?: string
  }

  export type Write = Read

  export type ImageWrite = Read
}

export namespace ImageEvent {
  export type Read = {
    readonly '@type': 'ImageEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type ImageEvent = Read
}

export namespace InformationalConversationEventInformationalConversationListOutput {
  export type Read = {
    readonly '@type': 'InformationalConversationEventInformationalConversationListOutput'
    readonly '@id': `/${string}/${UUID}`
    conversations: string[]
  }

  export type Write = Read

  export type InformationalRead = Read
}

export namespace InformationalConversationEventInformationalConversationOutput {
  export type Read = {
    readonly '@type': 'InformationalConversationEventInformationalConversationOutput'
    readonly '@id': `/${string}/${UUID}`
    id: string
    name: string
    content: string
    agencies: string[]
    societies: string[]
    isNational: boolean
    societyNames: string[]
    agencyNames: string[]
  }

  export type Write = Read

  export type InformationalRead = Read
}

export namespace InformationalConversationEventPostInformationalConversationRequest {
  export type Read = {
    readonly '@type': 'InformationalConversationEventPostInformationalConversationRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    name: string
    content: string
    agencies: string[]
    societies: string[]
    isNational: boolean
  }

  export type Write = Read

  export type InformationalConversationEventPostInformationalConversationRequest =
    Read
}

export namespace Job {
  export type Read = {
    readonly '@type': 'Job'
    readonly '@id': `/jobs/${UUID}`
    readonly id?: UUID
    label: string
    code: string
    slug: string
    readonly interesting: boolean
  }

  export type Write = {
    readonly '@type': 'Job'
    readonly '@id': `/jobs/${UUID}`
    label: string
    code: string
    slug: string
  }

  export type CraftCompanyRead = Read

  export type CraftCompanyReadCraftCompanyFullRead = Read
}

export namespace JobEventJob {
  export type Read = {
    readonly '@type': 'JobEventJob'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    label: string
    code: string
    slug: string
    readonly interesting: boolean
  }

  export type Write = {
    readonly '@type': 'JobEventJob'
    readonly '@id': `/${string}/${UUID}`
    label: string
    code: string
    slug: string
  }

  export type JobRead = Read
}

export namespace Like {
  export type Read = {
    readonly '@type': 'Like'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    author: Api.IriReference
    publication: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type Write = {
    readonly '@type': 'Like'
    readonly '@id': `/${string}/${UUID}`
    author: Api.IriReference
    publication: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type Like = Read

  export type FeedRead = {
    readonly '@type': 'Like'
    readonly '@id': `/${string}/${UUID}`
  }

  export type PublicationRead = FeedRead
}

export namespace Message {
  export type Read = {
    readonly '@type': 'Message'
    readonly '@id': `/messages/${UUID}`
    readonly id?: UUID
    content?: string
    document: Document.MessageRead[]
    author: User.MessageRead | any
    createdAt?: DateTimeString
    readonly isRead: boolean
  }

  export type Write = {
    readonly '@type': 'Message'
    readonly '@id': `/messages/${UUID}`
    content?: string
    document: Document.MessageRead[]
    author: User.MessageRead | any
    createdAt?: DateTimeString
  }

  export type SummaryConversationRead = {
    readonly '@type': 'Message'
    readonly '@id': `/messages/${UUID}`
    readonly id?: UUID
    content?: string
    createdAt?: DateTimeString
  }
}

export namespace Notification {
  export type Read = {
    readonly '@type': 'Notification'
    readonly '@id': `/notifications/${UUID}`
    title?: string
    content?: string
    isDraft: boolean
    isNational: boolean
    publishedAt?: DateTimeString
    targetSocieties: Api.IriReference[]
    targetAgencies: Api.IriReference[]
    author: Api.IriReference
    isProAubade: boolean
    hasEaCard: boolean
    readonly jobsIds: string[]
  }

  export type Write = {
    readonly '@type': 'Notification'
    readonly '@id': `/notifications/${UUID}`
    title?: string
    content?: string
    isDraft: boolean
    isNational: boolean
    publishedAt?: DateTimeString
    targetSocieties: Api.IriReference[]
    targetAgencies: Api.IriReference[]
    author: Api.IriReference
    isProAubade: boolean
    hasEaCard: boolean
  }
}

export namespace PostConversationConversation {
  export type Read = {
    readonly '@type': 'PostConversationConversation'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type Write = {
    readonly '@type': 'PostConversationConversation'
    readonly '@id': `/${string}/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type ConversationRead = Read
}

export namespace PostConversationPostConversationRequest {
  export type Read = {
    readonly '@type': 'PostConversationPostConversationRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    users: string[]
  }

  export type Write = Read

  export type ConversationWrite = Read
}

export namespace PostMessageEventMessage {
  export type Read = {
    readonly '@type': 'PostMessageEventMessage'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    content?: string
    document: Document.MessageRead[]
    author: User.MessageRead | any
    createdAt?: DateTimeString
    readonly isRead: boolean
  }

  export type Write = {
    readonly '@type': 'PostMessageEventMessage'
    readonly '@id': `/${string}/${UUID}`
    content?: string
    document: Document.MessageRead[]
    author: User.MessageRead | any
    createdAt?: DateTimeString
  }

  export type MessageRead = Read
}

export namespace PostMessageEventPostMessageRequest {
  export type Read = {
    readonly '@type': 'PostMessageEventPostMessageRequest'
    readonly '@id': `/${string}/${UUID}`
    conversation: string
    content?: string
    document: DocumentPart.MessageWrite | any
  }

  export type Write = Read

  export type MessageWrite = Read
}

export namespace Publication {
  export type Read = {
    readonly '@type': 'Publication'
    readonly '@id': `/publications/${UUID}`
    readonly id?: UUID
    title?: string
    content?: string
    isDraft: boolean
    publishedAt?: DateTimeString
    author: Api.IriReference
    documents: Api.IriReference[]
    url?: string
    urlLabel?: string
    targetSocieties: Api.IriReference[]
    blockedBy: BlockedBy.PublicationRead[]
    likes: Like.PublicationRead[]
  }

  export type Write = {
    readonly '@type': 'Publication'
    readonly '@id': `/publications/${UUID}`
    title?: string
    content?: string
    isDraft: boolean
    publishedAt?: DateTimeString
    author: Api.IriReference
    documents: Api.IriReference[]
    url?: string
    urlLabel?: string
    targetSocieties: Api.IriReference[]
    blockedBy: BlockedBy.PublicationRead[]
    likes: Like.PublicationRead[]
  }
}

export namespace PublicationEvent {
  export type Read = {
    readonly '@type': 'PublicationEvent'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    title?: string
    content?: string
    publishedAt?: DateTimeString
    documents: Document.FeedRead[]
    url?: string
    urlLabel?: string
    likes: Like.FeedRead[]
    readonly authorFullName: string
    readonly isAubade: boolean
  }

  export type Write = {
    readonly '@type': 'PublicationEvent'
    readonly '@id': `/${string}/${UUID}`
    title?: string
    content?: string
    publishedAt?: DateTimeString
    documents: Document.FeedRead[]
    url?: string
    urlLabel?: string
    likes: Like.FeedRead[]
  }

  export type FeedRead = Read
}

export namespace PublicationLikeEventLike {
  export type Read = {
    readonly '@type': 'PublicationLikeEventLike'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    author: Api.IriReference
    publication: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type Write = {
    readonly '@type': 'PublicationLikeEventLike'
    readonly '@id': `/${string}/${UUID}`
    author: Api.IriReference
    publication: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type PublicationLikeEventLike = Read
}

export namespace PublicationLikeEventPublicationLikeRequest {
  export type Read = {
    readonly '@type': 'PublicationLikeEventPublicationLikeRequest'
    readonly '@id': `/${string}/${UUID}`
    publicationId: string
    userId: string
    value: boolean
  }

  export type Write = Read

  export type LikeWrite = Read
}

export namespace PublicationSociety {
  export type Read = {
    readonly '@type': 'PublicationSociety'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    society: Api.IriReference
    publication: Api.IriReference
    newId?: string
  }

  export type Write = {
    readonly '@type': 'PublicationSociety'
    readonly '@id': `/${string}/${UUID}`
    society: Api.IriReference
    publication: Api.IriReference
    newId?: string
  }

  export type PublicationSociety = Read
}

export namespace Realisation {
  export type Read = {
    readonly '@type': 'Realisation'
    readonly '@id': `/realisations/${UUID}`
    readonly id?: UUID
    before: Api.IriReference
    after: Api.IriReference
    folder?: Api.IriReference
    title?: string
    description?: string
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'Realisation'
    readonly '@id': `/realisations/${UUID}`
    before: Api.IriReference
    after: Api.IriReference
    folder?: Api.IriReference
    title?: string
    description?: string
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }
}

export namespace RealisationEventPostRealisationRequest {
  export type Read = {
    readonly '@type': 'RealisationEventPostRealisationRequest'
    readonly '@id': `/${string}/${UUID}`
    newId: UUID
    folder?: string
    title?: string
    description?: string
    before: string
    after: string
  }

  export type Write = Read

  export type RealisationWrite = Read
}

export namespace RealisationEventPutRealisationRequest {
  export type Read = {
    readonly '@type': 'RealisationEventPutRealisationRequest'
    readonly '@id': `/${string}/${UUID}`
    title?: string
    description?: string
  }

  export type Write = Read

  export type RealisationWrite = Read
}

export namespace RealisationEventRealisation {
  export type Read = {
    readonly '@type': 'RealisationEventRealisation'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    before: Api.IriReference
    after: Api.IriReference
    folder?: Api.IriReference
    title?: string
    description?: string
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'RealisationEventRealisation'
    readonly '@id': `/${string}/${UUID}`
    before: Api.IriReference
    after: Api.IriReference
    folder?: Api.IriReference
    title?: string
    description?: string
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }

  export type RealisationRead = Read
}

export namespace RegistrationTokenEventRegistrationTokenRequest {
  export type Read = {
    readonly '@type': 'RegistrationTokenEventRegistrationTokenRequest'
    readonly '@id': `/${string}/${UUID}`
    registrationTokens: string[]
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace RegistrationTokenEventUser {
  export type Read = {
    readonly '@type': 'RegistrationTokenEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    userFunction: UserFunction.UserRead | any
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    readonly avatarUrl?: string
  }

  export type Write = {
    readonly '@type': 'RegistrationTokenEventUser'
    readonly '@id': `/${string}/${UUID}`
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    userFunction: UserFunction.UserRead | any
  }

  export type UserRead = Read
}

export namespace RenameConversationEventConversation {
  export type Read = {
    readonly '@type': 'RenameConversationEventConversation'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type Write = {
    readonly '@type': 'RenameConversationEventConversation'
    readonly '@id': `/${string}/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
    name?: string
    informational: boolean
  }

  export type ConversationRead = Read
}

export namespace RenameConversationEventRenameConversationRequest {
  export type Read = {
    readonly '@type': 'RenameConversationEventRenameConversationRequest'
    readonly '@id': `/${string}/${UUID}`
    name?: string
  }

  export type Write = Read

  export type ConversationWrite = Read
}

export namespace Society {
  export type Read = {
    readonly '@type': 'Society'
    readonly '@id': `/societies/${UUID}`
    readonly id?: UUID
    code?: string
    agencies: Api.IriReference[]
    name: string
  }

  export type Write = {
    readonly '@type': 'Society'
    readonly '@id': `/societies/${UUID}`
    code?: string
    agencies: Api.IriReference[]
    name: string
  }
}

export namespace SocietyEvent {
  export type Read = {
    readonly '@type': 'SocietyEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type SocietyEvent = Read
}

export namespace Statistic {
  export type Read = {
    readonly '@type': 'Statistic'
    readonly '@id': `/statistics/${UUID}`
    readonly id?: UUID
  }

  export type Write = {
    readonly '@type': 'Statistic'
    readonly '@id': `/statistics/${UUID}`
  }
}

export namespace StatisticEventStatisticRequest {
  export type Read = {
    readonly '@type': 'StatisticEventStatisticRequest'
    readonly '@id': `/${string}/${UUID}`
    eventKey: string
    toolName: string
    recordedAt?: string
  }

  export type Write = Read

  export type StatisticWrite = Read
}

export namespace StatisticEvent {
  export type Read = {
    readonly '@type': 'StatisticEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type StatisticEvent = Read
}

export namespace Tool {
  export type Read = {
    readonly '@type': 'Tool'
    readonly '@id': `/tools/${UUID}`
    readonly id?: UUID
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    document: Document.ToolRead | any
    lineOne?: string
    lineTwo?: string
    toolCategory: ToolCategoriesEnum
    isNotEditable: boolean
    isScanBat: boolean
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
  }

  export type Write = {
    readonly '@type': 'Tool'
    readonly '@id': `/tools/${UUID}`
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    document: Document.ToolRead | any
    lineOne?: string
    lineTwo?: string
    toolCategory: ToolCategoriesEnum
    isNotEditable: boolean
    isScanBat: boolean
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
  }
}

export namespace UpsertNotificationEventNotification {
  export type Read = {
    readonly '@type': 'UpsertNotificationEventNotification'
    readonly '@id': `/${string}/${UUID}`
    title?: string
    content?: string
    isDraft: boolean
    isNational: boolean
    publishedAt?: DateTimeString
    targetSocieties: Api.IriReference[]
    targetAgencies: Api.IriReference[]
    author: Api.IriReference
    isProAubade: boolean
    hasEaCard: boolean
    readonly jobsIds: string[]
  }

  export type Write = {
    readonly '@type': 'UpsertNotificationEventNotification'
    readonly '@id': `/${string}/${UUID}`
    title?: string
    content?: string
    isDraft: boolean
    isNational: boolean
    publishedAt?: DateTimeString
    targetSocieties: Api.IriReference[]
    targetAgencies: Api.IriReference[]
    author: Api.IriReference
    isProAubade: boolean
    hasEaCard: boolean
  }

  export type NotificationRead = Read
}

export namespace UpsertNotificationEventUpsertNotificationRequest {
  export type Read = {
    readonly '@type': 'UpsertNotificationEventUpsertNotificationRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    title?: string
    content?: string
    isDraft: boolean
    isNational: boolean
    targetSocieties: string[]
    targetAgencies: string[]
    publishedAt?: string
    author: string
    isInternal: boolean
    jobs: string[]
    isProAubade: boolean
    hasEaCard: boolean
  }

  export type Write = Read

  export type NotificationWrite = Read
}

export namespace UpsertPublicationEventPublicationRepresentation {
  export type Read = {
    readonly '@type': 'UpsertPublicationEventPublicationRepresentation'
    readonly '@id': `/${string}/${UUID}`
    id: string
    title?: string
    content?: string
    author: string
    documents: string[]
    url?: string
    urlLabel?: string
    isDraft: boolean
    isNational?: boolean
    targetSocieties: string[]
    targetAgencies: string[]
    societyId: string
    publishedAt?: string
    toolId?: string
    blockedBy: string[]
    liked: boolean
    likeCount: Integer
    notificationTitle?: string
    notificationContent?: string
    isInternal?: boolean
    withNotification: boolean
    jobs: string[]
    isProAubade: boolean
    hasEaCard: boolean
  }

  export type Write = Read

  export type UpsertPublicationEventPublicationRepresentation = Read
}

export namespace UpsertPublicationEventUpsertPublicationRequest {
  export type Read = {
    readonly '@type': 'UpsertPublicationEventUpsertPublicationRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    title?: string
    content?: string
    author: string
    documents: DocumentPart.PublicationWrite[]
    url?: string
    urlLabel?: string
    isDraft: boolean
    isNational: boolean
    targetSocieties: string[]
    targetAgencies: string[]
    societyId?: string
    publishedAt?: string
    toolId?: string
    notificationTitle?: string
    notificationContent?: string
    withNotification: boolean
    isInternal: boolean
    jobs: string[]
    isProAubade: boolean
    hasEaCard: boolean
  }

  export type Write = Read

  export type PublicationWrite = Read
}

export namespace UpsertToolEventTool {
  export type Read = {
    readonly '@type': 'UpsertToolEventTool'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    document?: Api.IriReference
    lineOne?: string
    lineTwo?: string
    toolCategory: Api.IriReference
    publications: Api.IriReference[]
    isNotEditable: boolean
    isScanBat: boolean
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    readonly sortValue: Integer
    readonly photo?: string
    readonly scanBat?: boolean
  }

  export type Write = {
    readonly '@type': 'UpsertToolEventTool'
    readonly '@id': `/${string}/${UUID}`
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    document?: Api.IriReference
    lineOne?: string
    lineTwo?: string
    toolCategory: Api.IriReference
    publications: Api.IriReference[]
    isNotEditable: boolean
    isScanBat: boolean
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
  }

  export type UpsertToolEventTool = Read
}

export namespace UpsertToolEventUpsertToolRequest {
  export type Read = {
    readonly '@type': 'UpsertToolEventUpsertToolRequest'
    readonly '@id': `/${string}/${UUID}`
    newId?: UUID
    document: DocumentPart.ToolWrite | any
    url?: string
    name?: string
    isPublished?: boolean
    backgroundColor?: string
    lineOne?: string
    lineTwo?: string
    toolCategory?: string
  }

  export type Write = Read

  export type ToolWrite = Read
}

export namespace User {
  export type Read = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: UserJobsEnum
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    userFunction: UserFunction.UserRead | any
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    readonly avatarUrl?: string
  }

  export type Write = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: UserJobsEnum
    isAvailable: boolean
    avatar?: Api.IriReference
    acRoles?: string[]
    acAccesses?: string[]
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    userFunction: UserFunction.CraftCompanyReadCraftCompanyFullRead | any
  }

  export type CraftCompanyReadCraftCompanyFullRead = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: UserJobsEnum
    isAvailable: boolean
    avatar?: Api.IriReference
    acRoles?: string[]
    acAccesses?: string[]
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    userFunction: UserFunction.CraftCompanyReadCraftCompanyFullRead | any
    readonly avatarUrl?: string
  }

  export type MessageRead = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: UserJobsEnum
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    avatar: Document.MessageRead | any
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    userFunction: UserFunction.MessageRead | any
    readonly aubade: boolean
  }
}

export namespace UserEventAvatarUserRequest {
  export type Read = {
    readonly '@type': 'UserEventAvatarUserRequest'
    readonly '@id': `/${string}/${UUID}`
    avatar: string
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace UserEventCrafterUserInfoRequest {
  export type Read = {
    readonly '@type': 'UserEventCrafterUserInfoRequest'
    readonly '@id': `/${string}/${UUID}`
    firstname: string
    lastname: string
    email: string
    jobsIds: string[]
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace UserEventCrafterUserUpdateRequest {
  export type Read = {
    readonly '@type': 'UserEventCrafterUserUpdateRequest'
    readonly '@id': `/${string}/${UUID}`
    lastName: string
    firstName: string
    email: string
    ebAccessEnabled: boolean
    ebRole?: string
    acRole: string
  }

  export type Write = Read

  export type UserEventCrafterUserUpdateRequest = Read
}

export namespace UserEventEditUserRequest {
  export type Read = {
    readonly '@type': 'UserEventEditUserRequest'
    readonly '@id': `/${string}/${UUID}`
    avatar: DocumentPart.UserWrite[]
    job?: string
    isAvailable?: boolean
    userFunctionId?: string
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace UserEventHasSeenOnboardingRequest {
  export type Read = {
    readonly '@type': 'UserEventHasSeenOnboardingRequest'
    readonly '@id': `/${string}/${UUID}`
    hasSeenOnboarding: boolean
  }

  export type Write = Read

  export type UserEventHasSeenOnboardingRequest = Read
}

export namespace UserEventInviteUserRequest {
  export type Read = {
    readonly '@type': 'UserEventInviteUserRequest'
    readonly '@id': `/${string}/${UUID}`
    userId: string
  }

  export type Write = Read

  export type UserEventInviteUserRequest = Read
}

export namespace UserEventResetCredentialsRequest {
  export type Read = {
    readonly '@type': 'UserEventResetCredentialsRequest'
    readonly '@id': `/${string}/${UUID}`
    username: string
  }

  export type Write = Read

  export type UserEventResetCredentialsRequest = Read
}

export namespace UserEventUpdatePasswordRequest {
  export type Read = {
    readonly '@type': 'UserEventUpdatePasswordRequest'
    readonly '@id': `/${string}/${UUID}`
    oldPassword: string
    password: string
  }

  export type Write = Read

  export type UserEventUpdatePasswordRequest = Read
}

export namespace UserEventUser {
  export type Read = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    currentAgency?: Api.IriReference
    likes: Like.Read[]
    userFunction?: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    ebRole?: string
    acAccessEnabled: boolean
    acRole: string
    readonly agencyId?: string
    readonly agencyName?: string
    readonly societyId?: string
    readonly societyName?: string
    readonly craftCompanyId?: string
    readonly craftCompanyName?: string
    readonly avatarUrl?: string
    readonly agenciesBlackListed: string[]
    readonly craftCompanyJobsIds: string[]
    readonly craftCompanyJobsLabels: string[]
    readonly userFunctionName?: string
    readonly userFunctionId?: string
  }

  export type Write = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    currentAgency?: Api.IriReference
    likes: Like.Write[]
    userFunction?: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    ebAccessEnabled: boolean
    ebRole?: string
    acAccessEnabled: boolean
    acRole: string
  }

  export type UserEventUser = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    currentAgency?: Api.IriReference
    likes: Like.Like[]
    userFunction?: Api.IriReference
    newId?: string
    createdAt?: DateTimeString
    updatedAt?: DateTimeString
    deletedAt?: DateTimeString
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    ebRole?: string
    acAccessEnabled: boolean
    acRole: string
    readonly agencyId?: string
    readonly agencyName?: string
    readonly societyId?: string
    readonly societyName?: string
    readonly craftCompanyId?: string
    readonly craftCompanyName?: string
    readonly avatarUrl?: string
    readonly agenciesBlackListed: string[]
    readonly craftCompanyJobsIds: string[]
    readonly craftCompanyJobsLabels: string[]
    readonly userFunctionName?: string
    readonly userFunctionId?: string
  }

  export type UserRead = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    readonly id?: UUID
    username?: string
    firstname?: string
    lastname?: string
    email?: string
    job?: Api.IriReference
    isAvailable: boolean
    agency?: Api.IriReference
    society?: Api.IriReference
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    avatar?: Api.IriReference
    craftCompany?: Api.IriReference
    registrationTokensBackOffice?: string[]
    registrationTokensIos?: string[]
    registrationTokensAndroid?: string[]
    acRoles?: string[]
    acAccesses?: string[]
    crafterApiAccessToken?: string
    crafterApiRefreshToken?: string
    hasSeenOnboarding: boolean
    lastLoginAt?: DateTimeString
    lastInvitationAt?: DateTimeString
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    userFunction: UserFunction.UserRead | any
    readonly aubade: boolean
    readonly societyAgency?: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    readonly avatarUrl?: string
  }
}

export namespace UserEvent {
  export type Read = {
    readonly '@type': 'UserEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type UserEvent = Read
}

export namespace UserFunction {
  export type Read = {
    readonly '@type': 'UserFunction'
    readonly '@id': `/user_functions/${UUID}`
    readonly id?: UUID
    name: string
  }

  export type Write = {
    readonly '@type': 'UserFunction'
    readonly '@id': `/user_functions/${UUID}`
    newId?: string
    name: string
  }

  export type CraftCompanyReadCraftCompanyFullRead = Read

  export type MessageRead = Read

  export type UserRead = Read
}
