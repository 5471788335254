import type { SuggestedPaths as Name } from '@nartex/stdlib'
import { useLayoutEffect, useRef } from 'react'
import type { DeepPartial, FieldValues, WatchObserver } from 'react-hook-form'
import { isDeepEqual } from 'remeda'

import type { GetPathsValues, GetPathValue } from '../types'

import { makeUseFormContext } from './useFormContext'
import { useWatcher } from './useWatcher'

export type UseReactiveValue<T extends FieldValues> = {
  <TargetName extends Name<T>, Names extends Name<T>[] = []>(
    name: TargetName,
    names: readonly [...Names],
    computeFunction: (
      values: GetPathsValues<T, Names>,
      value: GetPathValue<T, TargetName>,
    ) => DeepPartial<GetPathValue<T, TargetName>>,
    options?: UseReactiveValueOptions,
  ): void
}

type UseReactiveValueOptions = {
  enabled?: boolean
}
export function makeUseReactiveValue<
  T extends FieldValues,
>(): UseReactiveValue<T> {
  const useFormContext = makeUseFormContext<T>()

  return function useReactiveValue(name, names, computeFunction, options) {
    const { enabled = true } = options ?? {}

    const { register, unregister, getValues, setValue } = useFormContext()

    register(name as any)
    useLayoutEffect(() => {
      return () => unregister(name as any)
    }, [name, unregister])

    const latestValues = useRef<any[]>([])
    const watcherFunction: WatchObserver<T> = (_values) => {
      if (!enabled) return

      const previousValues = latestValues.current
      const currentValue = getValues(name)
      const currentValues = getValues(names)
      latestValues.current = currentValues

      if (!isDeepEqual(previousValues, currentValues)) {
        const newValue = computeFunction(currentValues, currentValue)

        if (newValue === undefined) {
          if (currentValue !== undefined) {
            setValue(name, newValue as any)
          }
        } else if (!isDeepEqual(currentValue, newValue)) {
          setValue(name, newValue as any)
        }
      }
    }

    useWatcher(watcherFunction, { eager: true })
  }
}
