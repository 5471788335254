import { Storage, HTTPClient, NativeBridge } from '@aubade/core/adapters'
import { getBuildHash } from '@aubade/core/libs'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import { Env } from './adapters/Env'
import { App } from './App'
import '@aubade/translation'
import { setup } from './setup'

const env = Env()
setup(env)

const isProd = process.env.NODE_ENV === 'production'

const storage = Storage() // Scope storage to current user some way ?
const nativeBridge = NativeBridge()

const httpClient = HTTPClient({
  baseURL: env.API_URL,
  getAuthToken() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('token')
    const storedToken = sessionStorage.getItem('jwt')
    if (token && token !== null) return token
    return storedToken
  },
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: true,
      keepPreviousData: false,
      ...(isProd
        ? {
            cacheTime: 1 * 60 * 60 * 1000,
            staleTime: 10 * 1000,
            retry: 3,
          }
        : {
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 1000,
            retry: 1, // prevent refetches during dev
          }),
    },
  },
})

persistQueryClient({
  queryClient,
  persister: createSyncStoragePersister({ storage }),
  buster: getBuildHash(),
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  // @ts-ignore
  <StrictMode>
    <App
      mode={nativeBridge.getAppMode()}
      env={env}
      adapters={{
        httpClient,
        storage,
        nativeBridge,
        queryClient,
      }}
    />
  </StrictMode>,
)
