import { useList } from '@aubade/core/adapters'
import type { BlackList } from '@aubade/types/api'
import { toHydraId, useDelete } from '@nartex/data-provider/react'
import { useMutation } from '@tanstack/react-query'
import { useAubadeQueryBuilder } from 'src/aubadeQueryBuilder/useAubadeQueryBuilder'
import { v4 as uuid } from 'uuid'

export function useActions(
  craftCompany: string,
  isBlocked: boolean,
  blockedBy?: string,
) {
  const [fullBlackList] = useList<BlackList.Read>(
    {
      // @ts-ignore
      resource: 'black_lists',
      dataProviderName: 'default',
      filters: [
        {
          field: 'craftCompany',
          operator: 'eq',
          value: toHydraId('craft_companies', craftCompany),
        },
        {
          field: 'agency',
          operator: 'eq',
          value: toHydraId('agencies', blockedBy ?? ''),
        },
      ],
    },
    {
      enabled: Boolean(blockedBy),
    },
  )

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  const { mutateAsync: blockCrafter } = useMutation(
    aubadeQueryBuilder.blockCrafter({
      newId: uuid(),
      craftCompany,
    }),
  )

  const [unblockCrafter] = useDelete()

  async function toggleBlockCrafter() {
    if (
      isBlocked === true &&
      fullBlackList &&
      fullBlackList[0] &&
      fullBlackList[0].id
    ) {
      await unblockCrafter(
        ...aubadeQueryBuilder.unblockCrafter(fullBlackList[0].id),
      )
    } else {
      await blockCrafter()
    }
  }

  return {
    toggleBlockCrafter,
  }
}
