import type { Document } from '@aubade/types/api'
import type { BaseRecord, DataProvider } from '@nartex/data-provider/react'
import type { AxiosInstance } from 'axios'

import { arrayify } from '../libs'

type ProxyOptions = {
  apiClient: AxiosInstance
  baseURL: string
}
export function fileUploadProxy<T extends BaseRecord>(
  dataProvider: DataProvider,
  options: ProxyOptions,
): DataProvider {
  const { apiClient, baseURL } = options

  async function uploadNestedDocuments(
    variables: Record<string, any>,
    metaData: MetaData,
  ) {
    const uploads = findNestedUploads(variables)
    return await Promise.all(
      Object.entries(uploads).map(async ([key, documents]) => {
        const oldDocs = arrayify(variables[key]).filter(
          (doc: DocCreate) => doc.newId === undefined,
        )
        const dt = {} as Record<string, DocCreate[]>
        dt[key] = oldDocs ?? []

        for (const document of documents) {
          if (document.mimeType?.includes('video')) {
            const response = await uploadVideo(apiClient, baseURL, document)
            dt[key].push({
              ...document,
              fileName: response.filename,
              playerEmbedUrl: response.playerEmbedUrl,
              pictureLink: response.pictureLink,
            })
          } else {
            await uploadFile(apiClient, baseURL, document, metaData as any)
            dt[key].push(document)
          }
        }
        return dt
      }),
    )
  }

  return {
    ...dataProvider,
    async create(params) {
      const newDocs = await uploadNestedDocuments(
        params.variables as Record<string, any>,
        params.metaData as any,
      )

      const vars = transformDocumentsData<T>(
        params.variables as any,
        params.resource,
        newDocs,
      )

      return dataProvider.create({
        ...params,
        variables: vars as any,
      })
    },
    async update(params) {
      const newDocs = await uploadNestedDocuments(
        params.variables,
        params.metaData as any,
      )
      const vars = transformDocumentsData<T>(
        params.variables as any,
        params.resource,
        newDocs,
      )

      return dataProvider.update({
        ...params,
        variables: vars as any,
      })
    },
    async custom(params) {
      const newDocs = await uploadNestedDocuments(
        params.payload as BaseRecord,
        params.metaData as any,
      )

      const vars = transformDocumentsData<T>(
        params.payload as any,
        params.url,
        newDocs,
      )

      return dataProvider.custom!({
        ...params,
        payload: vars,
      })
    },
  }
}

type ApiResource = {
  '@type': string
}

type ResourceDocumentLinks = Record<string, 'one' | 'many'>
export type ResourceDocumentLinksSchema<T extends string> = Partial<
  Record<T, ResourceDocumentLinks>
>

export function findNestedUploads(variables: Record<string, any>) {
  const uploads: Record<string, DocCreate[]> = {}
  function referenceDocuments(value: any, key: string) {
    const values = Array.isArray(value) ? value : [value]
    const documents = values.filter(isDocument)
    const newDocuments = documents.filter(isNewDocument)
    if (newDocuments && newDocuments.length > 0) {
      Object.assign(uploads, { [key]: newDocuments })
    }
  }

  function recurse(record: unknown) {
    if (!isRecord(record)) return

    Object.entries(record).forEach(([key, value]) => {
      referenceDocuments(value, key)

      if (Array.isArray(value)) {
        value.forEach(recurse)
      }

      if (isRecord(value) && !isDocument(value)) {
        recurse(value)
      }
    })
  }

  // sometimes @type is not provided for the root values
  // it fixes the algorythm
  recurse({ '@type': 'defaultType', ...variables })
  return uploads
}

type Optional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>

export type DocCreate = Optional<Omit<Document.Write, '@id'>, 'mimeType'> & {
  file: File
  newId: string
  '@id': Document.Read['@id']
  category?: string
}

export function isRecord(x: any): x is ApiResource {
  return x && x['@type']
}

export function isDocument(x: any): x is Document.Write | DocCreate {
  return x && x['@type'] === 'Document'
}

export function isNewDocument(x: any): x is DocCreate {
  return isDocument(x) && (x as any).file instanceof File
}

type MetaData = { headers?: Record<string, string> }
async function uploadFile(
  apiClient: AxiosInstance,
  baseURL: string,
  document: DocCreate,
  metaData?: MetaData,
) {
  const inlineHeaders = new URLSearchParams(metaData?.headers ?? {}).toString()
  const { data: uploadUrl } = await apiClient.get<string>(
    `/presigned_s3_put/${document.objectType}/${document.objectId}/${document.fileName}?${inlineHeaders}`,
    { headers: metaData?.headers, baseURL },
  )
  await apiClient.put(uploadUrl, document.file)
}

type uploadResponse = {
  filename: string
  playerEmbedUrl: string
  pictureLink: string
}

async function uploadVideo(
  apiClient: AxiosInstance,
  baseURL: string,
  document: DocCreate,
) {
  const formData = new FormData()
  formData.append('file', document.file, document.file.name)
  formData.append('newId', document.newId)
  formData.append('displayName', document.displayName)
  formData.append('objectId', document.objectId)
  const response = await apiClient.post('/documents/vimeo/upload', formData)
  return response.data as uploadResponse
}

export function transformDocumentsData<T extends BaseRecord>(
  payload: Partial<T>,
  resource: string,
  newDocs?: Record<string, DocCreate[]>[],
): Partial<T> {
  if (!newDocs) return payload
  const variables = payload

  newDocs.forEach((doc) => {
    Object.entries(doc).forEach(([key, value]) => {
      if (resource.includes('tool') || resource.includes('message')) {
        // @ts-expect-error
        variables[key] = value[0]
      } else {
        // @ts-expect-error

        variables[key] = value
      }
    })
  })
  // @ts-expect-error

  if (resource.includes('tool') && variables?.document?.[0]) {
    // @ts-expect-error

    variables.document = variables.document[0]
  }

  return variables as T
}
