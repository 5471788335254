import { once, type SuggestedPaths } from '@nartex/stdlib'
import type { FieldValues } from 'react-hook-form'

import { makeEmbeddedForm } from './EmbeddedForm'
import { makeUseFieldArray } from './useFieldArray'
import { makeUseFormContext } from './useFormContext'
import { makeUseFormSelector } from './useFormSelector'
import { useFormState } from './useFormState'
import { makeUseIntermediaryFormState } from './useIntermediaryFormState'
import { makeUseReactiveValue } from './useReactiveValue'
import { makeUseWatch } from './useWatch'
import { useWatcher } from './useWatcher'

function makeFormImpl<T extends FieldValues, Props extends keyof T = never>() {
  type InnerT = Pick<T, Props> // easy way to improve "name" resolution performances by limiting the keys

  return {
    /** Components */
    EmbeddedForm: makeEmbeddedForm<T>(),

    /** Hooks */
    useWatch: makeUseWatch<InnerT>(),
    useWatcher: useWatcher<InnerT>,
    useFormState: useFormState<InnerT>,
    useFormContext: makeUseFormContext<InnerT>(),
    useFieldArray: makeUseFieldArray<InnerT>(),
    useReactiveValue: makeUseReactiveValue<InnerT>(),
    useFormSelector: makeUseFormSelector<InnerT>(),
    useIntermediaryFormState: makeUseIntermediaryFormState<InnerT>(),

    /** Utilities */
    fieldName: (name: SuggestedPaths<InnerT>) => name,

    /** Types */
    fieldValues: {} as InnerT,
  }
}

export const makeForm = once(makeFormImpl) as typeof makeFormImpl

export * from './EmbeddedForm'
export * from './useController'
export * from './useDebugFormEvent'
export * from './useFieldArray'
export * from './useFormContext'
export * from './useFormController'
export * from './useFormSelector'
export * from './useFormState'
export * from './useWatch'
export * from './useIntermediaryFormState'
export * from './useReactiveValue'
export * from './useWatch'
export * from './useWatcher'
