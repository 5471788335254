import { isDeepEqual, mapValues } from 'remeda'

export type DirtyMap<T> =
  T extends Record<string, any>
    ? {
        [K in keyof T]?: T[K] extends Record<string, any>
          ? DirtyMap<T[K]>
          : true
      }
    : true | undefined

export function getDirtyValues<TValues>(
  values: TValues,
  dirtyMap: DirtyMap<TValues>,
): Partial<TValues> | undefined {
  if (dirtyMap === true) return values
  if (dirtyMap === undefined) return undefined

  const result: Record<string, any> = {}

  for (const [key, dirtyNess] of Object.entries(dirtyMap)) {
    if (dirtyNess === true) {
      result[key] = (values as any)[key]
      continue
    }
    if (Array.isArray(dirtyNess)) {
      result[key] = dirtyNess?.map((dirt, index) =>
        getDirtyValues((values as any)?.[key]?.[index], dirt),
      )
      continue
    }
    if (dirtyNess && typeof dirtyNess === 'object') {
      result[key] = mapValues(dirtyNess, (dirt, subKey) =>
        getDirtyValues((values as any)?.[key]?.[subKey], dirt),
      )
      continue
    }
  }

  return result as Partial<TValues>
}

export function getDiff<
  TDefaultValues extends Record<string, any>,
  TValues extends Record<string, any>,
>(defaultValues: TDefaultValues, values: TValues): Partial<typeof values> {
  const dirtyValues = Object.keys(values).reduce((result, key) => {
    const currentValue = values[key]
    if (defaultValues?.[key] === currentValue) return result

    if (!(key in defaultValues)) {
      ;(result as any)[key] = currentValue
      return result
    }

    if (Array.isArray(currentValue)) {
      const dirtyArray = currentValue.map((item: any) =>
        getDiff(defaultValues[key] as TDefaultValues, item as TValues),
      )
      if (isDeepEqual(dirtyArray, [])) return result
      ;(result as any)[key] = dirtyArray
      return result
    }

    if (typeof currentValue === 'object') {
      const dirtyObject = getDiff(
        defaultValues[key] as TDefaultValues,
        currentValue as TValues,
      )
      if (isDeepEqual(dirtyObject, {})) return result
      ;(result as any)[key] = dirtyObject
      return result
    }

    if (defaultValues[key] !== currentValue) {
      ;(result as any)[key] = currentValue
      return result
    }

    return result
  }, {})

  return dirtyValues
}
