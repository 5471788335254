import {
  Surface,
  ResourceList,
  Button,
  unit,
  Paragraph,
  makeForm,
} from '@aubade/core/ui-kit'
import { IconOutilsCouleurs, IconRecherche } from '@aubade/design/graphics'
import { ProfileCard, isAdmin, isAgency } from '@aubade/domain/components'
import { UserRole } from '@aubade/types/index'
import type { Typesense } from '@aubade/types/typesense'
import { Box, HStack } from '@chakra-ui/react'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'

import { useUrls } from 'src/App'

import type { CrudFilters } from '../../../../../core/src/adapters'
import { useTranslate } from '../../../../../translation/src'
import type { ExtendedUser } from '../../../../../types/src'

const templateColumns = '1fr 120px'

const { useWatch } = makeForm<
  ExtendedUser,
  'acRoles' | 'society' | 'agency' | 'craftCompany'
>()

type Props = {
  profile: 'customer' | 'collaborater'
}

export function FormUserList(props: Props) {
  const { profile } = props
  const columns = useGetColumns(profile)
  const translate = useTranslate()
  const resource = profile === 'collaborater' ? 'associates' : 'clients'
  const [acRoles, society, agency, craftCompany] = useWatch([
    'acRoles',
    'society',
    'agency',
    'craftCompany',
  ])
  const defaultFilters = useGetDefaultFilters(
    profile,
    acRoles?.AC as UserRole,
    society?.id,
    agency?.id,
    craftCompany?.id,
  )

  if (acRoles && acRoles.AC && isAdmin(acRoles.AC as any)) {
    return <></>
  }

  return (
    <Surface
      direction={'column'}
      paddingTop={unit('40')}
      overflowY="auto"
      gap={unit('32')}
      width="full"
    >
      <ResourceList<Typesense.Associates | Typesense.Clients>
        scope={'users'}
        resource={resource}
        dataProviderName="default"
        title={(count) => (
          <Paragraph
            size="md"
            fontWeight="bold"
            text={translate('users.fields.userlist', { count })}
          />
        )}
        filters={defaultFilters}
        pageLimit={5}
        tableProps={{
          templateColumns: templateColumns,
          columns: columns,
          noDataLabel: 'table.noData.collaboraters',
        }}
      />
    </Surface>
  )
}

function useGetColumns(profile: Props['profile']) {
  const translate = useTranslate()
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { firstname, lastname, avatar, roles } = row.original

          const fullName = [firstname, lastname].filter(Boolean).join(' ')

          return (
            <Box width="full">
              <ProfileCard
                title={fullName}
                picture={avatar}
                url=""
                subtitle={roles && translate(roles[0])}
                variant="big"
                icon={profile === 'customer' ? IconOutilsCouleurs : undefined}
              />
            </Box>
          )
        },
      },

      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps
          const { id } = row.original
          return <Actions userId={id} profile={profile} />
        },
      },
    ] satisfies ColumnDef<Typesense.Associates | Typesense.Clients>[]
  }, [profile, translate])
}

type ActionsProps = {
  userId: string
  profile: Props['profile']
}

function Actions(props: ActionsProps) {
  const { userId, profile } = props
  const userProfile = profile === 'collaborater' ? 'collaborateurs' : 'clients'

  const urls = useUrls()
  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().userDetail(userProfile, userId)}
      />
    </HStack>
  )
}

function useGetDefaultFilters(
  profile: 'customer' | 'collaborater',
  role?: UserRole,
  society?: string,
  agency?: string,
  craftCompany?: string,
) {
  if (profile === 'customer' && craftCompany)
    return [
      {
        field: 'craftCompany.id',
        operator: 'eq',
        value: [craftCompany],
      },
    ] as CrudFilters<{ societies: string; agencies: string }>
  if (!role || isAdmin(role)) return undefined
  if (isAgency(role)) {
    return [
      {
        field: 'agencies',
        operator: 'eq',
        value: [agency],
      },
      {
        field: 'roles',
        operator: 'eq',
        value: [UserRole.ROLE_APP_CRAFTER_STORE],
      },
    ] as CrudFilters<{ societies: string; agencies: string }>
  }
  return [
    {
      field: 'societies',
      operator: 'eq',
      value: [society],
    },
    {
      field: 'roles',
      operator: 'eq',
      value: [UserRole.ROLE_APP_CRAFTER_COMPANY],
    },
  ] as CrudFilters<{ societies: string; agencies: string }>
}
