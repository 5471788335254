import { debounceImmediateCallback } from '@nartex/stdlib'
import { useCallback, useSyncExternalStore } from 'react'
import type { FieldValues, WatchObserver } from 'react-hook-form'

import { useStabilizeResult, useEvent } from '../../hooks'

import type { NxFormContext } from './useFormContext'
import { makeUseFormContext } from './useFormContext'

export function makeUseFormSelector<TValues extends FieldValues>() {
  const useFormContext = makeUseFormContext<TValues>()

  return function useFormSelector<U>(
    selector: (form: NxFormContext<TValues>) => U,
    shouldUpdate: (
      ...args: Parameters<WatchObserver<TValues>>
    ) => boolean = () => true,
  ): U {
    shouldUpdate = useEvent(shouldUpdate)

    const form = useFormContext()
    const { watch, rootFormContext } = form

    return useSyncExternalStore(
      useCallback(
        (notify) => {
          const notifyDebounced = debounceImmediateCallback(notify)

          const formStateSubscription =
            rootFormContext.control._subjects.state.subscribe({
              next(_formState) {
                notifyDebounced()
              },
            })

          const watcher = watch((values, info) => {
            if (shouldUpdate(values, info)) {
              notifyDebounced()
            }
          })

          return function unsubscribe() {
            watcher.unsubscribe()
            formStateSubscription.unsubscribe()
          }
        },
        [rootFormContext.control._subjects.state, watch, shouldUpdate],
      ),
      useStabilizeResult<U>(
        useEvent(() => {
          return selector(form)
        }),
      ),
    )
  }
}

export const useFormSelector = makeUseFormSelector<Record<string, unknown>>()
