import { useStableMemo, type Merge } from '@aubade/core/libs'
import { cleanHydraId, type ResourceOf } from '@nartex/data-provider'
import type { BaseRecord, GetOneResponse } from '@nartex/data-provider/react'
import { useCreate, useUpdate, useOne } from '@nartex/data-provider/react'
import { getDiff } from '@nartex/react-libs'
import type {
  CreateResponse,
  HttpError,
  UpdateResponse,
} from '@pankod/refine-core'
import * as Sentry from '@sentry/browser'
import type { UseQueryOptions, MutateOptions } from '@tanstack/react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import type { DefaultValues } from 'react-hook-form'
import { useFormState } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { useToastMessage } from '../useToast'

import { useFormController, type UseFormControllerParams } from './hooks'
import type { ShouldBlockPredicate } from './useBlockNavigation'
import { useBlockNavigation } from './useBlockNavigation'
import { useIsDirty } from './useIsDirty'

import type { FormSubmitProps } from '.'

export type FormMode =
  | {
      mode: 'create'
      id?: string
    }
  | {
      mode: 'update'
      id: string
    }

export type UseFormProps<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TContext extends object = {},
> = FormMode & {
  sendOnlyDirtyValues?: boolean
  resource: ResourceOf<TData>
  queryOptions?: UseQueryOptions<GetOneResponse<TData>>
  mutationOptions?: Merge<
    MutateOptions<
      CreateResponse<TData> | UpdateResponse<TData>,
      TError,
      TData,
      TContext
    >,
    {
      onSuccess?: (
        data: CreateResponse<TData> | UpdateResponse<TData>,
        unblock: () => void,
      ) => void | Promise<void>
    }
  >
  redirect: (data: { id: string }) => string | false
  blockNavigation?: boolean
} & Omit<UseFormControllerParams<TData, TContext>, 'onValid' | 'onError'>
export function useForm<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TContext extends object = {},
>(params: UseFormProps<TData, TError, TContext>) {
  const {
    resource,
    id,
    mode,
    redirect,
    queryOptions,
    mutationOptions,
    blockNavigation = true,
    sendOnlyDirtyValues = false,
    hookFormOptions,
  } = params

  const [, queryResult] = useOne<TData>(
    {
      resource,
      id: id!,
    },
    {
      enabled: mode === 'update',
      suspense: true,
      ...queryOptions,
    },
  )

  const [, mutationUpdate] = useUpdate<TData, TError, TData>()
  const [, mutationCreate] = useCreate<TData, TError, TData>()

  const navigate = useNavigate()
  const toastMessage = useToastMessage()

  const queryClient = useQueryClient()

  const queryData = queryResult?.data?.data
  const defaultValues = useStableMemo(() => {
    return {
      ...hookFormOptions?.defaultValues,
      ...(queryData as any),
    }
  }, [hookFormOptions?.defaultValues, queryData])

  async function onValid(formData: TData) {
    const mutation = mode === 'create' ? mutationCreate : mutationUpdate

    const rawValues = sendOnlyDirtyValues
      ? getDiff(defaultValues, formData)
      : formData
    const values = nullifyEmptyString(rawValues) as any

    const successBaseMessage =
      mode === 'create'
        ? 'notifications.createSuccess'
        : 'notifications.editSuccess'
    const errorBaseMessage =
      mode === 'create'
        ? 'notifications.createError'
        : 'notifications.editError'
    const errorDuplicate = 'notifications.duplicateError'

    await mutation.mutateAsync(
      { id: id!, resource, values },
      {
        async onSuccess(
          response: CreateResponse<TData> | UpdateResponse<TData>,
        ) {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes(resource)
            },
          })
          const destination = redirect({
            id: cleanHydraId(response.data['@id']),
          })

          if (!mutationOptions?.onSuccess) {
            toastMessage('success', successBaseMessage)
          }

          const val = mutationOptions?.onSuccess?.(response, unblock)
          if (val instanceof Promise) {
            await val
          }
          if (destination !== false) {
            unblock()
            navigate(destination)
            return
          }

          reset(
            // @ts-expect-error put ID here just in case
            { '@id': response.data?.['@id'], ...formData },
            { keepDirtyValues: false },
          )
        },
        onError(error: TError) {
          Sentry.captureException(error)
          if (error.response?.status === 422) {
            // TODO Gérer les messages d'erreurs différemment avec l'API (ticket)
            if (!(mutationOptions as any)?.onError) {
              toastMessage('error', errorDuplicate)
            }
          } else {
            if (!(mutationOptions as any)?.onError) {
              toastMessage('error', errorBaseMessage)
            }
          }

          ;(mutationOptions as any)?.onError?.(error)
        },
        onSettled(data, error) {
          ;(mutationOptions as any)?.onSettled?.(data, error)
        },
      },
    )
  }

  // useOne is marked as loading when not enabled
  const isLoading =
    (mode === 'update' && queryResult.isLoading) ||
    mutationUpdate.isLoading ||
    mutationCreate.isLoading

  const baseForm = useFormController<TData, TContext>({
    onValid,
    isLoading,
    hookFormOptions: {
      defaultValues,
      ...hookFormOptions,
    },
  })

  const [formControls, unblock] = useFormWithBlock<TData, TContext>({
    form: baseForm,
    blockNavigation,
  })
  const { reset, control } = formControls
  const { isDirty } = useFormState<TData>({ control })

  useEffect(() => {
    if (queryData && !isDirty) {
      reset(queryData)
    }
  }, [queryData, isDirty, reset])

  const saveButtonProps = useMemo<FormSubmitProps>(() => {
    return {
      ...formControls.saveButtonProps,
      label: undefined, // let the button control its label
      state: isDirty ? 'dirty' : 'pristine',
      to: undefined,
      onClick: formControls.saveButtonProps.onClick,
    }
  }, [formControls.saveButtonProps, isDirty])

  return {
    ...formControls,
    saveButtonProps,
  }
}

type UseFormWithBlockParams<
  TFieldValues extends Record<string, any>,
  TContext extends object = {},
> = {
  form: ReturnType<typeof useFormController<TFieldValues, TContext>>
  blockNavigation?: ShouldBlockPredicate
}
export function useFormWithBlock<
  TFieldValues extends Record<string, any>,
  TContext extends object = {},
>(params: UseFormWithBlockParams<TFieldValues, TContext>) {
  const { form, blockNavigation = true } = params

  const { isDirty } = useFormState({ control: form.control })
  const unblock = useBlockNavigation(blockNavigation && isDirty)

  useIsDirty(isDirty)
  return [form, unblock] as const
}

type WithNullifiedStrings<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends string ? string | null : T[K]
}
function nullifyEmptyString<T extends Record<string, any>>(
  data: T,
): WithNullifiedStrings<T> {
  const res: Record<string, any> = {}

  Object.entries(data).forEach(([key, value]) => {
    if (value === '') {
      return (res[key] = null)
    }
    return (res[key] = value)
  })
  return res as WithNullifiedStrings<T>
}

export type UseFormWithActionProps<
  TData extends Record<string, any>,
  TResult,
> = FormMode & {
  isLoading?: boolean
  resource?: string
  sendOnlyDirtyValues?: boolean
  mutationOptions?: Merge<
    MutateOptions<TResult, TData>,
    {
      onSuccess?: (
        data: TResult,
        unblock: () => void,
        defaultValues?: Partial<TData>,
      ) => void | Promise<void>
    }
  >
  redirect: (data: TResult | undefined) => string | false
  blockNavigation?: ShouldBlockPredicate
} & Omit<UseFormControllerParams<TData>, 'onValid' | 'onError'>

export function useFormWithAction<TData extends Record<string, any>, TResult>(
  params: UseFormWithActionProps<TData, TResult>,
  action: (formData: TData) => Promise<TResult>,
) {
  const {
    mode,
    redirect,
    mutationOptions,
    blockNavigation = true,
    sendOnlyDirtyValues = false,
    hookFormOptions,
    resource,
  } = params

  if (typeof hookFormOptions?.defaultValues === 'function') {
    throw Error(`Async default values are not supported`)
  }
  const defaultValues = hookFormOptions?.defaultValues

  const navigate = useNavigate()
  const toastMessage = useToastMessage()

  const mutation = useMutation({ mutationFn: action })
  const queryClient = useQueryClient()

  async function onValid(formData: TData) {
    const rawValues = sendOnlyDirtyValues
      ? getDiff(defaultValues ?? {}, formData)
      : formData
    const normalizedValues = nullifyEmptyString(rawValues) as any
    const values = normalizedValues

    const successBaseMessage =
      mode === 'create'
        ? 'notifications.createSuccess'
        : 'notifications.editSuccess'
    const errorBaseMessage =
      mode === 'create'
        ? 'notifications.createError'
        : 'notifications.editError'
    const errorDuplicate = 'notifications.duplicateError'

    await mutation.mutateAsync(values, {
      async onSuccess(response) {
        const previousDefaultValues = defaultValues
          ? { ...defaultValues }
          : undefined

        await queryClient.invalidateQueries({
          queryKey: ['typesense', resource],
        })
        await queryClient.invalidateQueries({
          queryKey: ['default', resource],
        })

        const destination = redirect(response)

        if (!mutationOptions?.onSuccess) {
          toastMessage('success', successBaseMessage)
        }

        const val = mutationOptions?.onSuccess?.(
          response,
          unblock,
          previousDefaultValues,
        )
        if (val instanceof Promise) {
          await val
        }
        if (destination !== false && mode === 'create') {
          unblock()
          navigate(destination)
          return
        }

        reset(formData, { keepDirtyValues: false })
      },
      onError(error) {
        Sentry.captureException(error)
        if ((mutationOptions as any)?.onError) {
          return (mutationOptions as any)?.onError?.(error)
        }

        if ((error as HttpError).response?.status === 422) {
          // TODO Gérer les messages d'erreurs différemment avec l'API (ticket)
          toastMessage('error', errorDuplicate)
        } else {
          toastMessage('error', errorBaseMessage)
        }
      },
      onSettled(data, error) {
        ;(mutationOptions as any)?.onSettled?.(data, error)
      },
    })
  }

  const isLoading = params.isLoading || mutation.isLoading

  const baseForm = useFormController<TData>({
    onValid,
    onError: () => toastMessage('error', 'notifications.invalid'),
    isLoading,
    hookFormOptions: {
      ...hookFormOptions,
      defaultValues,
    },
  })

  let [formControls, unblock] = useFormWithBlock<TData>({
    form: baseForm,
    blockNavigation,
  })

  formControls = useFormWithSuggestions(formControls)

  useFormWithLiveDefaultValues({
    form: formControls,
    defaultValues,
  })

  const { reset, control } = formControls
  const { isDirty } = useFormState<TData>({ control })

  const saveButtonProps = useMemo<FormSubmitProps>(() => {
    const to = mode === 'create' ? undefined : redirect(undefined)
    const canSubmit = mode === 'create' || isDirty
    return {
      ...formControls.saveButtonProps,
      isDirty: canSubmit,
      label: undefined, // let the button control its label
      state: canSubmit ? 'dirty' : 'pristine',
      to: to && !isDirty ? to : undefined,
      onClick: canSubmit ? formControls.saveButtonProps.onClick : undefined,
    }
  }, [formControls.saveButtonProps, isDirty, mode, redirect])

  return {
    ...formControls,
    mode,
    saveButtonProps,
  }
}

function useFormWithSuggestions<TFieldValues extends Record<string, any>>(
  form: ReturnType<typeof useFormController<TFieldValues>>,
): ReturnType<typeof useFormController<TFieldValues>> {
  return {
    ...form,
    async onSubmit(event) {
      return form.onSubmit(event)
    },
    saveButtonProps: {
      ...form.saveButtonProps,
      onClick:
        form.saveButtonProps?.onClick &&
        async function onClick(event) {
          return form.saveButtonProps?.onClick?.(event)
        },
    },
    wrappers: [...form.wrappers],
  }
}

type useFormWithLiveDefaultValuesParams<
  TFieldValues extends Record<string, any>,
> = {
  form: Pick<
    ReturnType<typeof useFormController<TFieldValues>>,
    'reset' | 'control'
  >
  defaultValues?: DefaultValues<TFieldValues>
}
export function useFormWithLiveDefaultValues<
  TFieldValues extends Record<string, any>,
>(params: useFormWithLiveDefaultValuesParams<TFieldValues>): void {
  const { form, defaultValues } = params

  const { reset, control } = form
  const { isDirty } = useFormState<TFieldValues>({ control })

  useEffect(() => {
    if (defaultValues && !isDirty) {
      reset(defaultValues)
    }
  }, [defaultValues, isDirty, reset])
}
