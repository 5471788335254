// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation
import * as React from 'react'
import {
  useRef,
  useLayoutEffect,
  useCallback,
  type MutableRefObject,
} from 'react'

export function useEvent<T extends (...args: any[]) => any>(callback: T): T {
  const handlerRef = useConcurrentModeSafeRef(callback)
  handlerRef.current = callback

  const result = useCallback<any>(
    (...args: Parameters<T>): ReturnType<T> => {
      const fn = handlerRef.current
      return fn!(...args)
    },
    [handlerRef],
  ) as T

  if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(result, 'name', {
      get() {
        return `useEvent(${handlerRef.current.name ?? 'anonymous'})`
      },
    })
  }

  return result
}

// @deprecated Use `useEvent` instead
export const useStableFnRef = useEvent

// https://github.com/facebook/react/issues/14099#issuecomment-1002738546
type ConcurrentRef<T> = {
  committedValue: T
  currentValue: T
  ref: MutableRefObject<T>
}
function useConcurrentModeSafeRef<T>(initialValue: T) {
  const instanceRef = useRef<ConcurrentRef<T>>()
  if (!instanceRef.current) {
    instanceRef.current = {
      committedValue: initialValue,
      currentValue: initialValue,
      ref: {
        get current() {
          if (isRendering()) {
            return instance.currentValue
          } else {
            return instance.committedValue
          }
        },
        set current(v) {
          if (!isRendering()) {
            instance.committedValue = v
          }
          instance.currentValue = v
        },
      },
    }
  }
  const instance: ConcurrentRef<T> = instanceRef.current

  instance.currentValue = instance.committedValue

  useLayoutEffect(() => {
    instance.committedValue = instance.currentValue
  })

  return instance.ref
}

/**
 * this is bad : `__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED` should not be used and could break at any time
 * it could be replaced with this code :
 * ```ts
 * try {
 *   useContext(dummyCtx)
 *   return true
 * } catch {
 *   return false
 * }
 * ```
 * */
function isRendering() {
  return Boolean(
    (React as any).__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      .ReactCurrentOwner.current,
  )
}
