import { createContextFunction } from '../../../core/src/libs'
import type { GetUserReturn } from '../components'

export const [ConnectedUserProvider, useConnectedUser] =
  createContextFunction<GetUserReturn>('connectedUser', function () {
    return {
      id: undefined,
      society: undefined,
      agency: undefined,
    } as unknown as GetUserReturn
  })
