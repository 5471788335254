import { ImmutableMap, arrayify, isEmpty } from '@nartex/stdlib'
import { unflatten } from 'flat'
import { useMemo } from 'react'
import { type FieldValues, type FormState } from 'react-hook-form'
import { isTruthy } from 'remeda'

import { useDeepCompareMemo, useEvent } from '../../hooks'
import { useProfunctorState } from '../../ProfunctorState'
import { useProxyOverride } from '../libs/useProxyOverride'

import { unScopeName } from './EmbeddedForm'
import { makeUseFormContext } from './useFormContext'
import { makeUseFormSelector } from './useFormSelector'
import { useFormState } from './useFormState'

type IntermediaryFormState<T extends FieldValues> = FormState<T> & {
  values: Partial<T>
  hasValues: boolean
}

export function makeUseIntermediaryFormState<TValues extends FieldValues>() {
  const useFormContext = makeUseFormContext<TValues>()

  return function useIntermediaryFormState() {
    const { rootFormContext, scope } = useFormContext()

    if (!rootFormContext) throw Error('Missing parent Form Context')

    const [subFieldNames, { addField, removeField }] = useFieldsRegistry(scope)

    const parentControl = rootFormContext.control
    const wrappedControl = useProxyOverride(
      parentControl,
      useMemo(() => {
        return {
          register(...args: Parameters<typeof parentControl.register>) {
            const [name, options] = args
            addField(name)

            return parentControl.register(name, options)
          },

          unregister(...args: Parameters<typeof parentControl.unregister>) {
            const [names, options] = args
            arrayify(names).forEach(removeField)
            return parentControl.unregister(names, options)
          },
        } as typeof parentControl
      }, [parentControl, addField, removeField]),
    )

    const wrappedParentForm = useProxyOverride(
      rootFormContext,
      useMemo(() => {
        return { control: wrappedControl }
      }, [wrappedControl]),
    )

    const subFormState = usePartialFormState(subFieldNames)

    return [subFormState, wrappedParentForm] as const
  }

  function usePartialFormState(subFieldNames: string[]) {
    const formState = useFormState()
    const errors = usePartialErrors<TValues>(subFieldNames)
    const dirtyFields = usePartialDirty<TValues>(subFieldNames)
    const partialValues = usePartialValues<TValues>(subFieldNames)

    return useProxyOverride(
      formState,
      useMemo(() => {
        return {
          errors,

          /*
           * not exactly the same behavior of the parent formState:
           * the formState here will always be valid if the form is not submitted yet
           * while the parent formState could be invalid, even if not submitted yet
           */
          get isValid() {
            return !Object.values(errors ?? {}).length
          },
          get isDirty() {
            return Object.values(dirtyFields ?? {}).length > 0
          },
          get dirtyFields() {
            return dirtyFields
          },
          values: partialValues,
          hasValues: !isEmpty(partialValues),
        } satisfies Partial<IntermediaryFormState<TValues>>
      }, [dirtyFields, errors, partialValues]),
    )
  }
}

export const useIntermediaryFormState =
  makeUseIntermediaryFormState<Record<string, unknown>>()

function usePartialErrors<TValues extends FieldValues>(fieldNames: string[]) {
  const useFormSelector = makeUseFormSelector<TValues>()

  return useFormSelector<FormState<TValues>['errors']>((form) => {
    const errors = Object.fromEntries(
      fieldNames
        .map((name) => {
          const { error } = form.getFieldState(name as any)
          if (!error) return undefined
          return [name, form.getFieldState(name as any).error]
        })
        .filter(isTruthy),
    )

    return unflatten(errors) as FormState<TValues>['errors']
  })
}

function usePartialDirty<TValues extends FieldValues>(fieldNames: string[]) {
  const useFormSelector = makeUseFormSelector<TValues>()

  return useFormSelector<FormState<TValues>['dirtyFields']>((form) => {
    const dirtyFields = Object.fromEntries(
      fieldNames
        .map((name) => {
          const { isDirty } = form.getFieldState(name as any)
          if (!isDirty) return undefined
          return [name, isDirty]
        })
        .filter(isTruthy),
    )

    return unflatten(
      dirtyFields,
    ) as IntermediaryFormState<TValues>['dirtyFields']
  })
}

function usePartialValues<TValues extends FieldValues>(fieldNames: string[]) {
  const useFormSelector = makeUseFormSelector<TValues>()

  return useFormSelector<IntermediaryFormState<TValues>['values']>((form) => {
    const values = Object.fromEntries(
      fieldNames
        .map((name) => {
          const value = form.getValues(name as any)
          if (isEmpty(value)) return undefined
          return [name, value]
        })
        .filter(isTruthy),
    )

    return unflatten(values) as IntermediaryFormState<TValues>['values']
  })
}

const emptyMap = ImmutableMap<string, number>()
function useFieldsRegistry(scope: string | undefined) {
  const [subFieldsCount, setFieldCount] = useProfunctorState(
    () => emptyMap,
  ).useState()

  const addField = useEvent(function addField(name: string) {
    const localName = unScopeName(scope, name)
    if (!localName) return

    setFieldCount((map) => {
      return map.withSetted(localName, subFieldsCount.get(localName) ?? 0 + 1)
    })
  })

  const removeField = useEvent(function removeField(name: string) {
    const localName = unScopeName(scope, name)
    if (!localName) return

    setFieldCount((map) => {
      const result = map.withSetted(
        localName,
        subFieldsCount.get(localName) ?? 0 - 1,
      )
      if ((result?.get(localName) ?? 0) <= 0) {
        return result?.withDeleted(localName)
      }
      return result
    })
  })

  const names_ = Array.from(subFieldsCount.keys())
  const subFieldNames = useDeepCompareMemo(() => names_, [names_])

  return [subFieldNames, { addField, removeField }] as const
}
