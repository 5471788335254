import packageJson from '../package.json'

export const config = {
  APP_VERSION: packageJson.version,
  APP_NAME: packageJson.name,
  APP_DISPLAY_NAME: packageJson.displayName,
  APP_RELEASE: `${packageJson.name}@${packageJson.version}` as const,

  TOKEN_STORAGE_KEY: 'jwt',

  features: {
    lastLogin: false,
    eACard: false,
  } as const,
} as const
