import type { Agency, User } from '@aubade/types/api'
import { VStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'

import { useQueryClient } from '@tanstack/react-query'

import { useMany, useOne } from '../../../core/src/adapters'
import { Paragraph, SelectInput, unit } from '../../../core/src/ui-kit'
import {
  useConnectedUser,
  useMaybeSelectedAgency,
} from '../../../domain/src/adapters'
import { useTranslate } from '../../../translation/src'

export function SelectedAgencyList() {
  const { id: me, role } = useConnectedUser()
  const [user] = useOne<User.Read>({ iri: toHydraId('users', me) })
  if (role !== 'ROLE_APP_CRAFTER_STORE' || !user) return <></>
  return <SelectedAgencyListImpl user={user} />
}

type SelectedAgencyListImplProps = {
  user: User.Read
}

function SelectedAgencyListImpl(props: SelectedAgencyListImplProps) {
  const { user } = props
  const { authorizeAgencies } = user
  const [fullAgencies] = useMany<Agency.Read>({
    iris: authorizeAgencies as any,
  })
  const queryClient = useQueryClient()
  const agency = useMaybeSelectedAgency()
  const isSingleAgency = fullAgencies.length === 1
  const translate = useTranslate()

  const options: Record<string, string> = {}
  fullAgencies.forEach((fullAgency) =>
    Object.assign(options, { [fullAgency['@id']]: fullAgency.name }),
  )

  if (isSingleAgency)
    return (
      <VStack width="full" gap={unit('8')} alignItems={'flex-start'}>
        <Paragraph text={translate('agency.singleAgency')} />
        <Paragraph
          // size="md"
          fontWeight="bold"
          text={fullAgencies[0].name ?? ''}
        />
      </VStack>
    )

  return (
    <SelectInput
      optionsSelect={options}
      required
      variant="light"
      label="agency.selectAgency"
      value={agency?.agency || undefined}
      onChange={async (value) => {
        if (!value) return
        agency?.changeAgency(value)
        sessionStorage.setItem('agency', value)
        await queryClient.invalidateQueries()
      }}
    />
  )
}
