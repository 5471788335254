import type { GetRowProps } from '@aubade/core/ui-kit'
import { Surface, unit, ResourceList } from '@aubade/core/ui-kit'
import type { CampaignApiList } from '@aubade/types'
import { useCallback } from 'react'
import { useUrls } from 'src/App'
import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { ListHeader } from '../../components/ListHeader'

import { CreateCampaign } from './components/CreateCampaign'
import { CreateEdit } from './CreateEdit'

import { useGetColumns } from './useGetColumns'

const templateColumns = '400px 300px 1fr'
const resource = 'campaign'
const scope = 'campaign'
export function CampaignList() {
  return (
    <ResourcePageLayout>
      <List />
      <CreateEdit />
    </ResourcePageLayout>
  )
}

function List() {
  const urls = useUrls()
  const columns = useGetColumns()
  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
      paddingX={unit('10')}
      paddingY={unit('40')}
    >
      <ListHeader
        scope={scope}
        mainAction={<CreateCampaign />}
        withSearchBar={false}
      />
      <ResourceList<CampaignApiList>
        dataProviderName="default"
        sort={[{ field: 'sort_value', order: 'desc' }]}
        scope={resource}
        resource={resource}
        title={{
          mainTitle: `${resource}.tabletitle`,
          extraTitle: '',
        }}
        tableProps={{
          templateColumns: templateColumns,
          columns: columns,
          noDataLabel: `table.noData.${resource}`,
          getRowProps: useCallback<GetRowProps<CampaignApiList>>(
            (row) => {
              return {
                to: urls.aubade().campaignEdit(row.id),
              }
            },
            [urls],
          ),
        }}
      />
    </Surface>
  )
}
