import { debounceIdleCallback, debounceImmediateCallback } from '@nartex/stdlib'
import { useMemoOne } from 'use-memo-one'

import { useEvent } from './useEvent'

type Callback = (...args: any[]) => void
type Cancel = () => void
type Cancellable<T extends Callback> = (...args: Parameters<T>) => Cancel

export function useLowPriorityCallback<T extends Callback>(
  callback: T,
): Cancellable<T> {
  callback = useEvent(callback)
  return useMemoOne(() => {
    return debounceIdleCallback(callback, 200)
  }, [callback])
}

export function useImmediateCallback<T extends Callback>(
  callback: T,
): Cancellable<T> {
  callback = useEvent(callback)
  return useMemoOne(() => {
    return debounceImmediateCallback(callback)
  }, [callback])
}
