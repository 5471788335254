import type { FunctionComponent, PropsWithChildren } from 'react'
import { createContext, useContext as useReactContext } from 'react'

import { useDeepCompareMemo } from '../hooks/useDeepCompare'

const EMPTY = Symbol('empty context')
type TProvider<T> = FunctionComponent<PropsWithChildren<{ value: T }>>
type TUseContextFunction<T> = () => T

export function createContextFunction<T>(
  name: string,
  getDefaultValue?: () => T,
): [TProvider<T>, TUseContextFunction<T>] {
  const Context = createContext<T | typeof EMPTY>(
    getDefaultValue ? getDefaultValue() : EMPTY,
  )

  const useContext: TUseContextFunction<T> = function useContext() {
    const resolved = useReactContext(Context)
    if (resolved === EMPTY) {
      throw Error(`No Provider for context "${name}"`)
    }
    return resolved
  }
  if (process.env.NODE_ENV !== 'production') {
    Object.defineProperty(useContext, 'name', {
      value: `useContext(${name})`,
    })
  }

  const Provider: TProvider<T> = function Provider(props) {
    const { value, children } = props

    const memoValue = useDeepCompareMemo(() => value, [value])
    return <Context.Provider value={memoValue}>{children}</Context.Provider>
  }
  if (process.env.NODE_ENV !== 'production') {
    Object.defineProperty(Provider, 'name', {
      value: `Provider(${name})`,
    })
  }

  return [Provider, useContext] as const
}
