import { debounceImmediateCallback, type Falsy } from '@nartex/stdlib'
import { groupBy, isTruthy } from 'remeda'

type NonEmptyArray<T> = [T, ...T[]]

type AbortablePromise<T> = Promise<T> & { abort: () => void }

type ScheduledCall<Args, Result> = {
  args: Args
  resolve: (result: Result) => void
  reject: (error: unknown) => void
}
export function combineAsyncFunctions<
  Args extends any[],
  Result,
  GroupName extends string,
>(
  getGroupName: (args: Args, indexInQueue: number) => GroupName,
  groupedCall: (
    args: NonEmptyArray<Args>,
    groupName: GroupName,
  ) => Promise<Result[]>,
): (...args: Args) => AbortablePromise<Result> {
  let scheduledCalls: ScheduledCall<Args, Result>[] = []

  const scheduleCall = debounceImmediateCallback(async () => {
    if (!scheduledCalls.length) return

    const handledCalls = [...scheduledCalls] as NonEmptyArray<
      ScheduledCall<Args, Result>
    >
    scheduledCalls = []

    const groups = groupBy(handledCalls, (call, index) =>
      getGroupName(call.args, index),
    )
    const entries = Object.entries(groups) as [
      GroupName,
      NonEmptyArray<ScheduledCall<Args, Result>>,
    ][]

    entries.forEach(async ([groupName, calls]) => {
      try {
        const results = await groupedCall(
          calls.map((call) => call.args) as NonEmptyArray<Args>,
          groupName,
        )
        calls.forEach((call, index) => call.resolve(results[index]))
      } catch (error) {
        calls.forEach((call) => call.reject(error))
      }
    })
  })

  return (...args: Args): AbortablePromise<Result> => {
    const { promise, reject, resolve } = PromiseWithResolvers<Result>()

    const scheduledCall = { args, resolve, reject }
    scheduledCalls.push(scheduledCall)
    scheduleCall()

    return Object.assign(promise, {
      abort: () => {
        scheduledCalls.splice(scheduledCalls.indexOf(scheduledCall), 1)
      },
    })
  }
}

export function createSignalAll(
  maybeSignals: (AbortSignal | Falsy)[],
): AbortSignal {
  const signals = maybeSignals.filter(isTruthy)

  if (signals.length === 1) return signals[0]

  const controller = new AbortController()

  signals.forEach((signal) =>
    signal.addEventListener('abort', () => {
      if (signals.every((s) => s.aborted)) {
        controller.abort()
      }
    }),
  )

  return controller.signal
}

// TODO use Promise.withResolvers() when available
function PromiseWithResolvers<T, Err = unknown>() {
  let onResolve: (value: T) => void = () => {}
  let onReject: (error: Err) => void = () => {}

  const promise = new Promise<T>((resolve, reject) => {
    onResolve = resolve
    onReject = reject
  })
  return { promise, resolve: onResolve, reject: onReject }
}
