import { useOne } from '@aubade/core/adapters'
import { toHydraId } from '@nartex/api-platform'
import jwt_decode from 'jwt-decode'

import { isInvalidToken } from '../../../../apps/bo/src/components/Auth'
import type { ExtendedAgency, ExtendedUser } from '../../../types/src'
import type { Society, UserFunction } from '../../../types/src/api'
import type { CustomerRole, UserRole } from '../../../types/src/index'

export type ConnectedUser = {
  id: string
  role: UserRole
  society?: string
}

export type ApiToken = {
  iat: number
  exp: number
  roles: UserRole[]
  id: string
}

export type GetUserReturn = {
  id: string
  role?: UserRole
  society: Society.Read | undefined
  agency: ExtendedAgency | undefined
  userFunction?: UserFunction.Read
}

export function useGetUser(): GetUserReturn {
  const apiToken = sessionStorage.getItem('jwt')

  const [user] = useOne<ExtendedUser>(
    {
      iri: toHydraId(
        'users',
        apiToken ? jwt_decode<ApiToken>(apiToken!).id : '',
      ),
    },
    { enabled: Boolean(apiToken) },
  )

  if (!apiToken || isInvalidToken(apiToken) || !user) {
    // @ts-expect-error
    return { id: undefined, role: undefined }
  }

  const adminRole = jwt_decode<ApiToken>(apiToken).roles.find(
    (role) => role === 'ROLE_SUPER_ADMIN' || role === 'ROLE_APP_CRAFTER_ADMIN',
  )
  const userRole = jwt_decode<ApiToken>(apiToken).roles.find((role) =>
    role.startsWith('ROLE_APP'),
  )

  return {
    id: user.id!,
    role: (adminRole || userRole) as ConnectedUser['role'],
    society: user?.society,
    agency: user?.agency,
    userFunction: user?.userFunction,
  } satisfies GetUserReturn
}

export function isAdmin(role?: UserRole | CustomerRole) {
  return role === 'ROLE_SUPER_ADMIN' || role === 'ROLE_APP_CRAFTER_ADMIN'
}
export function isSociety(role?: UserRole | CustomerRole) {
  return role === 'ROLE_APP_CRAFTER_COMPANY'
}
export function isAgency(role?: UserRole | CustomerRole) {
  return role === 'ROLE_APP_CRAFTER_STORE'
}
