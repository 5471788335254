import { isDeepEqual, isTruthy, isNullish } from 'remeda'

import type { Falsy } from './truthy'
import type { Paths } from './utilityTypes'

export function filterKeys<T extends Record<string, any>, U extends Partial<T>>(
  object: T,
  predicate: (key: keyof T, value: T[keyof T]) => boolean,
): U {
  const result = {} as U

  for (const key in object) {
    const value = object[key]

    const pass = predicate(key, value)
    if (pass) {
      ;(result as any)[key] = value
    }
  }

  return result
}

export function filterValues<
  T extends Record<string, any>,
  U extends Partial<T>,
>(object: T, predicate: (value: T[keyof T], key: keyof T) => boolean): U {
  const result = {} as U

  for (const key in object) {
    const value = object[key]

    const pass = predicate(value, key)
    if (pass) {
      ;(result as any)[key] = value
    }
  }

  return result
}

/** filters out falsy values from object */
export function compactObj<T extends Record<string, any>>(
  object: T,
): { [K in keyof T]: T[K] extends Falsy ? never : T[K] } {
  return filterValues(object, isTruthy)
}

type EmptyObject = Record<string, never>

type Empty = EmptyObject | [] | '' | null | undefined
export function isEmpty(value: unknown): value is Empty {
  if (value == null) return true
  if (isDeepEqual(value, {})) return true
  if (isDeepEqual(value, [])) return true
  if (isDeepEqual(value, '')) return true
  if (typeof value === 'object' && value.constructor.name === 'Object') {
    return Object.values(value).every(isEmpty)
  }
  if (Array.isArray(value)) {
    return value.every(isEmpty)
  }

  return false
}

export type RuleKey<T extends Record<string, any>> =
  | Paths<T, { maxDepth: 3 }>
  | Paths<T, { maxDepth: 3 }>[]

/**
 * Function to validate that every keys passed as argument are defined in object
 * @param object The object to validate
 * @param keys An array of key to validate in the object, if we pass an subArray we check that at least one of the key is defined
 * @returns A set of the undefined keys in the object
 */
export function validateObject<T extends Record<string, any>>(
  object: T,
  keys: RuleKey<T>[],
): RuleKey<T>[] {
  const result: RuleKey<T>[] = []

  keys.forEach((key) => {
    if (Array.isArray(key)) {
      if (key.some((subKey) => !isNullish(getAtPath(object, subKey)))) {
        return
      }
    } else {
      if (!isNullish(getAtPath(object, key))) return
    }
    result.push(key)
  })

  return result
}

function getAtPath(val: any, path: string | number) {
  let result = val
  for (const key of String(path).split('.')) {
    result = result?.[key]
  }
  return result
}
