import type { ImageProps } from '@chakra-ui/react'
import { Box, Image as ChakraImage } from '@chakra-ui/react'
import { useQueryMetaData } from '@nartex/data-provider/react'
import { ProfunctorState } from '@nartex/react-libs'
import { forwardRef, type ForwardedRef, memo } from 'react'

import { createImmutableSet, useEvent } from '../libs'

import { useDesignSystemConfig } from '.'

const errored = ProfunctorState.createRoot(createImmutableSet())

export const Image = memo(
  forwardRef(function Image(
    props: ImageProps,
    ref: ForwardedRef<HTMLImageElement>,
  ) {
    const { src } = props
    const { s3 } = useDesignSystemConfig()
    const { headers } = (useQueryMetaData() ?? {}) as {
      headers?: Record<string, string>
    }

    const [failedImages, setFailedImages] = errored.useState()
    const onError = useEvent(() => {
      setFailedImages((set) => set.withAdded(src))
    })

    if (!src || failedImages.has(src)) {
      return <Box {...props} />
    }

    if (
      src?.startsWith('/presigned_s3_get') ||
      src?.startsWith('/s3/presigned_s3_get')
    ) {
      const inlineHeaders = new URLSearchParams(headers ?? {}).toString()
      const url = `${s3.url}${src}?${inlineHeaders}`.replace('/s3/s3/', '/s3/')
      return <ChakraImage ref={ref} {...props} src={url} onError={onError} />
    }

    return <ChakraImage ref={ref} {...props} src={src} onError={onError} />
  }),
)
