import type { DataProvider } from '@aubade/core/adapters'
import type { ExtendedUser, ExtendedAgency } from '@aubade/types'
import type { CraftCompany, Society, User, Document } from '@aubade/types/api'
import { cleanHydraId } from '@nartex/api-platform'
import type { ResourceDataProvider } from '@nartex/data-provider'

export function UsersProxy(dataProvider: DataProvider): Partial<DataProvider> {
  // @ts-expect-error
  return {
    ...dataProvider,
    // @ts-expect-error
    async getOne(params) {
      const data = await dataProvider.getOne<User.Read>(params)
      const {
        agency,
        society,
        craftCompany,
        societyAgency,
        isAvailable,
        avatar,
        userFunction,
      } = data.data
      let fullCraftCompany
      let fullSociety
      let fullAgency
      let fullSocietyAgency
      let fullAvatar

      if (avatar) {
        const { data: fullDocumentData } =
          await dataProvider.getOne<Document.Read>({
            ...params,
            resource: 'documents',
            id: cleanHydraId(avatar),
          })
        fullAvatar = fullDocumentData
      }
      if (agency) {
        const { data: fullAgencyData } =
          await dataProvider.getOne<ExtendedAgency>({
            ...params,
            resource: 'agencies',
            id: agency,
          })
        fullAgency = fullAgencyData
      }
      if (society) {
        const { data: fullSocietyData } =
          await dataProvider.getOne<Society.Read>({
            ...params,
            resource: 'societies',
            id: society,
          })
        fullSociety = fullSocietyData
      }
      if (craftCompany) {
        const { data: fullCraftCompanyData } =
          await dataProvider.getOne<CraftCompany.Read>({
            ...params,
            resource: 'craft_companies',
            id: cleanHydraId(craftCompany),
          })

        fullCraftCompany = fullCraftCompanyData
        const { society: craftSociety, agency: craftAgency } = fullCraftCompany
        const { data: craftSocietyData } =
          await dataProvider.getOne<Society.Read>({
            ...params,
            resource: 'societies',
            id: cleanHydraId(craftSociety),
          })
        fullSociety = craftSocietyData

        const { data: craftAgencyData } =
          await dataProvider.getOne<ExtendedAgency>({
            ...params,
            resource: 'agencies',
            id: cleanHydraId(craftAgency),
          })
        fullAgency = craftAgencyData
      }

      if (societyAgency) {
        const { data: fullSa } = await dataProvider.getOne<Society.Read>({
          ...params,
          resource: 'societies',
          id: societyAgency,
        })
        fullSocietyAgency = fullSa
      }

      const fullUser = {
        ...data.data,
        avatar: fullAvatar ? [fullAvatar] : undefined,
        userFunctionId: userFunction?.id,
        isNotAvailable: !isAvailable,
        agency: fullAgency,
        society: fullSociety,
        craftCompany: fullCraftCompany,
        jobIds: fullCraftCompany?.jobsIds,
        societyAgency: fullSocietyAgency,
      } as unknown as ExtendedUser

      return {
        ...data,
        data: fullUser,
      }
    },
    async update(params) {
      const variables = params.variables as ExtendedUser
      const { isNotAvailable } = variables

      return dataProvider.update<any>({
        ...params,
        variables: {
          ...variables,
          isAvailable: !isNotAvailable,
        },
      })
    },
  } satisfies Partial<ResourceDataProvider<ExtendedUser>>
}
