import type { LogicalFilter } from '@aubade/core/adapters'
import type { Contact } from '@aubade/types'
import type { DataProvider } from '@nartex/data-provider/react'
import { config } from 'src/config'
import { logicalToCrudApiFilter } from 'src/libs/logicalToCrudApiFilter'

export function ContactsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getList<T>(params) {
      const filters = params.filters as unknown as LogicalFilter<T>[]
      const apiFilters = parseFilters<T>([...filters])
      const contacts = await dataProvider.custom!<Contact[]>({
        ...params,
        method: 'post',
        url: `/contacts`,
        payload: apiFilters,
      })

      const { lastLogin } = config.features

      const filteredDatas = contacts?.data.filter((contact) => {
        if (lastLogin) {
          return contact.lastLoginAt !== null
        } else {
          return contact
        }
      })

      return {
        data: filteredDatas,
        total: filteredDatas.length,
      }
    },
  }
}

function parseFilters<T>(listFilters?: LogicalFilter<T>[]) {
  if (!listFilters) return undefined
  const payloadFilters = logicalToCrudApiFilter<T>(listFilters)
  return payloadFilters
}
