import type { HydraId } from '@nartex/data-provider'
import type { DataProvider as RefineDataProvider } from '@pankod/refine-core'
import type { AxiosRequestConfig } from 'axios'
import ModernError from 'modern-errors'

type DataProvider = Required<RefineDataProvider>

export type GetListParams = Parameters<DataProvider['getList']>[0]
export type GetManyParams = Parameters<DataProvider['getMany']>[0]
export type GetOneParams = Parameters<DataProvider['getOne']>[0]
export type CreateParams = Parameters<DataProvider['create']>[0]
export type CreateManyParams = Parameters<DataProvider['createMany']>[0]
export type UpdateParams = Parameters<DataProvider['update']>[0]
export type UpdateManyParams = Parameters<DataProvider['updateMany']>[0]
export type DeleteOneParams = Parameters<DataProvider['deleteOne']>[0]
export type DeleteManyParams = Parameters<DataProvider['deleteMany']>[0]
export type CustomParams = Parameters<DataProvider['custom']>[0] &
  Pick<GetListParams, 'hasPagination' | 'pagination'>
// ===

export type HydraResource = `/${string}`

export type HydraCollection<T extends object = object> = {
  '@context': `/${string}`
  '@id': HydraResource
  '@type': 'hydra:Collection'
  'hydra:member': (T & HydraItem)[]
  'hydra:totalItems': number
  'hydra:view': Record<string, string>
  'hydra:search': Record<string, string>
}

type JsonLdValue = string | number | null | HydraId

export type HydraItem = {
  '@context': `/${string}`
  '@id': HydraId
  '@type': string
  [k: string]: JsonLdValue | JsonLdValue[] | Record<string, JsonLdValue>
}

export type HydraParams = {
  page: number
  itemsPerPage: number
  [propName: string]: string | number
}

export type WithHeaders = {
  headers?: AxiosRequestConfig['headers']
}

export type HydraError = {
  '@type': 'hydra:Error'
  'hydra:title': string
  'hydra:description': string
  trace?: Array<{
    namespace: string
    short_class: string
    class: string
    type: string
    function: string
    file: string
    line: number
    args: [string, unknown]
  }>
}

export const ApiPlatformProviderError = ModernError.subclass(
  'ApiPlatformProviderError',
)
