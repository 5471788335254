import type { DocCreate } from '@aubade/core/adapters'
import { arrayify, createContextFunction, isTruthy } from '@aubade/core/libs'
import { Paragraph, useDocumentUrl, Button, unit } from '@aubade/core/ui-kit'
import { IconPlus } from '@aubade/design/graphics'
import { Avatar } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { Document } from '@aubade/types/api'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Icon, HStack, AspectRatio, Image, Center } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'

import path from 'path-browserify'
import type { Accept } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { useWatch } from 'react-hook-form'
import { v4 } from 'uuid'

import type { RHFInputProps } from '../controller'
import { useInput } from '../controller'

export type S3Config = {
  url: string
  resizeUrl: string
}

export type Bucket = {
  type: string
}

type InputValue = DocCreate | Document.Read

const COMMON_STYLE: BoxProps = {
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white.500',
  width: '100%',
  height: '100%',
}

const LOGO_STYLE: BoxProps = {
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed',
  borderColor: 'grey.500',
  borderRadius: '10px',
  width: '100%',
  height: '50px',
}

const PROFILE_STYLE: BoxProps = {
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  backgroundColor: 'white',
}

export type DocumentCategory = 'Other' | 'Picture'

export const [BucketProvider, useBucket] = createContextFunction<
  Bucket | undefined
>('Bucket', () => undefined)

type DropZoneInputProps<T> = RHFInputProps<
  T,
  InputValue | InputValue[] | null
> & {
  multiple?: boolean
  onAppend?: (doc: DocCreate) => void
  boxProps?: BoxProps
  objectId?: string
  variant?: 'logo' | 'documents' | 'icon' | 'profile'
  submitOnAccept?: (value: any) => void
  alt?: string
  accept: Accept
  maxFiles?: number
}
export function DropZoneInput<T>(props: DropZoneInputProps<T>) {
  const {
    name,
    multiple = false,
    onAppend,
    boxProps,
    objectId,
    variant = 'documents',
    submitOnAccept: submit,
    disabled,
    alt,
    accept,
    maxFiles,
  } = props
  const { id, wrap, field } = useInput<T, InputValue | InputValue[] | null>(
    props,
  )

  const translate = useTranslate()
  const bucket = useBucket()

  const actualValue = field.value

  if (!bucket) {
    throw new Error(
      'Input.Dropzone element needs to be provided a "Bucket" through the context (usually via `<Form {...controller} bucket={bucket} />`',
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    disabled: disabled,
    async onDrop(acceptedFiles) {
      if (!acceptedFiles[0]) return
      if (multiple) {
        const documents = acceptedFiles.map((acceptedFile) => {
          return initDocument(acceptedFile, bucket, objectId)
        })
        if (onAppend) {
          documents.forEach((doc) => onAppend(doc))
        } else {
          field.onChange(
            [...arrayify(actualValue), ...documents].filter(isTruthy),
          )
        }
      }
      if (!multiple) {
        const doc = initDocument(acceptedFiles[0], bucket, objectId)

        if (onAppend) {
          onAppend(doc)
        } else {
          field.onChange(doc)
        }
      }
    },
    onDropAccepted(acceptedFiles) {
      if (submit) {
        if (multiple) {
          const documents = acceptedFiles.map((acceptedFile) => {
            return initDocument(acceptedFile, bucket, objectId)
          })
          submit(documents)
        } else {
          const document = initDocument(acceptedFiles[0], bucket, objectId)
          submit(document)
        }
      }
    },
  })

  const value = useWatch<Record<string, DocCreate | Document.Read | undefined>>(
    {
      name: name as string,
    },
  )

  const pictureUrl = useDocumentUrl(value, {
    size: { width: 600, height: 600 },
    ratio: 'crop',
  })

  if (field.readOnly) return <></>

  if (variant === 'profile') {
    return (
      <Box position="relative">
        <input {...getInputProps()} />
        <Avatar
          {...getRootProps({
            id,
            role: 'button',
            title: translate(props?.label ?? ' '),
            fontSize: '19px',
          })}
          {...PROFILE_STYLE}
          name={alt ?? ''}
          picture={pictureUrl}
          variant="xxl"
        />

        <Center
          position="absolute"
          bottom={0}
          right={0}
          border="3px solid"
          borderColor="lightGrey.500"
          width={unit('20')}
          height={unit('20')}
          borderRadius={unit('10')}
          backgroundColor={'blue.500'}
        >
          <Icon
            as={IconPlus}
            color="white"
            width={unit('8')}
            height={unit('8')}
          />
        </Center>
      </Box>
    )
  }

  if (variant === 'icon') {
    return (
      <>
        <input {...getInputProps()} />
        <Button
          {...getRootProps()}
          variant="circleNegative"
          leftIcon={IconPlus}
        />
      </>
    )
  }

  if (variant === 'documents') {
    return wrap(
      <Box
        {...getRootProps({
          id,
          role: 'button',
          title: translate(props?.label ?? ' '),
          fontSize: '19px',
        })}
        {...COMMON_STYLE}
        {...boxProps}
      >
        <input {...getInputProps()} />
        {(!value || multiple) && (
          <Icon as={IconPlus} width="15px" height="15px" color="darkGrey.500" />
        )}
      </Box>,
    )
  }
  if (variant === 'logo') {
    return wrap(
      <HStack width="full" alignItems="flex-end" gap={unit('10')}>
        <AspectRatio ratio={1} minWidth={unit('50')}>
          {value ? (
            <Image
              alt={value?.displayName ?? ' '}
              src={pictureUrl}
              borderRadius={'50%'}
              border="1px solid"
              borderColor="grey.500"
            />
          ) : (
            <Box
              borderRadius={'50%'}
              border="1px solid"
              borderColor="grey.500"
            />
          )}
        </AspectRatio>
        <Box
          {...getRootProps({
            id,
            role: 'button',
            title: translate(props?.label ?? ' '),
            fontSize: '19px',
          })}
          {...LOGO_STYLE}
        >
          <input {...getInputProps()} />
          <Paragraph
            text={'input.dropzone.logo'}
            fontWeight="bold"
            size="2xs"
          />
        </Box>
      </HStack>,
    )
  }
}

export function initDocument(
  initialFile: File,
  bucket: Bucket,
  objectId?: string,
): DocCreate {
  const id = v4()
  const mimeType = initialFile.type

  const extension = initialFile.name.split('.').at(-1)

  const displayName = path.parse(initialFile.name).name

  return {
    '@type': 'Document',
    '@id': toHydraId('documents', id),
    newId: id,
    title: undefined,
    description: undefined,
    publication: undefined,
    pictureLink: undefined,
    playerEmbedUrl: undefined,
    folder: undefined,
    owner: undefined,
    category: undefined,
    fileName: `${id}.${extension}`,
    displayName,
    size: initialFile.size,
    mimeType,
    extension: extension!,
    objectId: objectId ?? id,
    objectType: bucket.type,
    file: new File([initialFile], `${id}.${extension}`, {
      type: mimeType ?? undefined,
    }),
  }
}
