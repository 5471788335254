import { useRef } from 'react'

import { ProfunctorState } from './ProfunctorState'

export function useProfunctorState<T>(
  initial: T | (() => T),
): ProfunctorState<T> {
  const ref = useRef<ProfunctorState<T> | undefined>(undefined)

  if (!ref.current) {
    ref.current = ProfunctorState.createRoot(initial)
  }

  return ref.current
}
