import type { LogicalFilter } from '@aubade/core/adapters'
import { toCrudFilters, useFiltersSearchParams } from '@aubade/core/ui-kit'
import type { User } from '@aubade/types/api'

import { cleanHydraId } from '@nartex/data-provider'

import type { PublicationFilterType } from '../../Publications/filters/types'
import type { ConversationFilterType } from '../types'

export function useConversationFilters(scope: 'conversations' | 'summary') {
  return useFiltersSearchParams<ConversationFilterType, User.Read>({
    scope: scope,
    defaultFilters:
      scope === 'summary'
        ? {}
        : { multi: false, internal: false, external: false },
    toCrudFilters(filtersState) {
      const { agencies, q, internal, external, ...defaultParse } = filtersState
      const filteredAgencies = agencies?.filter(
        (agency) => agency !== undefined,
      )
      return [
        ...toCrudFilters(defaultParse),
        filteredAgencies && agenciesFilterToCrud(filteredAgencies),
        q && queryToCrud(q),
        (internal || external) && profileToCrudFilter(internal, external),
      ]
    },
  })
}

function agenciesFilterToCrud(
  values: string[],
): LogicalFilter<PublicationFilterType> {
  const cleanValues = values.map(cleanHydraId)

  return {
    field: 'agencies',
    operator: 'eq',
    value: cleanValues,
  }
}

function queryToCrud(query: string): LogicalFilter<PublicationFilterType> {
  return {
    field: 'q',
    operator: 'eq',
    value: query,
  }
}

function profileToCrudFilter(
  internal?: boolean,
  external?: boolean,
): LogicalFilter<PublicationFilterType> {
  if (internal && !external) {
    return {
      field: 'isInternal',
      operator: 'eq',
      value: true,
    }
  }
  if (!internal && external) {
    return {
      field: 'isInternal',
      operator: 'eq',
      value: false,
    }
  }

  return {
    field: 'isInternal',
    operator: 'eq',
    value: undefined,
  }
}
