import type { LogicalFilter } from '@aubade/core/adapters'
import { Paragraph, unit, makeForm } from '@aubade/core/ui-kit'

import { useConnectedUser } from '@aubade/domain/adapters'
import { isSociety } from '@aubade/domain/components'
import { Trans } from '@aubade/translation'
import { HStack, SimpleGrid, VStack } from '@chakra-ui/react'
import type { BaseSyntheticEvent } from 'react'

import type { StatisticsFiltersType } from '../Filters'
import type { StatMessages, TemporaryStatSummary } from '../types'

import { useGetStats } from './useGetStats'

type Props = {
  crudFilters: LogicalFilter<TemporaryStatSummary>[]
  state?: StatisticsFiltersType
  onChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}

export function MessageSection(props: Props) {
  const { crudFilters, ...rest } = props
  const stats = useGetStats(crudFilters)

  if (!stats) return <></>
  const { messages } = stats[0]
  return (
    <VStack
      width="full"
      backgroundColor={'white'}
      padding={unit('60')}
      borderRadius={unit('30')}
      gap={unit('20')}
    >
      <Header {...rest} messages={messages} />
      <GridMessages {...rest} messages={messages} />
    </VStack>
  )
}

function Header(
  props: Omit<Props, 'crudFilters'> & { messages: StatMessages[] },
) {
  const { messages, onChange, state } = props

  const label = useGetLabel(state)

  const averageCount = getAverageCount(messages)
  return (
    <HStack width="full" justifyContent={'space-between'}>
      <Trans
        i18nKey={label}
        components={{
          paragraph: (
            <Paragraph
              whiteSpace={'pre'}
              textAlign={'center'}
              fontWeight="bold"
            />
          ),
          count: <span style={{ fontWeight: 'bold', color: '#009EE0' }} />,
        }}
        values={{
          count: averageCount,
        }}
      />
      <HStack>
        <MessagesFilters onChange={onChange} />
      </HStack>
    </HStack>
  )
}

function GridMessages(
  props: Omit<Props, 'crudFilters'> & { messages: StatMessages[] },
) {
  const { messages } = props
  return (
    <SimpleGrid width="full" columns={3} spacing={unit('20')}>
      {messages.map((message, key) => (
        <MessageCard key={key} {...message} />
      ))}
    </SimpleGrid>
  )
}

function MessageCard(props: StatMessages) {
  const { smalltext, boldtext, count } = props
  return (
    <HStack
      width="full"
      justifyContent={'space-between'}
      borderRadius={unit('20')}
      backgroundColor="lightGrey.500"
      paddingX={unit('40')}
      paddingY={unit('20')}
    >
      <VStack gap={0} alignItems={'flex-start'}>
        {smalltext && <Paragraph text={smalltext} />}
        {boldtext && <Paragraph fontWeight="bold" text={boldtext} />}
      </VStack>
      <Paragraph
        text={String(count)}
        size="md"
        fontWeight="bold"
        color="primary.500"
      />
    </HStack>
  )
}

const { Input } = makeForm<StatisticsFiltersType>()

function MessagesFilters(props: Pick<Props, 'onChange'>) {
  const { onChange } = props

  return (
    <Input.RadioButtons
      inline
      name="displayMessageType"
      borderColor="grey.500"
      onChange={() => onChange()}
      options={{
        all: 'filters.displayMessageType.all',
        associates: 'filters.displayMessageType.associates',
        customers: 'filters.displayMessageType.customers',
      }}
    />
  )
}

function getAverageCount(messages: StatMessages[]) {
  if (!messages.length) return 0
  return Math.round(
    messages.reduce((acc, message) => acc + message.count, 0) / messages.length,
  )
}

function useGetLabel(state?: StatisticsFiltersType) {
  const { role } = useConnectedUser()

  if (isSociety(role)) {
    if (state && state.agencies?.length) {
      return 'statistics.messages.average.collaboraters'
    }
    return 'statistics.messages.average.agencies'
  }

  if (state && state.societies?.length) {
    return 'statistics.messages.average.agencies'
  }
  if (state && state.agencies?.length) {
    return 'statistics.messages.average.collaboraters'
  }

  return 'statistics.messages.average'
}
