import { useOne } from '@aubade/core/adapters'
import {
  Button,
  ButtonWithConfirm,
  makeForm,
  useModal,
} from '@aubade/core/ui-kit'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isSociety, isAgency } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { Contact, PublicationForm } from '@aubade/types'
import { Stack, VStack } from '@chakra-ui/react'

import { cleanHydraId, toHydraId, useDelete } from '@nartex/data-provider/react'
import { useMutation } from '@tanstack/react-query'

import { useMemo } from 'react'

import { useAubadeQueryBuilder } from 'src/aubadeQueryBuilder/useAubadeQueryBuilder'

import { usePublicationRights } from '../../adapters/rights'

import { PublicationPreview } from './Components'
import { useFormValidation } from './formUtils/formValidators'
import { useIsBlocked } from './useIsBlocked'

const { useWatch, useFormContext } = makeForm<
  PublicationForm,
  'fullAuthor' | 'documents' | 'publishedAt' | 'isDraft'
>()

type Props = {
  values: PublicationForm
  standAlone: boolean
  canPartialEdit: boolean
  newPublicationId?: string
  saveButtonProps: FormSubmitProps
}

export function Actions(props: Props) {
  const {
    values,
    standAlone,
    canPartialEdit,
    newPublicationId,
    saveButtonProps,
  } = props

  return (
    <VStack alignItems="flex-start" justifyContent="flex-start" gap={'60px'}>
      <Stack
        gap={['40px', '40px', '30px']}
        justifyContent={'flex-start'}
        direction={['column', 'column', 'row']}
      >
        <SaveSection
          values={values}
          canPartialEdit={canPartialEdit}
          newPublicationId={newPublicationId}
          saveButtonProps={saveButtonProps}
        />

        <DraftSection values={values} saveButtonProps={saveButtonProps} />
        <PreviewSection standAlone={standAlone} />
        <HideSection values={values} />
      </Stack>
      <DeleteSection values={values} />
    </VStack>
  )
}

function HideSection(props: Pick<Props, 'values'>) {
  const { values } = props
  const { id: me, role } = useConnectedUser()

  const fullAuthor = useWatch('fullAuthor')

  const [currentUser] = useOne<Contact>({
    iri: toHydraId('users', me),
  })
  const isBlocked = useIsBlocked(values.blockedBy)

  const hideButtonLabel = isBlocked ? 'buttons.unMask' : 'buttons.hide'

  const aubadeQueryBuilder = useAubadeQueryBuilder()
  const { mutate: mutateHide } = useMutation(
    aubadeQueryBuilder.toggleHidePublication(
      isBlocked,
      cleanHydraId(currentUser?.society?.['@id'] ?? ''),
      values.id,
    ),
  )

  const showButtonHide = useMemo(() => {
    if (!fullAuthor) return false
    if (isSociety(role) && fullAuthor.isAubadeAdmin === true) {
      return true
    }
    return false
  }, [fullAuthor, role])

  return (
    <>
      {showButtonHide && (
        <Button
          label={hideButtonLabel}
          variant="text"
          onClick={() => {
            mutateHide()
          }}
        />
      )}
    </>
  )
}

function DeleteSection(props: Pick<Props, 'values'>) {
  const { values } = props
  const fullAuthor = useWatch('fullAuthor')

  const [mutateDelete] = useDelete()
  const translate = useTranslate()

  const showButtonDelete = usePublicationRights().useCanDelete(
    fullAuthor as Contact,
  )

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  async function onDelete(id: string) {
    await mutateDelete(...aubadeQueryBuilder.deletePublication(id))
  }

  return (
    <>
      {showButtonDelete && (
        <ButtonWithConfirm
          dialogProps={{
            title: 'publications.confirmTitle',
            children: translate('publications.confirmText'),
          }}
          confirmButtonProps={{
            isDisabled: false,
            label: 'publications.confirmButton',
            variant: 'primary',
          }}
          buttonProps={{
            label: 'buttons.delete',
            isDisabled: false,
            variant: 'text',
            isDangerous: true,
          }}
          onConfirm={() => onDelete(values.id)}
        />
      )}
    </>
  )
}

function PreviewSection(props: Pick<Props, 'standAlone'>) {
  const { standAlone } = props

  const documents = useWatch('documents')

  const canPreview = useMemo(() => {
    if (!documents) return false
    return documents.length > 0
  }, [documents])

  const [modal, openModal] = useModal({
    withCloseButton: true,
  })

  return (
    <>
      {modal}
      {!standAlone && (
        <Button
          label="buttons.preview"
          variant="text"
          isDisabled={!canPreview}
          onClick={(event) => {
            event.preventDefault()
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            openModal(() => {
              return <PublicationPreview />
            })
          }}
        />
      )}
    </>
  )
}

function SaveSection(
  props: Pick<
    Props,
    'values' | 'canPartialEdit' | 'newPublicationId' | 'saveButtonProps'
  >,
) {
  const { values, canPartialEdit, newPublicationId, saveButtonProps } = props
  const { setValue } = useFormContext()

  const fullAuthor = useWatch('fullAuthor')

  const { role } = useConnectedUser()

  const suite = useFormValidation(isAgency(role))

  const { useCanCreate, useCanUpdate } = usePublicationRights()

  const canCreate = useCanCreate()
  const canUpdate = useCanUpdate(fullAuthor)

  const showButtonSave = useMemo(() => {
    if (newPublicationId) return canCreate
    return canUpdate
  }, [canCreate, canUpdate, newPublicationId])

  const canPublish = useMemo(() => {
    return suite(values).isValidByGroup('publish')
  }, [suite, values])
  const isDisabled = useMemo(() => {
    return Boolean(!canPartialEdit || !canPublish)
  }, [canPartialEdit, canPublish])

  const saveButtonLabel =
    !values.id || values.isDraft ? 'buttons.publish' : 'buttons.save'
  return (
    <>
      {canPartialEdit && showButtonSave && (
        <Button
          {...saveButtonProps}
          label={saveButtonLabel}
          variant="primary"
          isDisabled={isDisabled}
          onClick={async (evt) => {
            const { publishedAt } = values
            setValue('isDraft', false)
            if (!publishedAt || publishedAt === null) {
              setValue('publishedAt', new Date(), { shouldDirty: true })
            }
            await saveButtonProps?.onClick?.(evt)
          }}
        />
      )}
    </>
  )
}

function DraftSection(props: Pick<Props, 'values' | 'saveButtonProps'>) {
  const { values, saveButtonProps } = props
  const { setValue } = useFormContext()

  const { role } = useConnectedUser()

  const showBottonDraft = Boolean(!values.id || values.isDraft)
  const suite = useFormValidation(isAgency(role))

  const canDraft = suite(values).isValidByGroup('draft')

  return (
    <>
      {showBottonDraft && (
        <Button
          {...saveButtonProps}
          label="buttons.draft"
          variant="text"
          isDisabled={!canDraft}
          onClick={async (evt) => {
            setValue('isDraft', true)
            setValue('publishedAt', undefined)
            await saveButtonProps?.onClick?.(evt)
          }}
        />
      )}
    </>
  )
}
