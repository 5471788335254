import type { ReactNode } from 'react'
import { useMemo, useState, useId, useLayoutEffect, useCallback } from 'react'
import { useCallbackOne } from 'use-memo-one'

import { useDeepCompareMemo, useImmediateCallback } from '../hooks'

import { createContextFunction } from './createContextFunction'

const emptySymbol = Symbol('empty')
type EmptySymbol = typeof emptySymbol

export type CombinedElements<U> = Record<string, U>
type SetChildState<U> = (id: string, ownState: U | EmptySymbol) => void

export function createCombinedStateHook<CombinedState, ChildState>(
  combineState: (elements: CombinedElements<ChildState>) => CombinedState,
) {
  const [SetterContext, useSetter] = createContextFunction<
    SetChildState<ChildState>
  >('CombinedState setter', () => () => {})

  const [ValueContext, useCombinedState] = createContextFunction<CombinedState>(
    'CombinedState state',
    () => combineState({}),
  )

  function useSetChildState(ownState: ChildState) {
    const id = useId()
    const setChildState = useImmediateCallback(useSetter())

    const ownStateMemo = useDeepCompareMemo(() => ownState, [ownState])

    useLayoutEffect(() => {
      setChildState(id, ownStateMemo)

      return () => {
        setChildState(id, emptySymbol)
      }
    }, [setChildState, id, ownStateMemo])
  }

  function useCombinedStateRoot() {
    const [combinedElements, setState] = useState<CombinedElements<ChildState>>(
      {},
    )

    const setChildState = useCallbackOne<SetChildState<ChildState>>(
      (id, childState) => {
        setState((prevState) => {
          if (childState === emptySymbol) {
            const result = { ...prevState }
            delete result[id]
            return result
          }

          return {
            ...prevState,
            [id]: childState,
          }
        })
      },
      [],
    )

    const combinedState = useMemo(
      () => combineState(combinedElements),
      [combinedElements],
    )

    const wrapper = useCallback(
      (children: ReactNode) => {
        return (
          <SetterContext value={setChildState}>
            <ValueContext value={combinedState}>{children}</ValueContext>
          </SetterContext>
        )
      },
      [combinedState, setChildState],
    )

    return [combinedState, wrapper] as const
  }

  return { useSetChildState, useCombinedState, useCombinedStateRoot } as const
}
