import type { GetListResponse, GetOneResponse } from '@pankod/refine-core'
import type {
  UseQueryOptions,
  QueriesOptions,
  UseInfiniteQueryOptions,
  QueryClient,
} from '@tanstack/react-query'

import { getResourceType, toHydraId } from '../hydraId'
import type {
  BaseRecord,
  CrudFilter,
  CustomResponse,
  DataProviders,
  ResourceOf,
  HydraId,
  QueryMetaData,
} from '../types'

import type {
  GetListQueryParams,
  GetManyQueryParams,
  GetOneQueryParams,
  CustomQueryParams,
} from './types'

export type QueryBuilder = ReturnType<typeof createQueryBuilder>

export function createQueryBuilder(
  dataProviders: DataProviders,
  queryClient: QueryClient,
  defaultMetaData: QueryMetaData | undefined,
) {
  return {
    getList<T extends BaseRecord, TError = unknown>(
      params: GetListQueryParams<T>,
    ): UseQueryOptions<GetListResponse<T>, TError> {
      const {
        resource,
        dataProviderName = 'default',
        filters,
        hasPagination,
        pagination,
        sort,
      } = params

      const dataProvider = dataProviders[dataProviderName]

      const metaData = {
        ...defaultMetaData,
        ...params.metaData,
      }

      const isPaginated = hasPagination !== false
      const options = {
        filters,
        hasPagination,
        pagination: isPaginated ? pagination : undefined,
        sort,
        metaData,
      }

      return {
        // refine's nomenclature
        queryKey: [dataProviderName, resource, 'getList', options] as const,
        queryFn: async (opts) => {
          const { signal } = opts

          if (options.hasPagination !== false) {
            return dataProvider.getList<T>({
              resource,
              ...options,
              metaData: {
                ...options.metaData,
                signal,
              },
            })
          }

          const BULK_PAGE_SIZE = 250
          const fetchPage = (index: number) => {
            return queryClient.fetchQuery(
              this.getList<T>({
                resource,
                dataProviderName,
                ...options,
                hasPagination: true,
                pagination: {
                  current: index + 1,
                  pageSize: BULK_PAGE_SIZE,
                },
              }),
            )
          }

          const firstResponse = await fetchPage(0)

          const totalPages = Math.ceil(firstResponse.total / BULK_PAGE_SIZE)
          if (totalPages <= 1) return firstResponse

          const otherPages = Array.from(
            { length: totalPages - 1 },
            (_, i) => i + 1,
          )

          const rest = await Promise.all(otherPages.map(fetchPage))

          return {
            data: [firstResponse.data, ...rest.map((res) => res.data)].flat(),
            total: firstResponse.total,
          }
        },
      } satisfies UseQueryOptions<GetListResponse<T>, TError>
    },

    getInfiniteList<T extends BaseRecord, TError = unknown>(
      params: Omit<GetListQueryParams<T>, 'pagination' | 'hasPagination'> & {
        perPage?: number
      },
    ) {
      const {
        resource,
        dataProviderName = 'default',
        filters,
        sort,
        perPage = 100,
      } = params

      const dataProvider = dataProviders[dataProviderName]

      const metaData = {
        ...defaultMetaData,
        ...params.metaData,
      }

      const options = { filters, sort, metaData }

      return {
        queryKey: [
          dataProviderName,
          resource,
          'getAll',
          options,
          perPage,
        ] as const,
        queryFn: async (opts) => {
          const { signal } = opts
          const pageParam = opts.pageParam || 1
          const listResponse = await dataProvider.getList<T>({
            resource,
            ...options,
            hasPagination: true,
            pagination: {
              current: pageParam,
              pageSize: perPage,
            },
            filters: options.filters as CrudFilter<T>[],
            metaData: {
              ...options.metaData,
              signal,
            },
          })

          return {
            ...listResponse,
            page: pageParam,
          }
        },
        getNextPageParam(lastPage) {
          const { total, page } = lastPage

          if (total <= page * perPage) return undefined

          return page + 1
        },
      } satisfies UseInfiniteQueryOptions<
        GetListResponse<T> & { page: number },
        TError
      >
    },

    getOne<T extends BaseRecord, TError = unknown>(
      params: GetOneQueryParams<T>,
    ) {
      const { dataProviderName = 'default' } = params

      let iri: HydraId
      let resource: ResourceOf<T>
      if ('iri' in params) {
        iri = params.iri
        resource = getResourceType(iri) as ResourceOf<T>
      } else {
        resource = params.resource
        iri = toHydraId(resource, String(params.id))
      }

      const dataProvider = dataProviders[dataProviderName ?? 'default']

      const metaData = {
        ...defaultMetaData,
        ...params.metaData,
      }

      const options = { metaData }

      return {
        // refine's nomenclature
        queryKey: [dataProviderName, resource, 'getOne', iri, options] as const,
        queryFn: (opts) => {
          const { signal } = opts
          return dataProvider.getOne<T>({
            resource,
            id: iri,
            metaData: {
              ...options.metaData,
              signal,
            },
          })
        },
      } satisfies UseQueryOptions<GetOneResponse<T>, TError>
    },

    getMany<T extends BaseRecord, TError = unknown>(
      params: GetManyQueryParams<T>,
    ) {
      let iris: HydraId[]
      if ('iris' in params) {
        iris = params.iris
      } else {
        const { resource, ids } = params
        iris = ids.map((id) => toHydraId(resource, String(id)))
      }

      return {
        queries: iris.map((iri) =>
          this.getOne<T, TError>({ ...params, iri }),
        ) satisfies readonly [...QueriesOptions<GetOneResponse<T>[]>],
      }
    },

    custom<
      T extends BaseRecord,
      TPayload = unknown,
      TQuery = unknown,
      TError = unknown,
    >(params: CustomQueryParams<TPayload, TQuery>) {
      const {
        dataProviderName = 'default',
        filters,
        sort,
        method,
        payload,
        query,
        url,
        headers,
      } = params

      const dataProvider = dataProviders[dataProviderName]

      const metaData = {
        ...defaultMetaData,
        ...params.metaData,
      }

      const options = {
        filters,
        method,
        payload,
        query,
        url,
        headers,
        sort,
        metaData,
      }

      return {
        // refine's nomenclature
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [dataProviderName, 'custom', options] as const,
        queryFn: (opts) => {
          const { signal } = opts
          return dataProvider.custom!({
            ...options,
            metaData: {
              ...options.metaData,
              signal,
            },
          })
        },
      } satisfies UseQueryOptions<CustomResponse<T>, TError>
    },
  }
}
