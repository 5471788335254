import type { History } from 'history'
import type { PropsWithChildren } from 'react'
import { useState, useLayoutEffect } from 'react'
import type { BrowserRouterProps } from 'react-router-dom'
import { Router } from 'react-router-dom'

import { HistoryProvider } from './History'

// usePrompt and useBlocker removed on V6
// Related issue (open): https://github.com/remix-run/react-router/issues/8139

type HistoryRouterProps = BrowserRouterProps & {
  history: History
}

export function HistoryRouter(props: PropsWithChildren<HistoryRouterProps>) {
  const { history, children } = props
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => {
    const unlisten = history.listen(setState)
    return unlisten
  }, [history])

  return (
    <HistoryProvider value={history}>
      <Router
        {...props}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      >
        {children}
      </Router>
    </HistoryProvider>
  )
}
