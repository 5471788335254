import type { LogicalFilter } from '@aubade/core/adapters'
import type { ResourcePickerProps } from '@aubade/core/ui-kit'
import { makeForm, unit } from '@aubade/core/ui-kit'
import type { Option } from '@aubade/core/ui-kit/DataPicker/types'
import { IconOutilsCouleurs } from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import {
  ProfileCard,
  isAdmin,
  isAgency,
  isSociety,
} from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { ExtendedConversationWrite } from '@aubade/types'
import type { User } from '@aubade/types/api'
import type { Contact } from '@aubade/types/index'
import type { Typesense } from '@aubade/types/typesense'
import { HStack } from '@chakra-ui/react'
import { listify, type Merge } from '@nartex/stdlib'
import type { ReactNode } from 'react'
import { startsWith } from 'remeda'

import type {
  CustomerRole,
  UserRole,
} from '../../../../packages/types/src/index'

const { Input } = makeForm<ExtendedConversationWrite>()

type ContactPickerProps = Partial<
  Pick<ResourcePickerProps<User.Read>, 'label' | 'renderValue' | 'renderOption'>
> & {
  name: string
  filters?: LogicalFilter<User.Read>[]
  renderFilters?: (options: Option[]) => ReactNode
  max?: number
}

type ExtendedTypesenseUser = Merge<
  Typesense.Clients | Typesense.Associates,
  {
    craft_company?: {
      id: string
      name: string
    }
    avatarUrl: string
    role: string
    agency?: {
      name: string
    }
    society?: {
      name: string
    }
    userFunction?: {
      name: string
      id: string
    }
    roles?: (UserRole | CustomerRole)[]
  }
>

export function ContactPicker(props: ContactPickerProps) {
  const { label, name, filters, renderFilters, max } = props
  const { id: me } = useConnectedUser()

  return (
    <Input.ResourcePicker<ExtendedTypesenseUser, Contact>
      name={name}
      label={label}
      withoutEmptyText
      // @ts-ignore
      resource="contacts"
      filters={filters}
      renderFilters={renderFilters}
      max={max}
      getListParams={{ dataProviderName: 'default' }}
      renderOption={(user) => {
        const { craft_company } = user
        return (
          <UserCard
            origin="typesense"
            user={user}
            crafter={Boolean(craft_company)}
          />
        )
      }}
      renderValue={(user) => {
        if (user.id === me) return <></>
        const { craftCompany } = user

        return (
          <UserCard
            origin="api"
            user={user}
            selected
            crafter={Boolean(craftCompany)}
          />
        )
      }}
    />
  )
}

type UserCardProps = {
  selected?: boolean
  crafter: boolean
  origin: 'api' | 'typesense'
  url?: string
} & (
  | {
      user: Contact
    }
  | {
      user: ExtendedTypesenseUser
    }
)

export function UserCard(props: UserCardProps) {
  const { origin, user, selected = false, crafter, url } = props

  const { firstname, lastname, avatarUrl } = user
  const userName = listify([firstname, lastname], ' ')
  const companyName = useGetCompanyName(origin, user)

  return (
    <HStack
      width="full"
      borderRadius={'20px'}
      gap={unit('10')}
      backgroundColor="white"
      padding={unit('20')}
      border="1px solid"
      borderColor={selected ? 'blue.500' : 'transparent'}
      overflow={'hidden'}
    >
      <ProfileCard
        title={userName}
        url={url ?? ''}
        picture={avatarUrl}
        subtitle={companyName}
        icon={crafter ? IconOutilsCouleurs : undefined}
      />
    </HStack>
  )
}

function useGetCompanyName(
  origin: 'api' | 'typesense',
  user: ExtendedTypesenseUser | Contact,
) {
  const translate = useTranslate()

  if (origin === 'api') {
    const { craftCompany, society, agency, acRoles, userFunction } =
      user as Contact
    const role = acRoles.AC
    if (isAdmin(role)) return ''
    if (isSociety(role)) {
      return [society?.name, userFunction?.name ?? translate(role)]
        .filter(Boolean)
        .join(' - ')
    }
    if (isAgency(role)) {
      return [agency?.name, userFunction?.name ?? translate(role)]
        .filter(Boolean)
        .join(' - ')
    }
    return [userFunction?.name, craftCompany?.name].filter(Boolean).join(' - ')
  } else {
    const { craft_company, agency, society, roles, userFunction } =
      user as ExtendedTypesenseUser

    const role = roles?.find(startsWith('ROLE_APP'))
    if (isAdmin(role)) return ''
    if (isSociety(role)) {
      return [society?.name, userFunction?.name ?? translate(role ?? '')]
        .filter(Boolean)
        .join(' - ')
    }
    if (isAgency(role)) {
      return [agency?.name, userFunction?.name ?? translate(role ?? '')]
        .filter(Boolean)
        .join(' - ')
    }
    return [userFunction?.name, craft_company?.name].filter(Boolean).join(' - ')
  }
}
