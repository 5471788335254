// inspired from https://usehooks.com/useOnClickOutside/

import { run } from '@nartex/stdlib'
import type { PropsWithChildren, ReactNode } from 'react'
import {
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ProfunctorState } from '../ProfunctorState'

import { useEvent } from './useEvent'

type LayerIds = [string, ...string[]]
type Layer = {
  index: number
  zIndex: number
  id: string
}

const store = run(function initLayerStore() {
  const layerIdsStore = ProfunctorState.createRoot<LayerIds>(['root'])

  return {
    appendLayerId(layerId: string) {
      layerIdsStore.setState((overlaysStack) => [...overlaysStack, layerId])
    },

    removeLayerId(layerId: string) {
      layerIdsStore.setState((stack) => {
        const result = stack.filter((id) => id !== layerId)

        if (result.length === 0) return ['root']
        else return result as LayerIds
      })
    },

    getLastLayerId() {
      return layerIdsStore.getSnapShot().at(-1)!
    },

    useLayer(layerId?: string) {
      const [layerIdOnMount] = useState(() => store.getLastLayerId())

      return layerIdsStore.useSelect<Layer>(
        (stack) => {
          const id = layerId ?? layerIdOnMount

          const isProbablyAnOverlay = Boolean(layerId)

          const stackIndex = run(() => {
            const index = stack.indexOf(id)

            if (index === -1) {
              if (isProbablyAnOverlay) return stack.length
              else return 0
            }

            return index
          })

          return {
            index: stackIndex,
            zIndex: stackIndex * 1000,
            id,
          }
        },
        [layerId, layerIdOnMount],
      )
    },
  }
})

export const { useLayer } = store

type UseClickOutsideWrapperOptions = {
  ignoredElements?: (HTMLElement | null)[]
}
export function useOnClickOutside(
  listener: (event: unknown) => void,
  options?: UseClickOutsideWrapperOptions,
) {
  const onDismiss = useEvent(listener)
  const ignoredElements = useMemo(() => {
    return options?.ignoredElements
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(options?.ignoredElements ?? [])])

  const layerId = useId()
  const layer = useLayer(layerId)

  return [
    layer,
    useCallback(
      function withClickOutsideListener(children: ReactNode) {
        return (
          <ClickOutsideListener
            onDismiss={onDismiss}
            ignoredElements={ignoredElements}
            layerId={layerId}
          >
            {children}
          </ClickOutsideListener>
        )
      },
      [onDismiss, ignoredElements, layerId],
    ),
  ] as const
}

// This must be a component and not a hook, because we must detect when this specific piece of UI is mounted to push it on the global overlaysStack
type ClickOutsideListenerProps = {
  onDismiss: (event: unknown) => void
  ignoredElements?: (HTMLElement | null)[]
  layerId: string
}
function ClickOutsideListener(
  props: PropsWithChildren<ClickOutsideListenerProps>,
) {
  const { onDismiss, children, ignoredElements, layerId } = props

  useLayoutEffect(() => {
    store.appendLayerId(layerId)
    return () => store.removeLayerId(layerId)
  }, [layerId])

  const stableHandler = useEvent((event: unknown) => {
    const isLastLayer = store.getLastLayerId() === layerId
    if (isLastLayer) onDismiss(event)
  })

  const ref = useRef<HTMLDivElement>(null)
  const clickListener = useEvent(
    (event: MouseEvent | FocusEvent | TouchEvent) => {
      const eventTarget = event.target as HTMLElement
      if (!ref.current) return

      // Do nothing if clicking ref's element or descendent elements
      if (ref.current === event.target || ref.current.contains(eventTarget)) {
        return
      }

      if (ignoredElements?.some((element) => element?.contains(eventTarget))) {
        return
      }

      if (
        event.type === 'focusin' &&
        eventTarget.matches('[data-clickoutside-ignore]')
      ) {
        return
      }

      stableHandler(event)
    },
  )

  useEffect(
    () => {
      const options = { capture: true }
      document.addEventListener('mousedown', clickListener, options)
      document.addEventListener('touchstart', clickListener, options)
      document.addEventListener('focusin', clickListener, options)
      return () => {
        document.removeEventListener('mousedown', clickListener, options)
        document.removeEventListener('touchstart', clickListener, options)
        document.removeEventListener('focusin', clickListener, options)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, clickListener],
  )

  // TODO handle mobile backButton too
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        stableHandler(event)
      }
    }

    document.addEventListener('keydown', keydownListener)
    return () => {
      document.removeEventListener('keydown', keydownListener)
    }
  }, [stableHandler])

  return (
    <div style={{ display: 'contents' }} ref={ref} id={`layer-${layerId}`}>
      {children}
    </div>
  )
}
