import { unit, Paragraph } from '@aubade/core/ui-kit'
import { IconCoeurFilled } from '@aubade/design/graphics'
import { HStack, Icon, VStack } from '@chakra-ui/react'

import { useGetTimeDiff } from 'src/libs/getTimediff'

import type { ExtendedTypesensePublication } from '../types'

import { getAuthor } from './PublicationPreview'

export function FeedContent(props: {
  publication: ExtendedTypesensePublication & {
    liked: boolean
    likeCount: number
  }
}) {
  const { publication } = props
  const { author, title, publishedAt, likeCount } = publication

  const publishedFrom = useGetTimeDiff(new Date(publishedAt))
  const authorName = typeof author === 'object' ? author.name : author

  return (
    <VStack
      width="full"
      maxHeight="100%"
      overflow="hidden"
      padding={unit('30')}
      gap={unit('5')}
      alignItems="flex-start"
      justifyContent="center"
      cursor="pointer"
    >
      <HStack gap={unit('5')} width="full">
        {getAuthor(authorName)}
        <Paragraph
          fontWeight="bold"
          color="greyDark.500"
          text={`- ${publishedFrom.diff} ${publishedFrom.text}`}
        />
      </HStack>
      <HStack width="full" justifyContent="flex-start">
        <Paragraph color="greyDark.500" text={title} fontWeight="bold" />
      </HStack>
      <HStack width="full" gap={unit('4')}>
        <Paragraph text={String(likeCount ?? 0)} fontWeight="bold" />
        <Icon
          as={IconCoeurFilled}
          color={'red.500'}
          width="11px"
          height="11px"
        />
      </HStack>
    </VStack>
  )
}
