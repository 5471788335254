import { useMakeTest } from 'src/adapters/useVestTest'
import { create, enforce, group } from 'vest'

import type { NotificationFormType } from '../types'

export function useFormValidation() {
  const test = useMakeTest<NotificationFormType>()

  return create(function (data: Partial<NotificationFormType>) {
    const { title, content } = data

    group('draft', function () {
      test('title', 'publications.title.required', function () {
        return enforce(title).isNotBlank()
      })
      test('content', 'publications.content.required', function () {
        return enforce(content).isNotBlank()
      })
    })

    group('publish', function () {
      test('title', 'publications.title.required', function () {
        return enforce(title).isNotBlank()
      })
      test('content', 'publications.content.required', function () {
        return enforce(content).isNotBlank()
      })
    })
  })
}
