import { useOne } from '@aubade/core/adapters'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isAgency } from '@aubade/domain/components'
import type { ExtendedUserConversation } from '@aubade/types'
import type { BlackList } from '@aubade/types/api'
import type { ExtendedOneConversation } from '@aubade/types/index'
import { useDelete, cleanHydraId, toHydraId } from '@nartex/data-provider/react'
import { useMutation } from '@tanstack/react-query'
import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { useAubadeQueryBuilder } from '../../../aubadeQueryBuilder/useAubadeQueryBuilder'

export function useActions(
  id: string,
  users: ExtendedUserConversation[],
  blackList: ExtendedOneConversation['blackList'],
) {
  const { id: me, role } = useConnectedUser()

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  const { mutate: deleteConversation } = useMutation(
    aubadeQueryBuilder.deleteConversation(id, me),
  )

  const otherUsers = useMemo(
    () => users.filter((user) => cleanHydraId(user.id) !== cleanHydraId(me)),
    [me, users],
  )

  const canBlock = Boolean(
    isAgency(role) &&
      otherUsers.length === 1 &&
      (otherUsers[0].craftCompany || otherUsers[0].craftCompanyId),
  )
  const craft = users.find((user) => user.craftCompany)

  const [fullBlackList] = useOne<BlackList.Read>(
    // @ts-expect-error
    { iri: toHydraId('black_lists', blackList?.id! ?? '') },
    { enabled: Boolean(blackList?.id) },
  )

  const isBlocked = useMemo(() => {
    if (
      craft &&
      craft.craftCompany &&
      typeof craft.craftCompany === 'object' &&
      fullBlackList
    ) {
      if (
        fullBlackList.craftCompany ===
        toHydraId('craft_companies', craft.craftCompany.id!)
      ) {
        return true
      }
    }
    if (craft && craft.craftCompanyId && fullBlackList) {
      if (
        fullBlackList.craftCompany ===
        toHydraId('craft_companies', craft.craftCompanyId)
      ) {
        return true
      }
    }

    return false
  }, [craft, fullBlackList])

  const { mutateAsync: blockCrafter } = useMutation(
    aubadeQueryBuilder.blockCrafter({
      newId: uuid(),
      craftCompany: (craft?.craftCompany?.['@id'] ??
        craft?.craftCompany) as string,
    }),
  )

  const [unblockCrafter] = useDelete()

  async function toggleBlackList() {
    if (isBlocked === true) {
      await unblockCrafter(...aubadeQueryBuilder.unblockCrafter(blackList!.id!))
    } else {
      await blockCrafter()
    }
  }

  return {
    deleteConversation,
    toggleBlackList,
    canBlock,
    isBlocked,
  }
}
