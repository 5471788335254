import type { DayValue } from '@amir04lm26/react-modern-calendar-date-picker'
import type { LogicalFilter } from '@aubade/core/adapters'
import {
  useFiltersSearchParams,
  minMaxFilterToCrud,
  toCrudFilters,
} from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import type { Typesense } from '@aubade/types/typesense'
import { cleanHydraId } from '@nartex/api-platform'

import { endOfDay, startOfDay } from 'date-fns'

import { useScope } from '../../../components'
import { dayValueToDate } from '../../../libs/calendarUtils'

import type { PublicationFilterType } from './types'

export function usePublicationFilters(scope: 'publications' | 'notifications') {
  const { id } = useConnectedUser()
  const { publications, notifications } = useScope()

  const filterScope = scope === 'publications' ? publications : notifications
  return useFiltersSearchParams<PublicationFilterType, Typesense.Publications>({
    scope: filterScope,
    defaultFilters: { isDraft: false },
    toCrudFilters(filtersState) {
      const { me, publishedAt, agencies, societies, ...defaultParse } =
        filtersState

      const targets = [...(agencies ?? []), ...(societies ?? [])].filter(
        (target) => target !== undefined,
      )

      return [
        ...toCrudFilters(defaultParse),
        me && parseAuthor(me, id!),
        datetimeFilterToCrud('publishedAt', publishedAt as DayValue),
        targets && agenciesFilterToCrud(targets),
      ]
    },
  })
}

function parseAuthor(
  author: boolean,
  id: string,
): LogicalFilter<PublicationFilterType> | undefined {
  if (author) {
    return {
      field: 'author.id',
      operator: 'eq',
      value: id,
    }
  }
  return {
    field: 'isNational',
    operator: 'eq',
    value: true,
  }
}

function datetimeFilterToCrud<T extends Record<string, any>>(
  field: keyof T,
  publishedAt: DayValue,
): LogicalFilter<PublicationFilterType> | undefined {
  if (!publishedAt) return
  const dateString = dayValueToDate(publishedAt)
  const date = new Date(dateString!)
  const min = publishedAt ? startOfDay(date) : undefined
  const max = publishedAt ? endOfDay(date) : undefined
  const dateFilter = { min, max }
  return minMaxFilterToCrud<T>(field, dateFilter)
}

export function agenciesFilterToCrud(
  targets: string[],
): LogicalFilter<PublicationFilterType> {
  if (targets.length === 0) {
    return {
      field: 'targets',
      operator: 'eq',
      value: undefined,
    }
  }
  const filteredTargets = targets.map(cleanHydraId)
  return {
    field: 'targets',
    operator: 'eq',
    value: filteredTargets,
  }
}
