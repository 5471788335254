import { Boundary, unit } from '@aubade/core/ui-kit'
import {
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  VStack,
  Drawer,
  type DrawerContentProps,
  type DrawerProps,
  HStack,
} from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

import { useSearchParams } from 'react-router-dom'

import { IconFermer } from '../../../../design/src/graphics'
import { useOnClickOutside } from '../../libs'

import { Button } from '../Button'
import type { Unit } from '../utils'

type Variant = 'solid' | 'ghost' | 'users'
const margin = 10
const height = `calc(100vh - ${2 * margin})`

const drawerStyleMap: Record<Variant, DrawerContentProps> = {
  solid: {
    backgroundColor: 'lightGrey.500',
    borderRadius: unit('30'),
    padding: 0,
    paddingBottom: unit('60'),
    margin: unit(String(margin) as Unit),
    height: height,
    maxHeight: height,

    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  ghost: {
    margin: unit(String(margin) as Unit),
    height: height,
    maxHeight: height,
    justifyContent: 'center',
    overflowY: 'hidden',
    overflowX: 'hidden',
    padding: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    shadow: 'transparent',
  },
  users: {
    backgroundColor: 'lightGrey.500',
    borderRadius: unit('30'),
    padding: 0,
    margin: 0,
    height: height,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}

const drawerBodyStyleMap: Record<Variant, DrawerContentProps> = {
  solid: {
    padding: 0,
    gap: 0,
  },
  users: {
    padding: 0,
    gap: 0,
  },
  ghost: {},
}

type Props = {
  isOpenProps: boolean
  onClose?: () => void
  variant?: Variant
  size?: DrawerProps['size']
}

export function FormDrawer(props: PropsWithChildren<Props>) {
  const {
    isOpenProps,
    variant = 'solid',
    size = 'md',
    children,
    onClose,
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const onCloseModal = () => {
    searchParams.delete('page')
    searchParams.delete('id')
    setSearchParams(searchParams)
    onClose?.()
  }

  const drawerStyle = drawerStyleMap[variant]
  const drawerBodyStyle = drawerBodyStyleMap[variant]
  const [{ zIndex }] = useOnClickOutside(onCloseModal)

  const contentSize = size === 'full' ? '100%' : '594px'

  return (
    <Drawer
      isOpen={isOpenProps}
      placement="right"
      onClose={onCloseModal}
      onOverlayClick={onCloseModal}
      size={size}
      autoFocus={true}
      closeOnOverlayClick={true}
    >
      <DrawerOverlay />
      <DrawerContent zIndex={zIndex} maxWidth={contentSize} {...drawerStyle}>
        <DrawerBody gap={0}>
          <VStack
            margin={0}
            {...drawerBodyStyle}
            width="full"
            height="100%"
            alignItems={'flex-start'}
          >
            {variant !== 'ghost' && (
              <HStack
                width="full"
                paddingTop={unit('20')}
                paddingRight={unit('20')}
                justifyContent={'flex-end'}
              >
                <Button
                  variant="circleList"
                  leftIcon={IconFermer}
                  autoFocus={false}
                  onClick={onCloseModal}
                />
              </HStack>
            )}

            <Boundary>{children}</Boundary>
          </VStack>
        </DrawerBody>
      </DrawerContent>
      ,
    </Drawer>
  )
}
