import type { BaseRecord } from '@aubade/core/adapters'
import { once } from '@aubade/core/libs' // importing like this fixes a weird import bug
import { memo } from 'react'
import type { FieldPath } from 'react-hook-form'
import { mapValues } from 'remeda'

import type {
  RHFResourcePickerProps,
  RHFSingleResourcePickerProps,
} from '../DataPicker'
import { RHFResourcePicker, RHFSingleResourcePicker } from '../DataPicker'

import type { ArrayAccordionInputProps } from './ArrayAccordionInput'
import { ArrayAccordionInput } from './ArrayAccordionInput'
import type { ArrayTableInputProps } from './ArrayTableInput'
import { ArrayTableInput } from './ArrayTableInput'
import { AvatarInput } from './AvatarInput'
import { RHFCalendarDateInput } from './CalendarInput'
import { RHFCheckbox, CheckboxesGroup } from './Checkbox'
import { CheckBoxArrayInput } from './Checkbox/Checkbox'
import { MultipleChipInput, SingleChipInput } from './ChipInput'
import { RHFColorInput } from './Color'
import { RHFDateInput, RHFDateTimeInput } from './DateInput'

import { DropZoneInput } from './DropZoneInput/DropZoneInput'
import {
  HiddenInput,
  RHFSwitchInput,
  RHFTextAreaInput,
  RHFTextInput,
  RHFNumberInput,
} from './FormInputs'
import { RHFMarkdownInput } from './Markdown/index'
import type { NumberInputProps } from './NumberInput'
import type { RHFRadioButtonsProps } from './RadioButtons'
import { RHFRadioButtons } from './RadioButtons'
import { RHFRangeSelect } from './RangeSelect'
import { ReadOnlyInput } from './ReadOnlyInputs'
import { SearchInput } from './SearchInput'
import { RHFSelectInput } from './SelectInput'
import type { SelectInputProps } from './SelectInput'

export { VStackLayout } from './FormInputs'
export type { Bucket } from './DropZoneInput/DropZoneInput'
export { useBucket, initDocument } from './DropZoneInput/DropZoneInput'
export * from './controller'
export * from './Checkbox'
export * from './SelectInput'
export * from './TextInput'
export * from './NumberInput'
export * from './CalendarInput'
export * from './Switch'
export { ReadOnlyInputRender } from './ReadOnlyInputs'
export { SearchInput } from './SearchInput'

// Once : Small perf optimisation, each component will be defined once, at the whole app level (as expected if we did not use a factory function)
export const makeInputs = once(function makeInputs<
  FormType extends Record<string, any> = Record<string, any>,
>() {
  const result = {
    Text: RHFTextInput<FormType>,
    Markdown: RHFMarkdownInput<FormType>,
    Date: RHFDateInput<FormType>,
    DateTime: RHFDateTimeInput<FormType>,
    Calendar: RHFCalendarDateInput<FormType>,
    TextArea: RHFTextAreaInput<FormType>,
    Number<Outer = number>(props: NumberInputProps<FormType, Outer>) {
      return <RHFNumberInput<FormType, Outer> {...props} />
    },
    Select<Outer = string>(props: SelectInputProps<FormType, Outer>) {
      return <RHFSelectInput<FormType, Outer> {...props} />
    },
    Switch: RHFSwitchInput<FormType>,
    Checkbox: RHFCheckbox<FormType>,
    CheckboxesGroup: CheckboxesGroup<FormType>,
    RadioButtons<Out>(props: RHFRadioButtonsProps<FormType, Out>) {
      return <RHFRadioButtons<FormType, Out> {...props} />
    },
    Hidden: HiddenInput<FormType>,
    Disabled: ReadOnlyInput<FormType>,
    DropZone: DropZoneInput<FormType>,
    Avatar: AvatarInput<FormType>,
    Chip: SingleChipInput<FormType>,
    MultipleChip: MultipleChipInput<FormType>,
    RangeSelect: RHFRangeSelect<FormType>,
    Search: SearchInput<FormType>,
    ArrayTable<TFieldPath extends FieldPath<FormType>>(
      props: ArrayTableInputProps<FormType, TFieldPath>,
    ) {
      return <ArrayTableInput<FormType, TFieldPath> {...props} />
    },
    ArrayAccordion<R>(props: ArrayAccordionInputProps<R, FormType>) {
      return <ArrayAccordionInput<R, FormType> {...props} />
    },
    SingleResourcePicker<R extends BaseRecord, V extends BaseRecord = R>(
      props: RHFSingleResourcePickerProps<FormType, R, V>,
    ) {
      return <RHFSingleResourcePicker {...props} />
    },
    ResourcePicker<R extends BaseRecord, V extends BaseRecord = R>(
      props: RHFResourcePickerProps<FormType, R, V>,
    ) {
      return <RHFResourcePicker {...props} />
    },
    Color: RHFColorInput<FormType>,
    CheckBoxArray: CheckBoxArrayInput,
  }

  // auto memoize every component
  return mapValues(
    result,
    (component) => memo(component) as typeof component,
  ) as typeof result
})

export const ERROR_STYLE = { outline: '1px solid', outlineColor: 'error.dark' }
