import { Box, HStack } from '@chakra-ui/react'
import MDEditor, { commands } from '@uiw/react-md-editor'

import { useEffect, useState } from 'react'

import { useTranslate } from '../../../../../translation/src'
import { Paragraph } from '../../Paragraph/index'
import { useRHFInput, useBaseInput } from '../controller'
import type { RHFInputProps, BaseInputProps } from '../controller'

import { useTogglePreview, EmojiPicker } from './components/customButtons'

type MarkDownProps<T> = RHFInputProps<T, string, string, HTMLInputElement> &
  BaseInputProps<string, string, HTMLInputElement> & {
    maxLength?: number
  }

export const RHFMarkdownInput = function <T>(props: MarkDownProps<T>) {
  const { maxLength, ...rest } = props
  const innerProps = useRHFInput<
    string,
    string,
    string,
    string,
    HTMLInputElement
  >(rest)
  return <MarkdownInput {...innerProps} maxLength={maxLength} />
}

export function MarkdownInput(
  props: BaseInputProps<string, string, HTMLInputElement> & {
    maxLength?: number
  },
) {
  const { maxLength = 200, ...rest } = props

  const translate = useTranslate()

  const { id, field, wrap } = useBaseInput<
    string,
    string,
    string,
    HTMLInputElement
  >(rest)

  const [togglePreview, previewMode] = useTogglePreview()

  const { value, onChange, readOnly } = field

  const [innerVal, setInnerVal] = useState(value)

  useEffect(() => {
    setInnerVal(value)
  }, [value])

  function onTextChange(val?: string) {
    if (val && val.length >= maxLength) return
    setInnerVal(val)
    onChange(val)
  }

  return wrap(
    <Box id={id} data-color-mode="light" width="full" position="relative">
      <MDEditor
        value={innerVal}
        onChange={(val, event) => {
          event?.preventDefault()
          event?.stopPropagation()
          onTextChange(val)
        }}
        preview={readOnly ? 'preview' : previewMode}
        hideToolbar={readOnly}
        visibleDragbar={false}
        extraCommands={[togglePreview]}
        commands={[
          commands.bold,
          commands.italic,
          commands.orderedListCommand,
          commands.unorderedListCommand,
          EmojiPicker(previewMode),
        ]}
        style={{ borderRadius: '10px' }}
      />
      {maxLength && (
        <HStack width="full" justifyContent={'flex-end'}>
          <Paragraph
            text={translate('input.remainingChar', {
              value: maxLength - Number(field?.value?.length ?? 0),
            })}
          />
        </HStack>
      )}
    </Box>,
  )
}

type MarkdownPreviewProps = {
  value?: string
}

export function MarkdownPreview(props: MarkdownPreviewProps) {
  const { value } = props
  return (
    <Box data-color-mode="light" width="full">
      <MDEditor.Markdown
        source={value}
        style={{
          backgroundColor: 'transparent',
          fontSize: '12px',
        }}
      />
    </Box>
  )
}
