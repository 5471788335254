import { HStack } from '@chakra-ui/react'

import {
  AgenciesActiveFilter,
  JobActiveFilters,
  RolesActiveFilters,
  SocietiesActiveFilters,
} from '../../../components/ActiveFilters/index'

type Props = {
  onChange: () => void
}
export function ActiveFilters(props: Props) {
  return (
    <HStack width="full" wrap="wrap" gap={2}>
      <SocietiesActiveFilters {...props} />
      <AgenciesActiveFilter {...props} />
      <RolesActiveFilters {...props} />
      <JobActiveFilters {...props} />
    </HStack>
  )
}
