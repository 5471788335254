import type { GetRowProps } from '@aubade/core/ui-kit'
import {
  Surface,
  ResourceList,
  Paragraph,
  Button,
  unit,
} from '@aubade/core/ui-kit'

import {
  IconMessaging,
  IconRecherche,
  IconPlus,
  IconOutilsCouleurs,
  IconForbidden,
} from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import type { ConnectedUser } from '@aubade/domain/components'
import { ProfileCard, isAgency } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { Typesense } from '@aubade/types/typesense'
import { VStack, Box, HStack } from '@chakra-ui/react'
import { cleanHydraId, useCreate } from '@nartex/data-provider/react'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useUrls } from 'src/App'

import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { useScope } from '../../../components'

import { ListHeader } from '../../../components/ListHeader'
import { CreateUser, EditUser } from '../CreateEdit'
import { useUserFilters } from '../filters'
import { FilterBar } from '../filters/FilterForm'

import { useActions } from './Actions/index'

const templateColumns = '1fr 1fr 1fr 140px'

export function CustomersList() {
  const { role } = useConnectedUser()
  const { customers } = useScope()
  const urls = useUrls()
  const columns = useGetColumns(role!)
  const { filtersStore, crudFilters } = useUserFilters(customers)
  return (
    <ResourcePageLayout>
      <Surface
        direction={'column'}
        overflowY="auto"
        gap={unit('40')}
        paddingX={unit('10')}
        paddingY={unit('40')}
        maxHeight={'full'}
        minHeight={'full'}
        width="full"
      >
        <ListHeader
          scope={customers}
          filters={
            <FilterBar
              onChange={filtersStore.setState}
              filterState={filtersStore.state}
              scope={customers}
            />
          }
          mainAction={
            <>
              {isAgency(role) && (
                <Button
                  variant="primary"
                  label={'actions.newCustomer'}
                  leftIcon={IconPlus}
                  to={urls.aubade().customersCreate()}
                />
              )}
            </>
          }
        />
        <ResourceList<Typesense.Clients>
          scope={customers}
          resource="clients"
          dataProviderName="default"
          filters={crudFilters}
          tableProps={{
            templateColumns: templateColumns,
            hasPagination: true,
            columns: columns,
            noDataLabel: 'table.noData.collaboraters',
            getRowProps: useCallback<GetRowProps<Typesense.Clients>>(
              (row) => {
                return {
                  to: urls.aubade().userDetail('clients', row.id),
                }
              },
              [urls],
            ),
          }}
        />
      </Surface>
      <EditUser profile="customer" />
      <CreateUser />
    </ResourcePageLayout>
  )
}

function useGetColumns(userRole: ConnectedUser['role']) {
  const translate = useTranslate()
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const {
            firstname,
            lastname,
            roles,
            avatar,
            'userFunction.name': userJob,
          } = row.original

          const fullName = [firstname, lastname].filter(Boolean).join(' ')

          const role = roles?.[0]

          return (
            <Box width="full">
              <ProfileCard
                title={fullName}
                icon={IconOutilsCouleurs}
                picture={avatar}
                url=""
                subtitle={translate(userJob ?? role ?? '')}
                variant="big"
              />
            </Box>
          )
        },
      },
      {
        header: '',
        accessorKey: 'job',
        cell(props) {
          const { row } = props
          const {
            'craftCompany.name': craftCompany,
            'craftCompany.jobNames': jobNames,
          } = row.original
          const jobList = (jobNames ?? []).join(' - ')

          return (
            <VStack
              width="full"
              height="full"
              justifyContent={'center'}
              alignItems={'flex-start'}
              gap={unit('5')}
            >
              <Paragraph size="sm" fontWeight="bold" text={craftCompany} />
              <Paragraph size="sm" text={jobList} ellipsis />
            </VStack>
          )
        },
      },

      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps
          const { 'society.name': society, 'agency.name': agency } =
            row.original
          const societyAgency = [society, agency].filter(Boolean).join(' - ')

          return (
            <VStack
              width="full"
              height="full"
              justifyContent={'center'}
              alignItems={'flex-start'}
            >
              <Paragraph size="sm" text={societyAgency} />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps
          return <Actions user={row.original} />
        },
      },
    ] satisfies ColumnDef<Typesense.Clients>[]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole])
}

type ActionsProps = {
  user: Typesense.Clients
}

function Actions(props: ActionsProps) {
  const { user } = props
  const { agency } = useConnectedUser()
  const {
    id,
    agenciesBlackListed,
    'craftCompany.id': craftCompany,
    'agency.id': agencyId,
  } = user
  const urls = useUrls()

  const { role } = useConnectedUser()

  const isBlocked = Boolean(
    agency &&
      agenciesBlackListed &&
      agency &&
      agency.id &&
      agenciesBlackListed.includes(agency.id),
  )

  const canText = isAgency(role)
  const { toggleBlockCrafter } = useActions(
    craftCompany!,
    isBlocked,
    agency?.id,
  )

  const canBlock = Boolean(
    agency?.id &&
      agencyId &&
      isAgency(role) &&
      cleanHydraId(agencyId) === cleanHydraId(agency.id),
  )

  const [create] = useCreate()
  const navigate = useNavigate()
  async function createConversation() {
    await create(
      {
        resource: 'conversations',
        values: {
          users: [id],
        },
      },
      {
        async onSuccess(response) {
          navigate(
            urls.aubade().conversationDetail(response.data.id! as string),
          )
        },
      },
    )
  }
  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      justifyContent="flex-end"
      alignItems="center"
    >
      <>
        {canText && (
          <>
            <Button
              variant="circleList"
              leftIcon={IconMessaging}
              onClick={() => createConversation()}
            />
            {canBlock && (
              <Button
                variant="circleList"
                leftIcon={IconForbidden}
                iconColor={isBlocked ? 'error.dark' : undefined}
                onClick={async () => {
                  await toggleBlockCrafter()
                }}
              />
            )}
          </>
        )}
      </>

      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().userDetail('clients', id)}
      />
    </HStack>
  )
}
