import { unit } from '@aubade/core/ui-kit'
import { Center, Spinner, SimpleGrid, Box } from '@chakra-ui/react'
import type { BaseRecord } from '@pankod/refine-core'
import type { ReactNode } from 'react'
import { Fragment } from 'react'
import type { DeepPartial } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroller'
import { useInfiniteList } from 'src/libs/useInfiniteList'

type Props<T, U> = {
  resource: string
  renderItem: (item: T) => ReactNode
  filters?: DeepPartial<U>
  useWindow?: boolean
}

export function InfiniteResourceList<T extends BaseRecord, U>(
  props: Props<T, U>,
) {
  const { resource, renderItem, filters, useWindow } = props

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    error,
    isError,
  } = useInfiniteList<T, U>(resource, filters)

  if (isError) {
    throw error
  }
  if (!data) {
    return <></>
  }

  return (
    <Box width="full" overflow={'auto'}>
      {/* @ts-ignore */}
      <InfiniteScroll
        pageStart={1}
        loadMore={async () => {
          if (hasNextPage && !isFetchingNextPage) await fetchNextPage()
        }}
        hasMore={hasNextPage}
        useWindow={useWindow}
        threshold={100}
        loader={
          <Center key="infinite-list-loader" padding="4">
            <Spinner />
          </Center>
        }
        style={{ width: '100%', overflowX: 'hidden', padding: '0 12px' }}
      >
        <SimpleGrid width="full" columns={3} gap={unit('20')}>
          {data?.pages.flatMap((dataPage) => {
            return dataPage.data.map((item, key) => (
              <Fragment key={`${item.id} - ${key}`}>
                {renderItem(item)}
              </Fragment>
            ))
          })}
        </SimpleGrid>
      </InfiniteScroll>
    </Box>
  )
}
