import { useId, useMemo, useRef } from 'react'
import type {
  UseFormProps as UseHookFormProps,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form'
import { useFormState, useForm as useHookForm } from 'react-hook-form'

import { useEvent, useBusyContext } from '../../hooks'

type FormSubmitProps = {
  isLoading: boolean
  isDisabled?: boolean
  isDirty: boolean
  onClick: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}

export type UseFormControllerParams<
  TFieldValues extends Record<string, any>,
  TContext extends object = object,
> = {
  hookFormOptions?: UseHookFormProps<TFieldValues, TContext>
  readOnly?: boolean
  isLoading?: boolean
  onValid: SubmitHandler<TFieldValues>
  onError?: SubmitErrorHandler<TFieldValues>
}

export function useFormController<
  TFieldValues extends Record<string, any>,
  TContext extends object = object,
>(params: UseFormControllerParams<TFieldValues, TContext>) {
  const { hookFormOptions, readOnly, isLoading, onValid, onError } = params

  const formId = `form-${useId()}`
  const useHookFormResult = useHookForm(hookFormOptions)

  const { handleSubmit } = useHookFormResult
  const onSubmit = useEvent(handleSubmit(onValid, onError))
  const [isBusy, busyWrapper] = useBusyContext()
  const { isDirty } = useFormState(useHookFormResult)

  const saveButtonProps: FormSubmitProps = useMemo(() => {
    return {
      isDisabled: isLoading || isBusy || readOnly,
      isLoading: isLoading || isBusy,
      isDirty,
      onClick: onSubmit,
      form: formId,
      type: 'submit',
    }
  }, [isBusy, isDirty, isLoading, onSubmit, readOnly, formId])

  return {
    ...useHookFormResult,
    /** @deprecated please use useFormState({ controller? }) instead. This value is just a placeholder and won't be updated during render. This is required to improve render performance */
    formState: useRef(useHookFormResult.formState).current,
    onSubmit,
    saveButtonProps,
    readOnly,
    wrappers: useMemo(() => [busyWrapper], [busyWrapper]),
    id: formId,
  }
}
