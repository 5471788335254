import {
  ResourceList,
  Surface,
  useFiltersSearchParams,
  unit,
} from '@aubade/core/ui-kit'
import type { ExtendedTypesenseList } from '@aubade/types'
import { VStack } from '@chakra-ui/react'
import { forwardRef, memo } from 'react'
import { CalendarFilter } from 'src/components/Filters'

import { useScope } from '../../../components'
import { Padding } from '../../../components/ResourcePageLayout'

import { useGetDraftColumns } from './useGetColumns'

import type { ListFilterProps } from './index'

export const RightPanel = memo(
  forwardRef<HTMLDivElement, ListFilterProps>(function (props, ref) {
    const { listFilters } = props
    const { filtersStore, crudFilters: publicationsCrudFilters } = listFilters
    const { draft } = useScope()
    const { crudFilters } = useFiltersSearchParams({
      scope: draft,
      defaultFilters: { isDraft: true },
    })

    const columns = useGetDraftColumns()

    return (
      <VStack
        ref={ref}
        minWidth={unit('384')}
        maxWidth={unit('384')}
        gap={Padding}
        height="full"
        alignItems={'flex-start'}
        min-width="full"
      >
        <Surface padding={0} minHeight="370px" overflowY="hidden">
          <CalendarFilter
            onChange={filtersStore.setState}
            filterState={filtersStore.state}
            crudFilters={publicationsCrudFilters}
            resource="publications"
          />
        </Surface>
        <Surface
          height="full"
          paddingY={10}
          paddingX={5}
          width="full"
          overflowY="auto"
          overflowX="hidden"
          alignItems={'flex-start'}
        >
          <ResourceList<ExtendedTypesenseList>
            filters={crudFilters}
            sort={[{ field: 'sort_value', order: 'desc' }]}
            dataProviderName="default"
            scope="draft"
            resource="publications"
            variant="draft"
            title="publications.tabletitle.draft"
            tableProps={{
              templateColumns: '200px 100px',
              columns,
            }}
          />
        </Surface>
      </VStack>
    )
  }),
)
