// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconShop = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={51}
    height={50}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0018 0C19.4789 0 15.002 4.47696 15.002 9.99985H7.67855C3.10323 9.99985 -0.60155 13.9525 0.0814854 18.5726L3.77877 43.5722C4.33374 47.3247 7.62498 49.9995 11.3754 49.9995H38.6283C42.3783 49.9995 45.67 47.3247 46.2249 43.5722L49.9218 18.5726C50.6052 13.9525 46.9 9.99985 42.3251 9.99985H35.0021C35.0021 4.47696 30.5247 0 25.0018 0ZM30.002 9.99985C30.002 7.23846 27.7632 4.99971 25.0018 4.99971C22.2408 4.99971 20.0021 7.23846 20.0021 9.99985H30.002Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconShop)
export default Memo
