import { createCombinedStateHook } from '@nartex/react-libs'

export const {
  useCombinedStateRoot: useBusyContext,
  useSetChildState: useIsBusy,
} = createCombinedStateHook(isAppBusy)

function isAppBusy(busyElements: Record<string, boolean>) {
  return Object.values(busyElements).some((busy) => busy === true)
}
