import { compactObj, isEmpty } from '@aubade/core/libs'
import { unit, Form } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters/User'
import { isSociety } from '@aubade/domain/components'
import type { UserRole, CustomerRole } from '@aubade/types'
import { VStack, useId } from '@chakra-ui/react'

import { differenceInDays, lastDayOfYear, startOfYear } from 'date-fns'

import { useForm } from 'react-hook-form'

import {
  ClickSection,
  ExportSection,
  FiltersSection,
  GraphSection,
  MessageSection,
} from './Components'
import type { StatisticsFiltersType } from './Filters/index'
import { useStatisticsFilters } from './Filters/index'

const defaultFilters: Partial<StatisticsFiltersType> = {
  date: {
    startDate: startOfYear(new Date()),
    endDate: lastDayOfYear(new Date()),
  },
  displayType: 'day',
  displayMessageType: 'all',
}

export function Statistics() {
  const { crudFilters, filtersStore } = useStatisticsFilters({
    ...defaultFilters,
  })

  const methods = useForm<StatisticsFiltersType>({
    defaultValues: filtersStore.state,
  })
  const formId = useId()
  const { handleSubmit, setValue } = methods

  const { role } = useConnectedUser()

  const onFilterChange = handleSubmit(() => {
    transformValues(methods.getValues(), setValue, role)
    filtersStore.setState(compactObj(methods.getValues()))
  })
  return (
    <VStack
      width="full"
      backgroundColor="lightGrey.500"
      paddingX={unit('10')}
      gap={unit('10')}
    >
      <Form {...methods} onSubmit={onFilterChange} id={formId}>
        <ExportSection />
        <FiltersSection
          onChange={onFilterChange}
          filterState={filtersStore.state as any}
        />
        <GraphSection
          onChange={onFilterChange}
          crudFilters={crudFilters}
          filterState={filtersStore.state as any}
        />
        <ClickSection crudFilters={crudFilters} />
        <MessageSection
          crudFilters={crudFilters}
          onChange={onFilterChange}
          state={filtersStore.state as any}
        />
      </Form>
    </VStack>
  )
}

function transformValues(
  values: StatisticsFiltersType,
  setValue: any,
  role?: UserRole | CustomerRole,
) {
  const { date, displayType, societies, agencies } = values
  if (!date || !date.startDate || !date.endDate || !displayType) {
    return
  }

  if (
    differenceInDays(new Date(date.endDate), new Date(date.startDate)) > 30 &&
    displayType === 'day'
  ) {
    setValue('displayType', 'month')
  }
  if (
    differenceInDays(new Date(date.endDate), new Date(date.startDate)) <= 30 &&
    displayType === 'month'
  ) {
    setValue('displayType', 'day')
  }

  if (!isEmpty(societies) && displayType === 'society') {
    setValue('displayType', 'agency')
  }
  if (isEmpty(societies) && displayType === 'agency' && !isSociety(role)) {
    setValue('displayType', 'society')
  }
  if (!isEmpty(agencies)) {
    setValue('displayType', 'month')
  }
}
