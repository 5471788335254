import type { LogicalFilter } from '@aubade/core/adapters'
import { useList } from '@aubade/core/adapters'
import { useConnectedUser } from '@aubade/domain/adapters'

import type { TemporaryStatSummary } from '../types'

export function useGetStats(
  crudFilters: LogicalFilter<TemporaryStatSummary>[],
) {
  const { society } = useConnectedUser()

  const societyId = society?.id
  const societyFilter: LogicalFilter<TemporaryStatSummary> = {
    field: 'societies',
    operator: 'eq',
    value: societyId ? [societyId] : undefined,
  }

  const [stats] = useList<TemporaryStatSummary>({
    dataProviderName: 'default',
    resource: 'statistics',
    filters: [societyFilter, ...crudFilters],
  })

  return stats
}
